import React, { useState } from 'react'
import styles from './page.module.css'
import resourcestyles from './resourceCards.module.css'
import DownLoadCard from './card'
import ResourceCard from './resourceCard'
import ResourceHeader from './header'
import InstVideos from './instVideo'
import Faq from './faq'
import FooterResource from './footer'

const resourceData = [
  {
    title: 'T - 250 System',
    imageSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F3751790981554c669ead62bacb53137f?apiKey=7d740121674b4225a077dc91176b5085&',
  },
  {
    title: 'T - 500 System',
    imageSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F3751790981554c669ead62bacb53137f?apiKey=7d740121674b4225a077dc91176b5085&',
  },
  {
    title: 'Pro - 1500 WiFi',
    imageSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F278b4852bee74f70867008d6151add9d?apiKey=7d740121674b4225a077dc91176b5085&',
  },
  {
    title: 'Pro - 4000 WiFi',
    imageSrc:
      'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2Fe7587d1e6adb40b8b013d81168b540d5?apiKey=7d740121674b4225a077dc91176b5085&',
  },
]

const pages = [
  {
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/85f8b9ad10353dc3f5dfc0c437d33f70f360a6ebd7f78317a5e3a513e27581f9?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085',
    title: 'product sOPs',
    key: 'sops',
    nextTitle: 'explore instructional videos',
  },
  {
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/28316dcc6e291e2a288f12ecf619f3a07f9f3167b89dbe96c9dad49de819fca6?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085',
    title: 'instructional videos',
    key: 'videos',
    nextTitle: 'explore FAQs',
  },
  {
    iconSrc:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/d53e1ccdbde091f15073f8461be2d98fed769e8f4782f250ec13391940414131?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085',
    title: 'FAQs',
    key: 'faqs',
    nextTitle: 'explore news & Blog',
  },
  
  // {
  //   iconSrc:
  //     'https://cdn.builder.io/api/v1/image/assets/TEMP/52aa35a041da2be8646840e238c62793637f1a9b1079c8d38b4889aa9a44b544?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085',
  //   title: 'news & Blog',
  //   key: 'news',
  //   nextTitle: 'explore SOPs',
  // },
]

const Resource: React.FC = () => {
  const [activeBlock, setActiveBlock] = useState(0)
  console.log('Active block:', activeBlock);

  return (
    <main className={styles.resourcesContainer}>
      <section className={resourcestyles.resourceCardsContainer}>
        <div className={resourcestyles.resourceCardsGrid}>
          {pages.map((resource, index) => (
            <div key={index} className={resourcestyles.resourceCardWrapper}>
              <ResourceCard
                iconSrc={resource.iconSrc}
                title={resource.title}
                onClick={() => setActiveBlock(index)}
                active={activeBlock === index}
              />
            </div>
          ))}
        </div>
      </section>
      <hr className={resourcestyles.divider} />
      {pages[activeBlock].key === 'sops' && (
        <div className={styles.resourcesContainerOne}>
          <ResourceHeader />
          <section className={styles.resourcesList}>
            {resourceData.map((resource, index) => (
              <React.Fragment key={resource.title}>
                <DownLoadCard
                  title={resource.title}
                  imageSrc={resource.imageSrc}
                />
                {index < resourceData.length - 1 && (
                  <hr className={styles.divider} />
                )}
              </React.Fragment>
            ))}
          </section>
        </div>
      )}
      {pages[activeBlock].key === 'videos' && <InstVideos />}
      {pages[activeBlock].key === 'faqs' && <Faq isLight={true} />}
      <FooterResource
        next={() =>
          setActiveBlock(activeBlock === pages.length - 1 ? 0 : activeBlock + 1)
        }
        title={pages[activeBlock].nextTitle}
      />
    </main>
  )
}

export default Resource

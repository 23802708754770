import React from 'react';
import Image from 'next/image';
import styles from './SolutionWorks.module.css';

interface SolutionWorksProps {
  title: string;
  description: string;
  bulletPoints: string[];
  additionalDescription: string;
}

const SolutionWorks: React.FC<SolutionWorksProps> = ({ title, description, bulletPoints, additionalDescription }) => {
  return (
    <section className={styles.solutionWorks}>
      <div className={styles.container}>
        <div className={styles.imageColumn}>
          <h3 className={styles.subtitle}>How It Works</h3>
          <div className={styles.imageWrapper}>
            <Image
              src="/Neutrafog_Systems.png"
              alt="NeutraFog solution in action"
              layout="fill"
              objectFit="cover"
            />
          </div>
        </div>
        <div className={styles.contentColumn}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>{description}</p>
          <p className={styles.description}>{additionalDescription}</p>
        </div>
      </div>
    </section>
  );
};

export default SolutionWorks;

// // SolutionWorks.js
// import React from 'react';
// import Image from 'next/image';

// import styles from './SolutionWorks.module.css';

// interface SolutionWorksProps {
//   title: string;
//   description: string;
//   bulletPoints: string[];
//   additionalDescription: string;
// }

// const SolutionWorks: React.FC<SolutionWorksProps> = ({ title, description, bulletPoints, additionalDescription }) => {
//   return (
//     <section className={styles.solutionWorks}>
//       <div className={styles.container}>
//         <div className={styles.imageColumn}>
//           <h3 className={styles.subtitle}>How It Works</h3>
//           <div className={styles.imageWrapper}>
//             <Image
//               src="/Neutrafog_Systems.png"
//               alt="NeutraFog solution in action"
//               layout="fill"
//               objectFit="cover"
//             />
//           </div>
//         </div>
//         <div className={styles.contentColumn}>
//           <h2 className={styles.title}>{title}</h2>
//           <p className={styles.description}>{description}</p>
//           <ul className={styles.bulletPoints}>
//             {bulletPoints.map((point, index) => (
//               <li key={index}>{point}</li>
//             ))}
//           </ul>
//           <p className={styles.description}>{additionalDescription}</p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default SolutionWorks;
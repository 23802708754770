"use client";
import React from 'react';
import { Button } from "@trycreo/ui/button";
import style from './sop.module.css'

interface RestartButtonProps {
  onRestart: () => void;
}

export function RestartButton({ onRestart }: RestartButtonProps) {
  return (
    <div className={style.restartBtn} >
    <Button onClick={onRestart} variant="outline">
      Restart
    </Button>
    </div>
  );
}

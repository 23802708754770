import React from 'react'
import styles from './nav.module.css'
import cx from 'classnames'

const socialIcons = [
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b9432c8f4dba5ae15b3e77b14f7bfcb6dbd927ed53c2d70898c6f9cfed3d34c5?apiKey=7d740121674b4225a077dc91176b5085&',
    url: 'https://facebook.com/neutrafog',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/1b13625d254bb1e6a39206b1b8404a9e19085ace9751113737555ea7169b8e96?apiKey=7d740121674b4225a077dc91176b5085&',
    url: 'https://instagram.com/neutrafg',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/ccb7c17cee24581c9943cf911985cc54a72a000a840f7c251564dd9ac02d5c34?apiKey=7d740121674b4225a077dc91176b5085&',
    url: 'https://x.com/neutrafog',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/12d30d25a11e3118cfaa3befd6f88b7453c2ed257720dcb64846f7e8795d7da1?apiKey=7d740121674b4225a077dc91176b5085&',
    url: 'https://youtube.com/@neutrafog',
  },
]

const SocialLinks: React.FC = () => {
  return (
    <div
      className={`${styles.flex} ${styles.grow} ${styles['gap-5']} ${styles['justify-between']} ${styles['mt-36']} ${styles['max-md:mt-10']}`}
    >
      {socialIcons.map((icon, index) => (
        <a
          href={icon.url}
          className={cx(
            styles['text-white'],
            styles['text-decoration-none'],
            styles['link-hover']
          )}
        >
          <img
            key={index}
            loading="lazy"
            src={icon.icon}
            className={`${styles['shrink-0']} ${styles['aspect-square']} ${styles['w-[45px]']}`}
            alt={`Social icon ${index + 1}`}
          />
        </a>
      ))}
    </div>
  )
}

export default SocialLinks

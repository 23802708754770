import React from 'react'
import styles from './faq.module.css'
import cx from 'classnames'
import FAQAccordion from '@components/FAQ/FAQ'

const faqData = [
  'What type of connection do I need for remote operation?',
  'Can I use my own products with the Neutrafog unit?',
  'Can we use the product in a green house?',
  'Can I use the device for any crop?',
]

interface FaqProps {
  isLight: boolean;
}


const Faq: React.FC<FaqProps> = ({ isLight }) => {
  return (
    <div className={styles.instRoot}>
      <div className={styles.container}>
        <h2 className={styles.resourceType}>Resources</h2>
        <h1 className={cx(styles.resourceTitle)}>
          {'frequently asked questions.'}
        </h1>
        <div className="faq-divider">
          <svg
            width="100%"
            height="1"
            viewBox="0 0 100 1"
            preserveAspectRatio="none"
          >
            <line x1="0" y1="0" x2="100" y2="0" />
          </svg>
        </div>
        <FAQAccordion items={faqData.map(question => ({ question, answer: '' }))} isLight={isLight} />
      </div>
    </div>
  )
}

export default Faq

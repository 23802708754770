import React, { useState } from 'react';
import styles from './card.module.css';

interface ResourceCardProps {
  title: string;
  imageSrc: string;
  videoUrl: string;
}

const ResourceCard: React.FC<ResourceCardProps> = ({ title, imageSrc, videoUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleVideoClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <article className={styles.resourceCard}>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', gap:'20px'}} >
        <div className={styles.resourceImageWrapper}>
          <img src={imageSrc} alt="" className={styles.resourceImage} />
        </div>
        <h3 className={styles.resourceTitle}>{title}</h3>
      </div>

      <div className={styles.resourceInfo}>
        <button className={styles.downloadButton} onClick={handleVideoClick}>
          <span>Instructional Video</span>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/c43397d28b30397b7409b53e36de94daea438bc8aebbca6c706c5c1845cc3156?apiKey=7d740121674b4225a077dc91176b5085&" alt="" className={styles.downloadIcon} />
        </button>
      </div>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={handleCloseModal}>X</button>
            <video controls autoPlay className={styles.videoPlayer}>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </article>
  );
};

export default ResourceCard;

// import React from 'react';
// import styles from './card.module.css';

// interface ResourceCardProps {
//   title: string;
//   imageSrc: string;
//   videoUrl: string;

// }

// const ResourceCard: React.FC<ResourceCardProps> = ({ title, imageSrc, videoUrl }) => {
//   const handleVideoClick = () => {
//     window.open(videoUrl, '/Neutrafog_tutorial.mp4');
//   };
//   return (
//     <article className={styles.resourceCard}>
//       <div style={{display:'flex', justifyContent:'center', alignItems:'center', gap:'20px'}} >
//       <div className={styles.resourceImageWrapper}>
//         <img src={imageSrc} alt="" className={styles.resourceImage} />
//       </div>
//         <h3 className={styles.resourceTitle}>{title}</h3>
//       </div>

//       <div className={styles.resourceInfo}>
//         <button className={styles.downloadButton} onClick={handleVideoClick}>
//           <span>Instructional Video</span>
//           <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/c43397d28b30397b7409b53e36de94daea438bc8aebbca6c706c5c1845cc3156?apiKey=7d740121674b4225a077dc91176b5085&" alt="" className={styles.downloadIcon} />
//         </button>
//       </div>
//     </article>
//   );
// };

// export default ResourceCard;

/**
 * This code was generated by Builder.io.
 */
import React, { useState } from 'react'
import styles from './TeamSection.module.css'

interface TeamMemberProps {
  name: string
  position: string
  description?: string
  imageSrc: string
}

const TeamMember: React.FC<TeamMemberProps> = ({
  name,
  position,
  description,
  imageSrc,
}) => {
  const [isDetail, setDetail] = useState(false)
  const toggleDetail = () => {
    setDetail(!isDetail)
  }

  return (
    <div className={styles.memberCard}>
      <div className={styles.imageWrapper}>
        <img
          loading="lazy"
          src={imageSrc}
          alt={`${name}, ${position}`}
          className={styles.memberImage}
        />
        <div className={styles.memberInfo}>
          <div className={styles.memberDetails}>
            <h3 className={styles.memberName}>{name}</h3>
            <p className={styles.memberPosition}>{position}</p>
            {isDetail && (
              <p className={styles.memberDescription}>{description}</p>
            )}
          </div>
          {isDetail ? (
            <img
              loading="lazy"
              src="/img/vector-6.svg"
              alt=""
              className={styles.icon}
              onClick={toggleDetail}
            />
          ) : (
            <img
              loading="lazy"
              src="/img/vector-7.svg"
              alt=""
              className={styles.icon}
              onClick={toggleDetail}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TeamMember

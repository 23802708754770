import React from 'react'
import styles from './instVideo.module.css'
import cx from 'classnames'

interface InfoVideoBlockProps {
  title: string;
  discription: string;
  isWhite?: boolean;
}

const InfoVideoBlock: React.FC<InfoVideoBlockProps> = ({ title, discription, isWhite }) => {
  return (
    <span className={styles.videoBlock}>
      <div className={cx(styles.resourceHeader)}>
        <div className={cx(styles.resourceTitle, isWhite && styles.whiteText)}>
          {title}
        </div>
        <span
          className={cx(
            styles.resourceDescription,
            isWhite && styles.whiteText
          )}
        >
          {discription}
        </span>
      </div>
      <div>
      <video
          className={styles.video}
          controls
          src="/Neutrafog_tutorial.mp4"
          title="Setting Up Your Fogger System."
        >
          Your browser does not support the video tag.
        </video>
        {/* <iframe
          className={styles.iframe}
          src="/Neutrafog_tutorial.mp4"
          title="Setting Up Your Fogger System."
          frameBorder="0"
          allow="autoplay; encrypted-media;"
          allowFullScreen
        ></iframe> */}
      </div>
    </span>
  )
}

const InstVideos: React.FC = () => {
  return (
    <div className={styles.instRoot}>
      <div className={styles.container}>
        <h2 className={styles.resourceType}>resources</h2>
        {/* <InfoVideoBlock
          title="setting up your fogger System."
          discription="Easily set up your NeutraFog system for optimal performance with our step-by-step instructions. From assembly to calibration, ensure your fogger is ready to deliver effective pest management right from the start.

"
        />
          <InfoVideoBlock
          title="Installing your first IPM solution"
          isWhite={true}
          discription="Installing IPM solution in your Neutrafog device."
        />
        <br/>
        <br/>

        <br/> */}

        <InfoVideoBlock
          title="setting up neutrastorm"
          discription="Setting up Neutrastorm remote dashboard."
        />
        {/* <InfoVideoBlock
          title="headline for third video content"
          discription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Pellentesque commodo lacus at sodales sodales."
        /> */}
      </div>
    </div>
  )
}

export default InstVideos

import React from 'react'
import style from './footer.module.css'

const FooterResource: React.FC<{title: string, next: () => void}> = ({title, next}) => {
  return (
    <div className={style.footerRoot}>
      <div className={style.content} >
        <h2 className={style.resourceType}>Up Next:</h2>
        <div className={style.resourceWrapper} >
          <h1 className={style.resourceTitle}>{title}</h1>
          <img onClick={next} className={style.nextPage} src='/img/arrow_circle.svg' alt="next page" />
        </div>
      </div>
    </div>
  )
}

export default FooterResource

import React from 'react';
import styles from './resourceCards.module.css';
import cx from 'classnames';
interface ResourceCardProps {
  iconSrc: string;
  title: string;
  onClick: () => void;
  active: boolean;
  videoUrl: string; // Add this line

}

const ResourceCard: React.FC<ResourceCardProps> = ({ iconSrc, title, onClick, active }) => {
  return (
    <article className={cx(styles.resourceCard, active && styles.activeCard)} onClick={onClick} >
      <img loading="lazy" src={iconSrc} alt="" className={styles.resourceIcon} />
      <span className={styles.resourceTitle}>{title}</span>
    </article>
  );
};

export default ResourceCard;

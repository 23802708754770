import React, { useState } from 'react';
import styles from './AdditionalInfo.module.css';

interface AccordionItem {
  title: string;
  content: string;
}

const AdditionalInfo = ({ accordionData = [] }: { accordionData: AccordionItem[] }) => {
  const [activeSection, setActiveSection] = useState<number | null>(null);

  const toggleSection = (section: number) => {
    setActiveSection(activeSection === section ? null : section);
  };

  
  return (
    <div className={styles.additionalInfo}>
      <div className={styles.leftColumn}>
        <h2 className={styles.title}>Additional Product Information.</h2>
        <div className={styles.shareButtons}>
          <span className={styles.shareLabel}>Share:</span>
          <button className={styles.shareButton}>
            <img src="/social_icons/fb.png" alt="Facebook" />
          </button>
          <button className={styles.shareButton}>
            <img src="/social_icons/x.png" alt="Twitter" />
          </button>
          <button className={styles.shareButton}>
            <img src="/social_icons/pinterest.png" alt="Pinterest" />
          </button>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.accordion}>
          {accordionData.map((item, index) => (
            <div key={index} className={styles.accordionItem}>
              <button
                className={`${styles.accordionButton} ${activeSection === index ? styles.active : ''}`}
                onClick={() => toggleSection(index)}
              >
                {item.title}
                <span className={styles.icon}>{activeSection === index ? '−' : '+'}</span>
              </button>
              {activeSection === index && (
                <div className={styles.accordionContent}>
                  <p>{item.content}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;
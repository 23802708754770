import React, { useState } from 'react';
import styles from './ProductImage.module.css';

interface ProductImageProps {
  images: string[];
}

const ProductImage: React.FC<ProductImageProps> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  if (!images || images.length === 0) {
    return <div>No images available</div>;
  }


  return (
    <div className={styles.imageContainer}>
      <a href="/ipm-products#shopipm" className={styles.backLink}>
        <img src="/back.png" alt="Back Arrow" className={styles.backArrow} />
        <span className={styles.backText}>BACK</span>
      </a>
      <img src={images[currentImage]} alt="Product" className={styles.mainImage} />
      <div className={styles.thumbnails}>
        {images.map((img, index) => (
          <button
            key={index}
            className={`${styles.thumbnail} ${index === currentImage ? styles.active : ''}`}
            onClick={() => setCurrentImage(index)}
          ></button>
        ))}
      </div>
      <div className={styles.navigation}>
        <button onClick={() => setCurrentImage((prev) => (prev > 0 ? prev - 1 : images.length - 1))}>
          &lt;
        </button>
        <button onClick={() => setCurrentImage((prev) => (prev < images.length - 1 ? prev + 1 : 0))}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ProductImage;
// // ProductImage.js
// import React, { useState } from 'react';
// import styles from './ProductImage.module.css';

// const ProductImage = () => {
//   const [currentImage, setCurrentImage] = useState(0);

//   const images = ['/ipm.png', '/ipm3.png', '/ipm.png'];

//   return (
//     <div className={styles.imageContainer}>
//       <a href="#" className={styles.backLink}>
//         <img src="/back.png" alt="Back Arrow" className={styles.backArrow} />
//         <span className={styles.backText}>BACK</span>
//       </a>
//       <img src={images[currentImage]} alt="Product" className={styles.mainImage} />
//       <div className={styles.thumbnails}>
//         {images.map((img, index) => (
//           <button
//             key={index}
//             className={`${styles.thumbnail} ${index === currentImage ? styles.active : ''}`}
//             onClick={() => setCurrentImage(index)}
//           ></button>
//         ))}
//       </div>
//       <div className={styles.navigation}>
//         <button onClick={() => setCurrentImage((prev) => (prev > 0 ? prev - 1 : images.length - 1))}>
//           &lt;
//         </button>
//         <button onClick={() => setCurrentImage((prev) => (prev < images.length - 1 ? prev + 1 : 0))}>
//           &gt;
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ProductImage;
'use client'

import React, { useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@trycreo/ui/card'
import { SearchInput } from './search-input'
import { SelectDropdown } from './select-dropdown'
import { SubmitButton } from './submit-button'
import { OutputDisplay } from './output-display'
import dummyData, { getResultData } from './data_all'
import styles from './sop.module.css'
import { Table, TableBody, TableCell, TableRow } from '@trycreo/ui/table'
import cx from 'classnames'

const Sop2 = () => {
  const [selectedTableItem, setSelectedTableItem] = useState<any>(null)
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [submittedData, setSubmittedData] = useState<any>(null)
  const [selectedArea, setSelectedArea] = useState('25000')

  const handleSubmit = () => {
    if (selectedTableItem && selectedDropdownItem) {
      const resultData = getResultData(selectedTableItem.name, selectedDropdownItem, selectedArea);
      setSubmittedData({
        tableSelection: selectedTableItem,
        dropdownSelection: selectedDropdownItem,
        resultData: resultData,
      });
    }
  };

  const handleRestart = () => {
    setSelectedTableItem(null)
    setSelectedDropdownItem(null)
    setSearchQuery('')
    setSubmittedData(null)
    setSelectedArea('25000')
  }

  const handleAreaChange = (size: string) => {
    setSelectedArea(size.replace(/[^0-9]/g, ''))
    if (submittedData) {
      const updatedResultData = getResultData(submittedData.tableSelection.name, submittedData.dropdownSelection, size.replace(/[^0-9]/g, ''));
      setSubmittedData({
        ...submittedData,
        resultData: updatedResultData,
      });
    }
  }

  const filteredData = dummyData.tableData.filter((item: any) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <div className={styles.sopRoot}>
      <div className={styles.resourceHeader}>
        <h2 className={styles.resourceType}>Standard Operating Procedures</h2>
        <h1 className={styles.resourceTitle}>
        Optimize Your IPM Strategy with Customized Fogging Recommendations
        </h1>
        <span className={styles.resourceDescription}>
        Our interactive tool takes the guesswork out of pest management by analyzing your specific device and IPM solution to generate tailored recommendations. Get precise fogging schedules, optimal dosage amounts, and best-use practices to ensure maximum effectiveness in your greenhouse. Whether it’s weekly treatments or specific application guidelines, our tool helps you achieve consistent, reliable results.

        </span>
      </div>
      {submittedData ? (
        <OutputDisplay 
          data={submittedData.resultData} 
          handleRestart={handleRestart} 
          onAreaChange={handleAreaChange}
        />
      ) : (
        <Card className={styles.instRoot}>
          <CardHeader>
            <CardTitle className={styles.cardTitle}>
              Search for or select a solution to begin:
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <SearchInput onSearch={setSearchQuery} />

            <div className={styles.tableContainer}>
              <Table className={styles.tableRoot}>
                <TableBody>
                  {filteredData.map((mixture) => (
                    <TableRow key={mixture.id} className={styles.tableRow}>
                      <TableCell
                        onClick={() => setSelectedTableItem(mixture)}
                        className={cx(
                          styles.tableCell,
                          mixture === selectedTableItem && styles.tableCellActive
                        )}
                      >
                        {mixture.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            <SelectDropdown
              options={dummyData.dropdownOptions}
              onSelect={(value: string) => setSelectedDropdownItem(value)}
              selectedOption={selectedDropdownItem}
            />
            <SubmitButton onClick={handleSubmit} />
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default Sop2
import { useState, ChangeEvent, FormEvent } from 'react'

const init = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  interestedIn: '',
  location: '',
  message: '',
}

const useCallForm = () => {
  const [formData, setFormData] = useState(init)

  const [submitted, setSubmitted] = useState(false)

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const resetForm = () => {
    setFormData(init)
    setSubmitted(false)
  }

  const handleSubmit = () => {
    setSubmitted(true)
    setFormData(init)
  }

  return { formData, submitted, handleChange, handleSubmit, resetForm }
}

export default useCallForm

import React from 'react';
import Image from 'next/image';
import styles from './SystemDetails.module.css';

interface Feature {
  title: string;
  description: string;
}

interface SystemDetailsProps {
  features?: Feature[];
}

const SystemDetails: React.FC<SystemDetailsProps> = ({ features = [] }) => {
  return (
    <section className={styles.systemDetails}>
      <div className={styles.content}>
        <h3 className={styles.subtitle}>NeutraFog Features</h3>
        <h2 className={styles.title}>Take A Closer Look<br/> At System Details.</h2>
        <div className={styles.featuresGrid}>
          {features.map((feature, index) => (
            <div key={index} className={styles.featureItem}>
              <div className={styles.featureHeader}>
                <div className={styles.featureIconWrapper}>
                  <Image
                    src="/bullet.png"
                    alt="Feature icon"
                    width={24}
                    height={24}
                    className={styles.featureIcon}
                  />
                </div>
                <h4 className={styles.featureTitle}>{feature.title}</h4>
              </div>
              <p className={styles.featureDescription}>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.imageContainer}>
        <Image
          src="/mask.png"
          alt="Greenhouse with NeutraFog system"
          layout="fill"
          objectFit="cover"
          quality={100}
        />
      </div>
    </section>
  );
};

export default SystemDetails;
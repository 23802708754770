  import React, { useState } from 'react'
import styles from './styles.module.css'
import cx from 'classnames'
import useCallForm from './useCallForm'
import SalesForm from './salesForm'

const contactOptions = ['Sales', 'Support', 'IPM']

const CallForm: React.FC<{
  toggleCall: () => void;
  callOpen: boolean;
}> = ({ toggleCall, callOpen }) => {
  const [activeItem, setActiveItem] = useState(contactOptions[0])

  return (
    <dialog className={styles.contactContainer} open={callOpen}>
      <header className={styles.headerContainer}>
        <h1 className={styles.headerText}>get in touch</h1>
        <img
          onClick={toggleCall}
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/16885182ee1751ac7640377175b295ab75f8b74b4fd0922c5fbc60a2d9e24acd?apiKey=7d740121674b4225a077dc91176b5085&"
          alt=""
          className={cx(styles.headerIcon, styles.pointer)}
        />
      </header>

      <div className={styles.contactOptions}>
        {contactOptions.map((option, index) => (
          <div
            key={index}
            onClick={() => setActiveItem(option)}
            className={styles.contactOption}
          >
            {option}
          </div>
        ))}
      </div>
      {activeItem === 'Sales' && <SalesForm />}
      {activeItem === 'Support' && <SalesForm />}
      {activeItem === 'IPM' && <SalesForm />}
    </dialog>
  )
}

export default CallForm

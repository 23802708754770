import React, { useEffect, useRef } from 'react';

export interface CalconicCalculatorProps {
  calculatorId?: string;
}

const CalconicCalculator: React.FC<CalconicCalculatorProps> = ({ calculatorId = '6674bd1ada5576002b298876' }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://cdn.calconic.com/static/js/calconic.min.js';
      script.async = true;
      script.dataset.calconic = 'true';
      document.body.appendChild(script);

      script.onload = () => {
        if (containerRef.current) {
          containerRef.current.innerHTML = `<div class="calconic-calculator" data-calculatorid="${calculatorId}"></div>`;
          (window as any).Calconic?.init();
        }
      };

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [calculatorId]);

  return <div ref={containerRef} />;
};

export default CalconicCalculator;

// import React, { useEffect } from 'react';

// const CalconicCalculator = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://cdn.calconic.com/static/js/calconic.min.js';
//     script.async = true;
//     script.dataset.calconic = 'true';
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div 
//       className="calconic-calculator" 
//       data-calculatorid="6601976926da2c0029d92755"
//     />
//   );
// };

// export default CalconicCalculator;
import React from 'react'
import styles from './instVideo.module.css'
import cx from 'classnames'

interface InfoVideoBlockProps {
  title: string;
  discription: string;
  isWhite?: boolean;
}

const InfoVideoBlock: React.FC<InfoVideoBlockProps> = ({
  title,
  discription,
  isWhite = false,
}) => {
  return (
    <span className={styles.videoBlock}>
      <div className={cx(styles.resourceHeader)}>
        <h1 className={cx(styles.resourceTitle, isWhite && styles.whiteText)}>
          {title}
        </h1>
        <span
          className={cx(
            styles.resourceDescription,
            isWhite && styles.whiteText
          )}
        >
          {discription}
        </span>
      </div>
      <div>
        <iframe
          width="750"
          height="415"
          src="https://www.youtube.com/embed/ib2bHw3exFk?si=xCKYiky2vKLexCrq"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </span>
  )
}

const InstVideos: React.FC = () => {
  return (
    <div className={styles.instRoot}>
      <div className={styles.container}>
        <h2 className={styles.resourceType}>resources</h2>
        <InfoVideoBlock
          title="setting up your fogger System."
          discription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Pellentesque commodo lacus at sodales sodales."
        />
        <InfoVideoBlock
          title="headline for second video content"
          isWhite={true}
          discription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Pellentesque commodo lacus at sodales sodales."
        />
        <InfoVideoBlock
          title="headline for third video content"
          discription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Pellentesque commodo lacus at sodales sodales."
        />
      </div>
    </div>
  )
}

export default InstVideos

import React from 'react';
import HeroSection from './HeroSection';

const HeroSectionRoot: React.FC = () => {
  return (
    <main>
      <HeroSection
        backgroundImage="https://cdn.builder.io/api/v1/image/assets/TEMP/6169958165130f0f47c49ec83f7ac0ba7e9d59eddf5e6c078d488a39e85acd11?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
        headline="Placement Headline Here What Makes Us Different"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna."
        ctaText="explore savings"
        ctaIcon="https://cdn.builder.io/api/v1/image/assets/TEMP/20a69bce23098a725a014f7d656da5d7f7af275e426dceb4f1f29229dee58e17?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
      />
    </main>
  );
};

export default HeroSectionRoot;

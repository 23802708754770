'use client'

import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { X } from 'lucide-react'
import { Button } from "@trycreo/ui/button"
import Image from 'next/image'
import styles from './CallButtonMobile.module.css'

export default function CallButtonMobile() {
  const [isOpen, setIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('Sales')
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setModalRoot(document.body)
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const formObject = Object.fromEntries(formData.entries())
    
    try {
      const response = await fetch('/api/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formObject,
          formType: activeTab
        }),
      })
  
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
  
      const data = await response.json()
      console.log(data.message)
      setIsOpen(false) // Close the modal on successful submission
    } catch (error) {
      console.error('Error:', error)
    }
  }
  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault()
  //   const formData = new FormData(event.currentTarget)
  //   const formObject = Object.fromEntries(formData.entries())
    
  //   let submitUrl = '/api/submit-sales' // default to sales
  //   switch(activeTab) {
  //     case 'Support':
  //       submitUrl = '/api/submit-support'
  //       break
  //     case 'IPM':
  //       submitUrl = '/api/submit-ipm'
  //       break
  //   }

  //   try {
  //     const response = await fetch(submitUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formObject),
  //     })

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok')
  //     }

  //     const data = await response.json()
  //     console.log(data.message)
  //     setIsOpen(false) // Close the modal on successful submission
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }

  const modalContent = isOpen && (
    <div className={styles.modalOverlay} onClick={() => setIsOpen(false)}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <Button 
          onClick={() => setIsOpen(false)} 
          className={styles.closeButton}
          variant="ghost"
        >
          <X className={styles.closeIcon} />
        </Button>
        <h2 className={styles.modalTitle}>Get In Touch</h2>
        <div className={styles.tabContainer}>
          {['Sales', 'Support', 'IPM'].map((tab) => (
            <button
              key={tab}
              className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {/* <div className={styles.phoneContainer}>
          <p className={styles.phoneNumber}>Phone: 222-334-4556</p>
        </div> */}
        <form onSubmit={handleSubmit} className={styles.form}>
          <input
            name="firstName"
            placeholder="First Name"
            className={styles.input}
          />
          <input
            name="lastName"
            placeholder="Last Name"
            className={styles.input}
          />
          <input
            name="email"
            type="email"
            placeholder="Email Address"
            className={styles.input}
          />
          <input
            name="phone"
            type="tel"
            placeholder="Phone Number"
            className={styles.input}
          />

                  
      <select
            name="location"
            className={styles.input}
          >
            <option value="">Interested In?</option>
            <option value="option1">Sales</option>
          <option value="option2">Starting a Subscription</option>
          <option value="option3">IPM Systems & Solutions</option>
          <option value="option4">General Questions & Support</option>

            
            {/* Add more states here */}
          </select>

          <select
            name="location"
            className={styles.input}
          >
            <option value="">Location/State</option>
            <option value="AL">Alabama</option>
<option value="AK">Alaska</option>
<option value="AZ">Arizona</option>
<option value="AR">Arkansas</option>
<option value="CA">California</option>
<option value="CO">Colorado</option>
<option value="CT">Connecticut</option>
<option value="DE">Delaware</option>
<option value="FL">Florida</option>
<option value="GA">Georgia</option>
<option value="HI">Hawaii</option>
<option value="ID">Idaho</option>
<option value="IL">Illinois</option>
<option value="IN">Indiana</option>
<option value="IA">Iowa</option>
<option value="KS">Kansas</option>
<option value="KY">Kentucky</option>
<option value="LA">Louisiana</option>
<option value="ME">Maine</option>
<option value="MD">Maryland</option>
<option value="MA">Massachusetts</option>
<option value="MI">Michigan</option>
<option value="MN">Minnesota</option>
<option value="MS">Mississippi</option>
<option value="MO">Missouri</option>
<option value="MT">Montana</option>
<option value="NE">Nebraska</option>
<option value="NV">Nevada</option>
<option value="NH">New Hampshire</option>
<option value="NJ">New Jersey</option>
<option value="NM">New Mexico</option>
<option value="NY">New York</option>
<option value="NC">North Carolina</option>
<option value="ND">North Dakota</option>
<option value="OH">Ohio</option>
<option value="OK">Oklahoma</option>
<option value="OR">Oregon</option>
<option value="PA">Pennsylvania</option>
<option value="RI">Rhode Island</option>
<option value="SC">South Carolina</option>
<option value="SD">South Dakota</option>
<option value="TN">Tennessee</option>
<option value="TX">Texas</option>
<option value="UT">Utah</option>
<option value="VT">Vermont</option>
<option value="VA">Virginia</option>
<option value="WA">Washington</option>
<option value="WV">West Virginia</option>
<option value="WI">Wisconsin</option>
<option value="WY">Wyoming</option>
<option value="DC">District of Columbia</option>
<option value="AS">American Samoa</option>
<option value="GU">Guam</option>
<option value="MP">Northern Mariana Islands</option>
<option value="PR">Puerto Rico</option>
<option value="VI">U.S. Virgin Islands</option>
<option value="EU">Europe</option>
<option value="CA">Canada</option>
<option value="SA">South America</option>
<option value="AS">Asia</option>
<option value="MENA">Middle East and North Africa (MENA)</option>
            {/* Add more states here */}
          </select>
          <textarea
            name="message"
            placeholder="Message:"
            rows={3}
            className={styles.input}
          />
          <button
            type="submit"
            className={styles.submitButton}
          >
            <span>SUBMIT</span>
            <Image 
              src="/icon_dark.svg" 
              alt="Submit" 
              width={150} 
              height={150}
              className={styles.submitIcon}
            />
          </button>
        </form>
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => setIsOpen(true)}
          className={styles.openButton}
          aria-label="Open contact form"
        >
          <Image 
            src="/call.png" 
            alt="Contact" 
            width={400} 
            height={400}
            className={styles.buttonIcon}
          />
        </Button>
      </div>

      {modalRoot && createPortal(modalContent, modalRoot)}
    </>
  )
}
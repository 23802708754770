'use client'
import React, { useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@trycreo/ui/card'
import { RadioGroup, RadioGroupItem } from '@trycreo/ui/radio-group'
import { Label } from '@trycreo/ui/label'
import { RestartButton } from './restart-button'
import style from './sop.module.css'
import { Table, TableBody, TableCell, TableRow } from '@trycreo/ui/table'
import cx from 'classnames'

interface OutputDisplayProps {
  data: {
    title: string
    sizeArea: string[]
    mixture: { name: string; value: string }[]
    preventativeDays: number
    reactiveDays: number
  }
  handleRestart: () => void
  onAreaChange: (size: string) => void
}

export function OutputDisplay({ data, handleRestart, onAreaChange }: OutputDisplayProps) {
  const [selectedArea, setSelectedArea] = useState(data.sizeArea[0])

  const handleAreaChange = (size: string) => {
    setSelectedArea(size)
    onAreaChange(size)
  }

  return (
    <Card className={style.instRoot}>
      <CardHeader>
        <CardTitle className={style.title}> {data.title} </CardTitle>
      </CardHeader>
      <CardContent className={style.contentRoot}>
        <div>
          <div className={style.subTitle}> Select size of area in use: </div>
          <div>
            <RadioGroup value={selectedArea} onValueChange={handleAreaChange} className={style.radioRoot}>
              {data.sizeArea.map((size,index) => (
                <div key={size || index} className="flex items-center space-x-2 text-white">
                  <RadioGroupItem
                    className="bg-secondary"
                    value={size}
                    id={size}
                  />
                  <Label className={style.labelColor} htmlFor={size}>
                    {size}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </div>
        </div>
        <div>
          <div className={style.subTitle}>
            This mixture is recommended for the selected coverage:
          </div>
          <div>
            <Table>
              <TableBody className={style.labelColor}>
                {data.mixture.map((mixture, index) => (
                  <TableRow key={mixture.name || `mixture-${index}`}>
                    <TableCell>{mixture.name}</TableCell>
                    <TableCell>{mixture.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        <div>
          <div className={style.subMainTitle}>Preventative Days</div>
          <div className={style.subTitle}>
            Every {data.preventativeDays} days until pressure subsides or stop by day 18 flower:
          </div>
          <div className="flex gap-2 flex-wrap">
            {Array.from({ length: 15 }).map((_, i) => (
              <div
                key={i}
                className={cx(
                  style.boxCustom,
                  (i + 1) % data.preventativeDays === 0 && style.activeBox
                )}
              >
                <span> {i + 1} </span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className={style.subMainTitle}>Reactive Days:</div>
          <div className={style.subTitle}>
            Every {data.reactiveDays} days until pressure subsides or stop by day 18 flower
          </div>
          <div className="flex gap-2 flex-wrap">
            {Array.from({ length: 15 }).map((_, i) => (
              <div
                key={i}
                className={cx(
                  style.boxCustom,
                  (i + 1) % data.reactiveDays === 0 && style.activeBox
                )}
              >
                <span> {i + 1} </span>
              </div>
            ))}
          </div>
        </div>
        <RestartButton onRestart={handleRestart} />
      </CardContent>
    </Card>
  )
}

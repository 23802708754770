import React from 'react'
import styles from './faq.module.css'
import cx from 'classnames'
import FAQAccordion from './FAQ'

// const faqData = [
//   'What type of connection do I need for remote operation?',
//   'Can I use my own products with the Neutrafog unit?',
//   'Can we use the product in a green house?',
//   'Can I use the device for any crop?',
//   "Does the fogger system work the same indoors and outdoors?",
//   "How does the fogger work?",
//   "How do I properly maintain and clean a fogger system?",
//   "How do I properly maintain and clean a fogger system?",
//   "How do I properly maintain and clean a fogger system?",
//   "Can I use the my Neutrafog unit remotely?"
// ]

const faqData = [
  {
    question: 'What type of connection do I need for remote operation?',
    answer: 'For remote operation, you need a stable internet connection. Our system supports both Wi-Fi and cellular connections, ensuring you can control your NeutraFog unit from anywhere with internet access.'
  },
  {
    question: 'Can I use my own products with the NeutraFog unit?',
    answer: 'While the NeutraFog unit is optimized for use with our specially formulated products, it can accommodate certain third-party solutions. However, we recommend using our products to ensure optimal performance and to maintain your warranty.'
  },
  {
    question: 'Can we use the product in a greenhouse?',
    answer: 'Yes, the NeutraFog system is perfectly suited for use in greenhouses. It\'s designed to create an ideal environment for plant growth and pest control in enclosed spaces.'
  },
  {
    question: 'Can I use the device for any crop?',
    answer: 'The NeutraFog unit is versatile and can be used for a wide variety of crops. However, different crops may require different settings or solutions. We recommend consulting our crop-specific guides for optimal results.'
  },
  {
    question: 'Does the fogger system work the same indoors and outdoors?',
    answer: 'While the NeutraFog system can be used both indoors and outdoors, its effectiveness may vary depending on environmental conditions. Indoor use typically provides more controlled conditions, while outdoor use may require adjustments based on weather and wind patterns.'
  },
  {
    question: 'How does the fogger work?',
    answer: 'Our fogger works by creating an ultra-fine mist that evenly distributes the solution over the target area, ensuring thorough coverage and effective treatment.'
  },
  {
    question: 'How do I properly maintain and clean a fogger system?',
    answer: 'Regular maintenance includes flushing the system with clean water after each use, checking and cleaning nozzles, and inspecting all connections. We provide a detailed maintenance guide with each unit for specific instructions.'
  },
  {
    question: 'Can I use my NeutraFog unit remotely?',
    answer: 'Yes, the NeutraFog unit can be operated remotely through our dedicated mobile app or web interface, allowing you to control and monitor your system from anywhere with an internet connection.'
  }
]

const Faq: React.FC = () => {
  const isLight = false;
  return (
    <div className={cx(styles.instRoot, !isLight && styles.darkBg)}>
      <div className={styles.container}>
        {/* <h2 className={cx(styles.resourceType, !isLight && styles.whiteText)}>Resources</h2>
        <h1 className={cx(styles.resourceTitle, !isLight && styles.whiteText)}>
          {'frequently asked questions.'}
        </h1> */}
        <div className="faq-divider">
          <svg
            width="100%"
            height="1"
            viewBox="0 0 100 1"
            preserveAspectRatio="none"
          >
            <line x1="0" y1="0" x2="100" y2="0" />
          </svg>
        </div>
        <FAQAccordion items={faqData} isLight={isLight} />
      </div>
    </div>
  )
}

export default Faq

import React, { useEffect, useState } from 'react'
import Header from './Header'
import Navigation from './Navigation'
import SocialLinks from './SocialLinks'
import styles from './nav.module.css'
import cx from 'classnames'
import CallButtonDesktop from './CallModal/CallButtonDesktop'

export const SharedNav = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [callOpen, setCallOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    document.body.style.overflow = !menuOpen ? 'hidden' : ''
  }

  const toggleCall = () => {
    setCallOpen(!callOpen)
  }

  useEffect(() => {
    const triggerElement = document.querySelector('.nav-trigger')
    if (triggerElement) {
      triggerElement.addEventListener('click', toggleMenu)
    }
    return () => {
      if (triggerElement) {
        triggerElement.removeEventListener('click', toggleMenu)
      }
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <div className={cx(styles.navRoot, { [styles.menuOpen]: menuOpen })}>
      <div className={styles.navHeader}>
        <Header toggleMenu={toggleMenu} menuOpen={menuOpen} toggleCall={toggleCall} isNavOpen={menuOpen} />
      </div>
      {menuOpen && (
        <div className={styles.navContent}>
          <Navigation />
          <div className={styles.navLinks}>
            <div className={styles.primaryLinks}>
              <nav className={`${styles['text-2xl']} ${styles['leading-79px']} ${styles['text-white']} ${styles['text-opacity-50']} ${styles['max-md:mt-10']}`}>
                <a href="/sop" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>SOP</a>
                <br/>
                <a href="/careers" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>Careers</a>
                <br />
                <a href="/contact" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>Contact Us</a>
              </nav>
            </div>
            <div className={styles.socialLinks}>
              <SocialLinks />
            </div>
          </div>
        </div>
      )}
      <CallButtonDesktop />
    </div>
  )
}
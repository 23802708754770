import React, { useEffect, useState } from 'react'
import Header from './Header'
import Navigation from './Navigation'
import SocialLinks from './SocialLinks'
import styles from './nav.module.css'
import cx from 'classnames'

export const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [callOpen, setCallOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const toggleCall = () => {
    setCallOpen(!callOpen)
  }

  useEffect(() => {
    const triggerElement = document.querySelector('.nav-trigger')
    if (triggerElement) {
      triggerElement.addEventListener('click', toggleMenu)
    }
    return () => {
      if (triggerElement) {
        triggerElement.removeEventListener('click', toggleMenu)
      }
    }
  }, [])

  return (
    <div className={cx(styles.flex, styles['flex-col'], styles['bg-transparent'], { [styles['nav-open']]: menuOpen })}>
      <div className={cx(styles.flex, styles['justify-center'], styles['w-full'], styles['max-md:px-5'], styles['max-md:max-w-full'])}>
        <div className={cx(styles.flex, styles['flex-col'], styles['w-full'], styles['max-w-1606px'], styles['max-md:mb-10'], styles['max-md:max-w-full'])}>
          <Header toggleMenu={toggleMenu} menuOpen={menuOpen} toggleCall={toggleCall} isNavOpen={menuOpen} />
        </div>
      </div>
      {menuOpen && (
        <div className={styles.menuContent}>
          <div className={styles.menuInnerContent}>
            <main className={cx(styles.flex, styles['gap-5'], styles['items-end'], styles['justify-between'], styles['w-full'], styles['max-md:flex-wrap'], styles['max-md:mt-10'], styles['max-md:max-w-full'])}>
              <Navigation />
              <div className={cx(styles['justify-between'], styles['max-md:mt-10'], styles['max-md:max-w-full'])}>
                <div className={cx(styles.flex, styles['align-end'], styles['gap-7'], styles['max-md:flex-col'])}>
                  <div className={cx(styles.flex, styles['flex-col'], styles['w-41'], styles['max-md:ml-0'], styles['max-md:w-full'])}>
                    <nav className={cx(styles['text-2xl'], styles['text-white'], styles['text-opacity-50'], styles['max-md:mt-10'])}>
                      <a href="/sop" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>SOP</a>
                      <br />
                      <a href="/careers" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>Careers</a>
                      <br />
                      <a href="/contact" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>Contact Us</a>
                    </nav>
                  </div>
                  <div className={cx(styles.flex, styles['flex-col'], styles['ml-5'], styles['w-59'], styles['max-md:ml-0'], styles['max-md:w-full'])}>
                    <SocialLinks />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </div>
  )
}

export default Nav





// import React, { useEffect, useState } from 'react'
// import Header from './Header'
// import Navigation from './Navigation'
// import SocialLinks from './SocialLinks'
// import styles from './nav.module.css'
// import cx from 'classnames';
// import CallButtonDesktop from './CallModal/CallButtonDesktop'

// // import CallForm from './CallModal'

// export const Nav = () => {
//   const [menuOpen, setMenuOpen] = useState(false)
//   const [callOpen, setCallOpen] = useState(false)

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen)
//   }

//   const toggleCall = () => {
//     setCallOpen(!callOpen)
//   }

//   useEffect(() => {
//     const triggerElement = document.querySelector('.nav-trigger')
//     if (triggerElement) {
//       triggerElement.addEventListener('click', toggleMenu)
//     }
//     return () => {
//       if (triggerElement) {
//         triggerElement.removeEventListener('click', toggleMenu)
//       }
//     }
//   }, [])

//   return (
//     <div className={cx(styles.flex, styles['flex-col'], styles['bg-transparent'], { [styles['nav-open']]: menuOpen })}>
//       <div className={cx(styles.flex, styles['justify-center'], styles['w-full'], styles['max-md:px-5'], styles['max-md:max-w-full'])}>
//         <div className={cx(styles.flex, styles['flex-col'], styles['w-full'], styles['max-w-1606px'], styles['max-md:mb-10'], styles['max-md:max-w-full'])}>
//           <Header toggleMenu={toggleMenu} menuOpen={menuOpen} toggleCall={toggleCall} isNavOpen={menuOpen} />
//         </div>
//       </div>
//       {menuOpen && (
//         <div className={styles.menuContent}>
//           <main className={cx(styles.menuInnerContent, styles.flex, styles['gap-5'], styles['items-end'], styles['justify-between'], styles['w-full'], styles['max-md:flex-wrap'], styles['max-md:mt-10'], styles['max-md:max-w-full'])}>
//             <Navigation />
//             <div className={cx(styles['justify-between'], styles['max-md:mt-10'], styles['max-md:max-w-full'])}>
//               <div className={cx(styles.flex, styles['align-end'], styles['gap-7'], styles['max-md:flex-col'])}>
//                 <div className={cx(styles.flex, styles['flex-col'], styles['w-41'], styles['max-md:ml-0'], styles['max-md:w-full'])}>
//                   <nav className={cx(styles['text-2xl'], styles['text-white'], styles['text-opacity-50'], styles['max-md:mt-10'])}>
//                     <a href="/sop" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>SOP</a>
//                     <br />
//                     <a href="/careers" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>Careers</a>
//                     <br />
//                     <a href="/contact" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>Contact Us</a>
//                   </nav>
//                 </div>
//                 <div className={cx(styles.flex, styles['flex-col'], styles['ml-5'], styles['w-59'], styles['max-md:ml-0'], styles['max-md:w-full'])}>
//                   <SocialLinks />
//                 </div>
//               </div>
//             </div>
//           </main>
//         </div>
//       )}
//     </div>
//   )
// }

//   return (
//     <div className={cx(styles.flex, styles['flex-col'], styles['justify-center'], styles['bg-transparent'], { [styles['nav-open']]: menuOpen })}>
//       <header className={`${styles.headerContainer} ${styles.flex} ${styles['justify-center']} ${styles['items-center']} ${styles['px-16']} ${styles['py-16']} ${styles['w-full']} ${styles['max-md:px-5']} ${styles['max-md:max-w-full']}`}>
//         <div className={`${styles.flex} ${styles['flex-col']} ${styles['w-full']} ${styles['max-w-1606px']} ${styles['max-md:mb-10']} ${styles['max-md:max-w-full']}`}>
//           <Header toggleMenu={toggleMenu} menuOpen={menuOpen} toggleCall={toggleCall} isNavOpen={menuOpen} />
//         </div>
//       </header>
//       {menuOpen && (
//         <div className={`${styles.menuContent}`}>
//           <main className={`${styles.menuInnerContent} ${styles.flex} ${styles['gap-5']} ${styles['items-end']} ${styles['justify-between']} ${styles['w-full']} ${styles['max-md:flex-wrap']} ${styles['max-md:mt-10']} ${styles['max-md:max-w-full']}`}>
//             <Navigation />
//             <div className={`${styles['justify-between']} ${styles['max-md:mt-10']} ${styles['max-md:max-w-full']}`}>
//               <div className={`${styles.flex} ${styles['align-end']} ${styles['gap-7']} ${styles['max-md:flex-col']}`}>
//                 <div className={`${styles.flex} ${styles['flex-col']} ${styles['w-41']} ${styles['max-md:ml-0']} ${styles['max-md:w-full']}`}>
//                   <nav className={`${styles['text-2xl']} ${styles['text-white']} ${styles['text-opacity-50']} ${styles['max-md:mt-10']}`}>
//                     <a href="/sop" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>
//                       SOP
//                     </a>
//                     <br />
//                     <a href="/careers" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>Careers</a>
//                     <br />
//                     <a href="/contact" className={cx(styles['text-white'], styles['text-decoration-none'], styles['link-hover'])}>Contact Us</a>
//                   </nav>
//                 </div>
//                 <div className={`${styles.flex} ${styles['flex-col']} ${styles['ml-5']} ${styles['w-59']} ${styles['max-md:ml-0']} ${styles['max-md:w-full']}`}>
//                   <SocialLinks />
//                 </div>
//               </div>
//             </div>
//           </main>
//         </div>
//       )}
//       {/* <CallButtonDesktop /> */}
//     </div>
//   )
// }
// import React, { useEffect, useState } from 'react'
// import Header from './Header'
// import Navigation from './Navigation'
// import SocialLinks from './SocialLinks'
// import styles from './nav.module.css'
// import cx from 'classnames';
// import CallForm from './CallModal'


// export const Nav = () => {

//   const [menuOpen, setMenuOpen] = useState(false)
//   const [callOpen, setCallOpen] = useState(false)

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen)
//   }

//   const toggleCall = () => {
//     setCallOpen(!callOpen)
//   }

//   useEffect(() => {
//     const triggerElement = document.querySelector('.nav-trigger')
//     if (triggerElement) {
//       triggerElement.addEventListener('click', toggleMenu)
//     }
//     return () => {
//       if (triggerElement) {
//         triggerElement.removeEventListener('click', toggleMenu)
//       }
//     }
//   }, [])

//   return (
//     <div className={cx(styles.flex ,styles['flex-col'],styles['justify-center'], styles['bg-transparent'], { [styles['nav-open']]: menuOpen })}>
//     <header className={`${styles.flex} ${styles['justify-center']} ${styles['items-center']} ${styles['px-16']} ${styles['py-16']} ${styles['w-full']} ${styles['max-md:px-5']} ${styles['max-md:max-w-full']}`}>
//       <div className={`${styles.flex} ${styles['flex-col']} ${styles['w-full']} ${styles['max-w-1606px']} ${styles['max-md:mb-10']} ${styles['max-md:max-w-full']}`}>
//         <Header toggleMenu={toggleMenu} menuOpen={menuOpen} toggleCall={toggleCall} />
//         { menuOpen && <main className={`${styles.flex} ${styles['gap-5']} ${styles['mt-56']} ${styles['items-end']} ${styles['justify-between']} ${styles['mt-36']} ${styles['w-full']} ${styles['max-md:flex-wrap']} ${styles['max-md:mt-10']} ${styles['max-md:max-w-full']}`}>
//           <Navigation />
//           <div className={`${styles['justify-between']} ${styles['max-md:mt-10']} ${styles['max-md:max-w-full']}`}>
//             <div className={`${styles.flex} ${styles['align-end']} ${styles['gap-7']} ${styles['max-md:flex-col']}`}>
//               <div className={`${styles.flex} ${styles['flex-col']} ${styles['w-41']} ${styles['max-md:ml-0']} ${styles['max-md:w-full']}`}>
//                 <nav className={`${styles['text-2xl']} ${styles['leading-[79px]']} ${styles['text-white']} ${styles['text-opacity-50']} ${styles['max-md:mt-10']}`}>
//                   <a href="/sop" className={cx(styles['text-white'],styles['text-decoration-none'], styles['link-hover'])}>
//                     SOP
//                   </a>
//                   <br />
//                   <a href="/careers" className={cx(styles['text-white'],styles['text-decoration-none'], styles['link-hover'])} >Careers</a>
//                   <br />
//                   <a href="/contact" className={cx(styles['text-white'],styles['text-decoration-none'], styles['link-hover'])} >Contact Us</a>
//                 </nav>
//               </div>
//               <div className={`${styles.flex} ${styles['flex-col']} ${styles['ml-5']} ${styles['w-[59%]']} ${styles['max-md:ml-0']} ${styles['max-md:w-full']}`}>
//                 <SocialLinks />
//               </div>
//             </div>
//           </div>
//         </main>}
//       </div>
//     </header>
//     <CallForm callOpen={callOpen} toggleCall={toggleCall} />
//   </div>
//   )
// }

// export default Nav

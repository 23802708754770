import React, { useState } from 'react'
import { ArrowRight, Download } from 'lucide-react'
import styles from './ProductSOPs.module.css'
import Link from 'next/link'

interface Product {
  name: string
  image: string
}

interface ProductSOPsProps {
  onExploreVideos: () => void
}

const products: Product[] = [
  { name: "T - 250", image: "/t-250.png" },
  { name: "T - 500", image: "/t-500.png" },
  { name: "Pro - 1500 Wifi", image: "/pro-1500.png" },
  { name: "Pro - 4000 Wifi", image: "/pro-4000.png" },
]

const ProductSOPs: React.FC<ProductSOPsProps> = ({ onExploreVideos }) => {
  return (
    <div className={styles.container}>
      <p className={styles.resourcesHeader}>Resources</p>
      <h1 className={styles.title}>Standard Operating Procedures.</h1>
      <p className={styles.description}>
        Access essential guidelines and best practices to maximize the performance and longevity of your NeutraFog systems. Ensure your pest management operations are safe, efficient, and compliant with our comprehensive Standard Operating Procedures.
      </p>
      <div className={styles.productList}>
        {products.map((product, index) => (
          <div key={index} className={styles.productItem} style={{"--animation-order": index} as React.CSSProperties}>
            <img
              src={product.image}
              alt={`${product.name} preview`}
              className={styles.productImage}
            />
            <div className={styles.productInfo}>
              <h2 className={styles.productName}>{product.name}</h2>
            </div>
            <Link href="/sop" legacyBehavior>
              <a className={styles.downloadButton}>
                <Download className={styles.downloadIcon} />
                <span>View SOP's</span>
              </a>
            </Link>
          </div>
        ))}
      </div>
      <div className={styles.exploreSection}>
        <p className={styles.exploreTitle}>Up Next:</p>
        <div className={styles.exploreContent}>
          <h2 className={styles.exploreHeading}>How to Setup your Neutrafog System</h2>
          <button className={styles.exploreButton} onClick={onExploreVideos}>
            <ArrowRight className={styles.arrowIcon} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductSOPs


// import React, { useState } from 'react'
// import { ArrowRight, Download, X } from 'lucide-react'
// import styles from './ProductSOPs.module.css'

// interface Product {
//   name: string
//   image: string
// }
// interface ProductSOPsProps {
//   onExploreVideos: () => void
// }

// const products: Product[] = [
//   { name: "T - 250", image: "/t-250.png" },
//   { name: "T - 500", image: "/t-500.png" },
//   { name: "Pro - 1500 Wifi", image: "/pro-1500.png" },
//   { name: "Pro - 4000 Wifi", image: "/pro-4000.png" },
// ]
//   // const ProductSOPs: React.FC<ProductSOPsProps> = ({ onExploreVideos }) => {

//   const ProductSOPs: React.FC<ProductSOPsProps> = ({ onExploreVideos }) => {

//   const [isVideoOpen, setIsVideoOpen] = useState(false)

//   const openVideo = () => {
//     setIsVideoOpen(true)
//   }

//   const closeVideo = () => {
//     setIsVideoOpen(false)
//   }

//   return (
//     <div className={styles.container}>
//       <p className={styles.resourcesHeader}>Resources</p>
//       <h1 className={styles.title}>Standard Operating Procedures.</h1>
//       <p className={styles.description}>
//       Access essential guidelines and best practices to maximize the performance and longevity of your NeutraFog systems. Ensure your pest management operations are safe, efficient, and compliant with our comprehensive Standard Operating Procedures.

//       </p>
//       <div className={styles.productList}>
//         {products.map((product, index) => (
//           <div key={index} className={styles.productItem} style={{"--animation-order": index} as React.CSSProperties}>
//             <img
//               src={product.image}
//               alt={`${product.name} preview`}
//               className={styles.productImage}
//             />
//             <div className={styles.productInfo}>
//               <h2 className={styles.productName}>{product.name}</h2>
//             </div>
//             <button className={styles.downloadButton} onClick={openVideo}>
//               <Download className={styles.downloadIcon} />
//               <span>View Instructional Video</span>
//             </button>
//           </div>
//         ))}
//       </div>
//       <div className={styles.exploreSection}>
//         <p className={styles.exploreTitle}>Up Next:</p>
//         <div className={styles.exploreContent}>
//           <h2 className={styles.exploreHeading}>Explore SOP's</h2>
//           <button className={styles.exploreButton} onClick={onExploreVideos}>
//             <ArrowRight className={styles.arrowIcon} />
//           </button>
//         </div>
//       </div>
//       {isVideoOpen && (
//         <div className={styles.videoModal}>
//           <div className={styles.videoModalContent}>
//             <button className={styles.closeButton} onClick={closeVideo}>
//               <X className={styles.closeIcon} />
//             </button>
            
//             {/* <video src="/sop" controls autoPlay className={styles.videoPlayer} /> */}
//           </div>
//         </div>
//       )}
//     </div>
//   )
// }
// export default ProductSOPs

// import React, { useState } from 'react'
// import { ArrowRight, Download, X } from 'lucide-react'
// import styles from './ProductSOPs.module.css'

// interface Product {
//   name: string
//   image: string
// }

// const products: Product[] = [
//   { name: "Pro - 250", image: "/t-250.png" },
//   { name: "Pro - 500", image: "/t-500.png" },
//   { name: "Pro - 1500", image: "/pro-1500.png" },
//   { name: "Pro - 4000", image: "/pro-4000.png" },
// ]

// export default function ProductSOPs() {
//   const [isVideoOpen, setIsVideoOpen] = useState(false)

//   const openVideo = () => {
//     setIsVideoOpen(true)
//   }

//   const closeVideo = () => {
//     setIsVideoOpen(false)
//   }

//   return (
//     <div className={styles.container}>
//       <p className={styles.resourcesHeader}>Resources</p>
//       <h1 className={styles.title}>Standard Operating Procedures.</h1>
//       <p className={styles.description}>
//         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
//         Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.
//       </p>
//       <div className={styles.productList}>
//         {products.map((product, index) => (
//           <div key={index} className={styles.productItem} style={{"--animation-order": index} as React.CSSProperties}>
//             <img
//               src={product.image}
//               alt={`${product.name} preview`}
//               className={styles.productImage}
//             />
//             <div className={styles.productInfo}>
//               <h2 className={styles.productName}>{product.name}</h2>
//             </div>
//             <button className={styles.downloadButton} onClick={openVideo}>
//               <Download className={styles.downloadIcon} />
//               <span>View Instructional Video</span>
//             </button>
//           </div>
//         ))}
//       </div>
//       <div className={styles.exploreSection}>
//         <p className={styles.exploreTitle}>Up Next:</p>
//         <div className={styles.exploreContent}>
//           <h2 className={styles.exploreHeading}>Explore Instructional Videos</h2>
//           <button className={styles.exploreButton}>
//             <ArrowRight className={styles.arrowIcon} />
//           </button>
//         </div>
//       </div>
//       {isVideoOpen && (
//         <div className={styles.videoModal}>
//           <div className={styles.videoModalContent}>
//             <button className={styles.closeButton} onClick={closeVideo}>
//               <X className={styles.closeIcon} />
//             </button>
//             <video src="https://example.com/instructional-video.mp4" controls autoPlay className={styles.videoPlayer} />
//           </div>
//         </div>
//       )}
//     </div>
//   )
// }


// // import React from 'react'
// // import { ArrowRight, Download } from 'lucide-react'
// // import styles from './ProductSOPs.module.css'

// // interface Product {
// //   name: string
// //   image: string
// // }

// // const products: Product[] = [
// //   { name: "Pro - 250", image: "/t-250.png" },
// //   { name: "Pro - 500", image: "/t-500.png" },
// //   { name: "Pro - 1500", image: "/pro-1500.png" },
// //   { name: "Pro - 4000", image: "/pro-4000.png" },
// // ]

// // export default function ProductSOPs() {
// //   return (
// //     <div className={styles.container}>
// //       <p className={styles.resourcesHeader}>Resources</p>
// //       <h1 className={styles.title}>Standard Operating Procedures.</h1>
// //       <p className={styles.description}>
// //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
// //         Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.
// //       </p>
// //       <div className={styles.productList}>
// //         {products.map((product, index) => (
// //           <div key={index} className={styles.productItem} style={{"--animation-order": index} as React.CSSProperties}>
// //             <img
// //               src={product.image}
// //               alt={`${product.name} preview`}
// //               className={styles.productImage}
// //             />
// //             <div className={styles.productInfo}>
// //               <h2 className={styles.productName}>{product.name}</h2>
// //             </div>
// //             <button className={styles.downloadButton}>
// //               <Download className={styles.downloadIcon} />
// //               <span>View Instructional Video</span>
// //             </button>
// //           </div>
// //         ))}
// //       </div>
// //       <div className={styles.exploreSection}>
// //         <p className={styles.exploreTitle}>Up Next:</p>
// //         <div className={styles.exploreContent}>
// //           <h2 className={styles.exploreHeading}>Explore Instructional Videos</h2>
// //           <button className={styles.exploreButton}>
// //             <ArrowRight className={styles.arrowIcon} />
// //           </button>
// //         </div>
// //       </div>
// //     </div>
// //   )
// // }

// // // import React from 'react'
// // // import { ArrowRight, Download } from 'lucide-react'
// // // import styles from './ProductSOPs.module.css'

// // // interface Product {
// // //   name: string
// // //   image: string
// // // }

// // // const products: Product[] = [
// // //   { name: "Pro - 250", image: "/placeholder.svg?height=100&width=100" },
// // //   { name: "Pro - 500", image: "/placeholder.svg?height=100&width=100" },
// // //   { name: "Pro - 1500", image: "/placeholder.svg?height=100&width=100" },
// // //   { name: "Pro - 4000", image: "/placeholder.svg?height=100&width=100" },
// // // ]

// // // export default function ProductSOPs() {
// // //   return (
// // //     <div className={styles.container}>
// // //       <h1 className={styles.title}>Standard Operating Procedures.</h1>
// // //       <p className={styles.description}>
// // //         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.
// // //         Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.
// // //       </p>
// // //       <div className={styles.productList}>
// // //         {products.map((product, index) => (
// // //           <div key={index} className={styles.productItem} style={{"--animation-order": index} as React.CSSProperties}>
// // //             <img
// // //               src={product.image}
// // //               alt={`${product.name} preview`}
// // //               className={styles.productImage}
// // //             />
// // //             <div className={styles.productInfo}>
// // //               <h2 className={styles.productName}>{product.name}</h2>
// // //             </div>
// // //             <button className={styles.downloadButton}>
// // //               <Download className={styles.downloadIcon} />
// // //               <span>Download</span>
// // //             </button>
// // //           </div>
// // //         ))}
// // //       </div>
// // //       <div className={styles.exploreSection}>
// // //         <p className={styles.exploreTitle}>Up Next:</p>
// // //         <div className={styles.exploreContent}>
// // //           <h2 className={styles.exploreHeading}>Explore Instructional Videos</h2>
// // //           <button className={styles.exploreButton}>
// // //             <ArrowRight className={styles.arrowIcon} />
// // //           </button>
// // //         </div>
// // //       </div>
// // //     </div>
// // //   )
// // // }
/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import ComparisonTable from './ComparisonTable';

const comparisonItems = [
  {
    neutraFoggers: "Savings, less cost over 12-month period",
    traditional: "Expensive, higher cost over 12-month period"
  },
  {
    neutraFoggers: "Applying product with automation, used remotely",
    traditional: "Physical person applying product, on-site dependent"
  },
  {
    neutraFoggers: "Quicker application time (under 3 min)",
    traditional: "Longer application time (hours to entire day)"
  },
  {
    neutraFoggers: "Fog particle 1 micron, consistent coverage (3D)",
    traditional: "Fog particle 5–250 micron (more product used), potentially inconsistent coverage"
  },
  {
    neutraFoggers: "Quicker results and preparation (just exchange the fluid bag)",
    traditional: "Longer results and more preparation time"
  },
  {
    neutraFoggers: "Less product wastage",
    traditional: "More product wastage"
  },
  {
    neutraFoggers: "Safe to use",
    traditional: "Safe to use"
  },
  {
    neutraFoggers: "Schedule different applications in separate rooms at the same time",
    traditional: "Schedule dependent on user availability, application applied one room at a time"
  },
  {
    neutraFoggers: "Log keeping saved digitally on the platform",
    traditional: "Record keeping dependent on user"
  },
  {
    neutraFoggers: "Easy to clean (purge unit)",
    traditional: "Takes longer to clean traditional sprayers"
  }
];

const ProsAndConsMobile: React.FC = () => {
  return (
    <ComparisonTable items={comparisonItems} />
  );
};

export default ProsAndConsMobile;

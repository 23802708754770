/**
 * This code was generated by Builder.io.
 */
import React from 'react';
import styles from './HeroSection.module.css';

interface HeroSectionProps {
  backgroundImage: string;
  headline: string;
  description: string;
  ctaText: string;
  ctaIcon: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  backgroundImage,
  headline,
  description,
  ctaText,
  ctaIcon
}) => {
  return (
    <section className={styles.heroSection}>
      <img
        src={backgroundImage}
        alt=""
        className={styles.backgroundImage}
        aria-hidden="true"
      />
      <div className={styles.contentWrapper}>
        <span className={styles.headline}>{headline}</span>
        <div className={styles.contentRow}>
          <p className={styles.description}>{description}</p>
          <div className={styles.ctaWrapper}>
            <span className={styles.ctaText}>
              {ctaText}
            </span>
            <img src={ctaIcon} alt="" className={styles.ctaIcon} aria-hidden="true" />
          </div>
        </div>
          <hr className={styles.divider} />
      </div>
    </section>
  );
};

export default HeroSection;

import { useState } from 'react'
import ProductGrid from './ProductGrid'
import styles from './ProductPage.module.css'

export default function ProductPage() {
  const [containerWidth, setContainerWidth] = useState(800)

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContainerWidth(Number(event.target.value))
  }

  return (
    <div className={styles.pageContainer}>
      {/* <div className={styles.widthControl}>
        <label htmlFor="width-slider">Adjust container width: {containerWidth}px</label>
        <input
          id="width-slider"
          type="range"
          min="400"
          max="1200"
          value={containerWidth}
          onChange={handleWidthChange}
        />
      </div> */}
      <div className={styles.productGridContainer} style={{ maxWidth: `${containerWidth}px` }}>
        <ProductGrid />
      </div>
    </div>
  )
}
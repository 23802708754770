import React from 'react';
import HeroSection from './HeroSection';

const HeroSectionVideo: React.FC = () => {
  return (
    <main>
      <HeroSection
        videoSrc="/video/homepage-test.mp4"
        videoPoster="https://cdn.builder.io/api/v1/image/assets/TEMP/6169958165130f0f47c49ec83f7ac0ba7e9d59eddf5e6c078d488a39e85acd11?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
        headline="Revolutionize Your Greenhouse With Our IPM Solutions"
        description="Empower your crops with cutting-edge Integrated Pest Management (IPM) technologies. Our solutions ensure healthier plants, higher yields, and sustainable operations."
        ctaText="explore neutrafog systems"
        ctaIcon="https://cdn.builder.io/api/v1/image/assets/TEMP/20a69bce23098a725a014f7d656da5d7f7af275e426dceb4f1f29229dee58e17?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
        ctaLink="/our-systems"
        secondaryCtaText1="Calculate Savings"
        secondaryCtaLink1="#calculate"
        secondaryCtaText2="Why Choose Neutrafog?"
        secondaryCtaLink2="/our-systems/#proscons"
      />
    </main>
  );
};

export default HeroSectionVideo;

// import React from 'react';
// import HeroSection from './HeroSection';

// const HeroSectionVideo: React.FC = () => {
//   return (
//     <main>
//       <HeroSection
//               videoSrc="/video/homepage.mp4"
//         // videoSrc="https://neutrastorm.com/videos/1.mp4"
//         videoPoster="https://cdn.builder.io/api/v1/image/assets/TEMP/6169958165130f0f47c49ec83f7ac0ba7e9d59eddf5e6c078d488a39e85acd11?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
//         headline="Revolutionize Your Greenhouse With Our IPM Solutions"
//         description="Empower your crops with cutting-edge Integrated Pest Management (IPM) technologies. Our solutions ensure healthier plants, higher yields, and sustainable operations."
//         ctaText="explore neutrafog systems"
//         ctaIcon="https://cdn.builder.io/api/v1/image/assets/TEMP/20a69bce23098a725a014f7d656da5d7f7af275e426dceb4f1f29229dee58e17?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
//         ctaLink="/our-systems" // Add this line

//       />
//     </main>
//   );
// };

// export default HeroSectionVideo;

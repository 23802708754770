/**
 * This code was generated by Builder.io.
 */
import React from 'react'
import styles from './TeamSection.module.css'
import TeamMember from './TeamMember'

const teamMembers = [
  {
    name: 'Mike D.',
    position: 'Chief Executive Officer',
    description:
      'Previously CEO of Gamma Holdings Manufacturing in Paarl, Cape Town South Africa. ',
      // Robust background in manufacturing & electronics, established NeutraTech in 2020
    
    imageSrc:
      '/img/ourTeam/avery_our_team.png',
  },
  {
    name: 'Adrian D.',
    position: 'Chief Operating Officer',
    description:
      'Sales executive with a track record of exceeding targets and building strong client relationships. Background in sales.',
    imageSrc:
      '/img/ourTeam/adrian_our_team.jpg',
  },
{
  name: 'Chris M.',
  position: 'Chief Technology Officer',
  description:
  'Chris, a 3x founder, leads the development and scaling of multiple startups. With deep expertise in AI, machine learning, and growth hacking, Chris excels at aligning technology strategy with business objectives to drive innovation and scalable growth. He has worked with notable brands like the NBA, NFL, MLB, and Maybach.',
  imageSrc:
      '/img/ourTeam/sergio_our_team.png',
},
  
  {
    name: 'Sergio C.',
    position: 'Warehouse Clerk',
    description:
      '4+ years in the shipping and logistic space (Formely @Amazon)',
    imageSrc:
      // '/img/ourTeam/michelle_our_team.png',
     
      '/img/ourTeam/sergio_our_team.png',
  },
  {
    name: 'Michelle U.',
    position: 'Cultivator & Influencer',
    description:
      '(aka. Miss Rad Reefer) professional cannabis cultivator and influencer',
    imageSrc:
      '/img/ourTeam/michelle_our_team.png',
    },
  {
    name: 'James A.',
    position: 'Sales Rep',
    description:
      'Extensive experience in BD and sales for tech companies expert communicator',
    imageSrc:
      '/img/ourTeam/russel_our_team.png',
  },
  {
    name: 'Sam A.',
    position: 'Technical Support',
    description:
      '14+ years of technical expertise across software and hardware solutions',
    imageSrc:
      '/img/ourTeam/sam_our_team.png',
  },
  {
    name: 'Emily A.',
    position: 'Service Support',
    description:
      '',
    imageSrc:
      '/img/ourTeam/michelle_our_team.png',
    },
  {
    name: 'Adam S.',
    position: 'IPM Advisor',
    description:
      'Extensive regulatory & ops knowledge from 5+ years in commercial cultivation',
    imageSrc:
      '/img/ourTeam/avery_our_team.png',
  },
]

const TeamSection: React.FC = () => {
  return (
    <section className={styles.teamSection}>
      <h2 className={styles.sectionTitle}>our team</h2>
      <hr className={styles.divider} />
      <div className={styles.teamGrid}>
        <div className={styles.teamColumns}>
          {teamMembers.map((teamMember, index) => (
            <TeamMember
              key={index}
              name={teamMember.name}
              position={teamMember.position}
              description={teamMember.description}
              imageSrc={teamMember.imageSrc}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default TeamSection

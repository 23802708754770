import React from 'react';
import styles from './Modal.module.css';

interface ModalProps {
  show: boolean;
  handleClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ show, handleClose }) => {
  return (
    <div className={`${styles.modal} ${show ? styles.show : ''}`}>
      <div className={styles.modalContent}>
        <span className={styles.close} onClick={handleClose}>&times;</span>
        <p>Form successfully submitted!</p>
      </div>
    </div>
  );
};

export default Modal;

// import React from 'react';

// interface ModalProps {
//   show: boolean;
//   handleClose: () => void;
// }

// const Modal: React.FC<ModalProps> = ({ show, handleClose }) => {
//   return (
//     <div className={`modal ${show ? 'show' : ''}`}>
//       <div className="modal-content">
//         <span className="close" onClick={handleClose}>&times;</span>
//         <p>Form successfully submitted!</p>
//       </div>
//     </div>
//   );
// };

// export default Modal;

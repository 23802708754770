import React from 'react'
import styles from './styles.module.css'
import cx from 'classnames'

interface FormFieldProps {
  label: string
  className?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  className,
  value,
  name,
  onChange,
}) => {
  const id = label.replace(/\s+/g, '-').toLowerCase()

  return (
    <div className={styles.formField}>
      <input
        id={id}
        className={cx(className, styles.formInput)}
        placeholder={label}
        value={value}
        name={name}
        onChange={onChange}
      />
    </div>
  )
}

export default FormField

import React from 'react';
import styles from './ProductPage.module.css';
import Header from './Header';
import ProductImage from './ProductImage';
import ProductInfo from './ProductInfo';
import AdditionalInfo from './AdditionalInfo';
import SolutionWorks from './SolutionWorks';
import SystemDetails from './SystemDetails';
import Footer from './Footer';

interface IPMPagesProps {
  productImages: { image: string }[];
  productName: string;
  productPrice: string;
  productDescription: string;
  productId: string;
  features: { title: string; description: string }[];
  accordionData: { title: string; content: string }[];
  solutionWorksTitle: string;
  solutionWorksDescription: string;
  solutionWorksBulletPoints: string[];
  solutionWorksAdditionalDescription: string;
}

const IPMPages: React.FC<IPMPagesProps> = ({
  productImages = [], // Default value to ensure it's never undefined
  productName,
  productPrice,
  productDescription,
  productId,
  features,
  accordionData,
  solutionWorksTitle,
  solutionWorksDescription,
  solutionWorksBulletPoints,
  solutionWorksAdditionalDescription,
}) => {
  const imageUrls = productImages.map(img => img.image);

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.productSection}>
          <ProductImage images={imageUrls} />
          <ProductInfo
            productName={productName}
            productPrice={productPrice}
            productDescription={productDescription}
            productId={productId}
          />
        </div>
        <SolutionWorks
          title={solutionWorksTitle}
          description={solutionWorksDescription}
          bulletPoints={solutionWorksBulletPoints}
          additionalDescription={solutionWorksAdditionalDescription}
        />
        <AdditionalInfo accordionData={accordionData} />
      </main>
      <SystemDetails features={features} />
      {/* <Footer /> */}
    </div>
  );
};

export default IPMPages;

// import React from 'react';
// import styles from './ProductPage.module.css';
// import Header from './Header';
// import ProductImage from './ProductImage';
// import ProductInfo from './ProductInfo';
// import AdditionalInfo from './AdditionalInfo';
// import SolutionWorks from './SolutionWorks';
// import SystemDetails from './SystemDetails';
// import Footer from './Footer';

// interface IPMPagesProps {
//   productImages: { image: string }[];
//   productName: string;
//   productPrice: string;
//   productDescription: string;
//   productId: string;
//   features: { title: string; description: string }[];
//   accordionData: { title: string; content: string }[];
// }

// const IPMPages: React.FC<IPMPagesProps> = ({
//   productImages = [], // Default value to ensure it's never undefined
//   productName,
//   productPrice,
//   productDescription,
//   productId,
//   features,
//   accordionData,
// }) => {
//   const imageUrls = productImages.map(img => img.image);

//   return (
//     <div className={styles.container}>
//       <main className={styles.main}>
//         <div className={styles.productSection}>
//         <ProductImage images={imageUrls} />
//         <ProductInfo
//             productName={productName}
//             productPrice={productPrice}
//             productDescription={productDescription}
//             productId={productId}
//           />
//         </div>
// <SolutionWorks />
//         <AdditionalInfo accordionData={accordionData} />

//       </main>
//       <SystemDetails features={features} />

//       {/* <Footer /> */}
//     </div>
//   );
// };

// export default IPMPages;
import React from 'react';
import styles from './header.module.css';

const ResourceHeader: React.FC = () => {
  return (
    <header className={styles.resourceHeader}>
      <h2 className={styles.resourceType}>resources</h2>
      <h1 className={styles.resourceTitle}>Standard Operating Procedures.</h1>
      <p className={styles.resourceDescription}>
      Follow these best practices to maintain high crop quality, and maximize the benefits of your NeutraFog system.
      </p>
    </header>
  );
};

export default ResourceHeader;

import React, { useState } from 'react'
import { Plus, Minus, ArrowRight } from 'lucide-react'
import styles from './FAQs.module.css'

interface FAQ {
  question: string
  answer: string
}


const faqs: FAQ[] = [
  {
    question: "What Type Of Connection Do I Need For Remote Operation?",
    answer: "For remote operation, you need a stable internet connection. Our system supports both Wi-Fi and cellular connections, ensuring you can control your NeutraFog unit from anywhere with internet access."
  },
  {
    question: "Can I Use My Own Products With The NeutraFog Unit?",
    answer: "While the NeutraFog unit is optimized for use with our specially formulated products, it can accommodate certain third-party solutions. However, we recommend using our products to ensure optimal performance and to maintain your warranty."
  },
  {
    question: "Can We Use The Product In A Greenhouse?",
    answer: "Yes, the NeutraFog system is perfectly suited for use in greenhouses. It's designed to create an ideal environment for plant growth and pest control in enclosed spaces."
  },
  {
    question: "Can I Use The Device For Any Crop?",
    answer: "The NeutraFog unit is versatile and can be used for a wide variety of crops. However, different crops may require different settings or solutions. We recommend consulting our crop-specific guides for optimal results."
  },
  {
    question: "Can I Use My Own Products With The NeutraFog Unit?",
    answer: "While it's possible to use your own products, we strongly recommend using NeutraFog-approved solutions to ensure optimal performance and maintain your warranty. Some third-party products may be compatible, but it's best to consult with our support team first."
  },
  {
    question: "How Effective Is The Fog?",
    answer: "Our fog is highly effective due to its ultra-fine particle size, which allows for excellent coverage and penetration. It can reach areas that traditional spraying methods often miss, ensuring comprehensive treatment."
  },
  {
    question: "Can I Use The My NeutraFog Unit Remotely?",
    answer: "Yes, the NeutraFog unit can be operated remotely through our dedicated mobile app or web interface, allowing you to control and monitor your system from anywhere with an internet connection."
  },
  {
    question: "How Do I Properly Maintain And Clean A Fogger System?",
    answer: "Regular maintenance includes flushing the system with clean water after each use, checking and cleaning nozzles, and inspecting all connections. We provide a detailed maintenance guide with each unit for specific instructions."
  },
  {
    question: "What Types Of IPM Products Can Be Used With Each System?",
    answer: "Our systems are compatible with a wide range of Integrated Pest Management (IPM) products, including biological controls, botanical oils, and synthetic pesticides. Always check the product label for fogger compatibility."
  },
  {
    question: "Are There Any Precautions I Should Take Before And After Using A Fogger System?",
    answer: "Before fogging, ensure proper ventilation and wear appropriate personal protective equipment. After fogging, allow the treated area to settle according to the product instructions before re-entry. Always follow safety guidelines provided with your unit and products."
  },
  {
    question: "Does The Fogger System Work The Same Indoors And Outdoors?",
    answer: "While the NeutraFog system can be used both indoors and outdoors, its effectiveness may vary depending on environmental conditions. Indoor use typically provides more controlled conditions, while outdoor use may require adjustments based on weather and wind patterns."
  }
  // {
  //   question: "What Type Of Connection Do I Need For Remote Operation?",
  //   answer: "For remote operation, you need a stable internet connection. Our system supports both Wi-Fi and cellular connections, ensuring you can control your Neutrafog unit from anywhere with internet access."
  // },
  // {
  //   question: "Can I Use My Own Products With The Neutrafog Unit?",
  //   answer: "While the Neutrafog unit is optimized for use with our specially formulated products, it can accommodate certain third-party solutions. However, we recommend using our products to ensure optimal performance and to maintain your warranty."
  // },
  // {
  //   question: "Can We Use The Product In A Green House?",
  //   answer: "Yes, the Neutrafog system is perfectly suited for use in greenhouses. It's designed to create an ideal environment for plant growth and pest control in enclosed spaces."
  // },
  // {
  //   question: "Can I Use The Device For Any Crop?",
  //   answer: "The Neutrafog unit is versatile and can be used for a wide variety of crops. However, different crops may require different settings or solutions. We recommend consulting our crop-specific guides for optimal results."
  // },
  // {
  //   question: "Can I Use My Own Products With The Neutrafog Unit?",
  //   answer: "While it's possible to use your own products, we strongly recommend using Neutrafog-approved solutions to ensure optimal performance and maintain your warranty. Some third-party products may be compatible, but it's best to consult with our support team first."
  // },
  // {
  //   question: "How Effective Is The Fog?",
  //   answer: "Our fog is highly effective due to its ultra-fine particle size, which allows for excellent coverage and penetration. It can reach areas that traditional spraying methods often miss, ensuring comprehensive treatment."
  // },
  // {
  //   question: "Can I Use The My Neutrafog Unit Remotely?",
  //   answer: "Yes, the Neutrafog unit can be operated remotely through our dedicated mobile app or web interface, allowing you to control and monitor your system from anywhere with an internet connection."
  // },
  // {
  //   question: "How Do I Properly Maintain And Clean A Fogger System?",
  //   answer: "Regular maintenance includes flushing the system with clean water after each use, checking and cleaning nozzles, and inspecting all connections. We provide a detailed maintenance guide with each unit for specific instructions."
  // },
  // {
  //   question: "What Types Of IPM Products Can Be Used With Each System?",
  //   answer: "Our systems are compatible with a wide range of Integrated Pest Management (IPM) products, including biological controls, botanical oils, and synthetic pesticides. Always check the product label for fogger compatibility."
  // },
  // {
  //   question: "Are There Any Precautions I Should Take Before And After Using A Fogger System?",
  //   answer: "Before fogging, ensure proper ventilation and wear appropriate personal protective equipment. After fogging, allow the treated area to settle according to the product instructions before re-entry. Always follow safety guidelines provided with your unit and products."
  // },
  // {
  //   question: "Does The Fogger System Work The Same Indoors And Outdoors?",
  //   answer: "While the Neutrafog system can be used both indoors and outdoors, its effectiveness may vary depending on environmental conditions. Indoor use typically provides more controlled conditions, while outdoor use may require adjustments based on weather and wind patterns."
  // }
]

interface FAQsProps {
  onExploreNewsBlog: () => void
}

// export default function FAQs({ onExploreNewsBlog }: FAQsProps) {
  const FAQs: React.FC<FAQsProps> = ({ onExploreNewsBlog }) => {

  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className={styles.container}>
      <p className={styles.resourcesHeader}>Resources</p>
      <h1 className={styles.title}>Frequently Asked Questions.</h1>
      <div className={styles.faqList}>
        {faqs.map((faq, index) => (
          <div key={index} className={styles.faqItem}>
            <button
              className={`${styles.faqQuestion} ${openIndex === index ? styles.open : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              <span className={styles.iconWrapper}>
                {openIndex === index ? (
                  <Minus className={styles.icon} />
                ) : (
                  <Plus className={styles.icon} />
                )}
              </span>
              {faq.question}
            </button>
            <div className={`${styles.faqAnswer} ${openIndex === index ? styles.open : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.exploreSection}>
        <h2 className={styles.exploreHeading}>Explore News & Blog</h2>
        <button className={styles.exploreButton} onClick={onExploreNewsBlog}>
          <ArrowRight className={styles.arrowIcon} />
        </button>
      </div>
    </div>
  )
}

export default FAQs
import React from 'react'
import styles from './nav.module.css'
import cx from 'classnames'

type HeaderProps = {
  toggleMenu: () => void;
  menuOpen: boolean;
  toggleCall: () => void;
};

const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall }) => {
  return (
    <div
      className={`${styles.flex} ${styles['gap-5']} ${styles['justify-between']} ${styles['items-center']} ${styles['w-full']} ${styles['text-4xl']} ${styles['font-bold']} ${styles['tracking-tighter']} ${styles['text-center']} ${styles['text-white']} ${styles['whitespace-nowrap']} ${styles['max-md:flex-wrap']} ${styles['max-md:max-w-full']}`}
      style={{
        width: '100%',
      }}
    >
      <div className={`${styles.flex} ${styles['gap-5']}`}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
          className={`${styles['shrink-0']} ${styles['aspect-0-99']} ${styles['w-69px']}`}
          style={{
            objectFit: 'contain',
          }}
          alt=""
        />
        <div className={`${styles['flex-auto']} ${styles['my-auto']}`}>
          NeutraFog
        </div>
      </div>

      <div className={cx(styles.flex)}>
        {menuOpen ? (
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
            className={`${styles['w-70px']} ${styles.pointer}`}
            onClick={toggleMenu}
            alt=""
          />
        ) : (
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
            className={`${styles['w-89px']} ${styles.pointer}`}
            onClick={toggleMenu}
            alt=""
          />
        )}
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F415cd4ab860f4cf9acab124056bf2da5"
          className={cx(styles['w-89px'], styles.pointer)}
          onClick={toggleCall}
          alt=""
        />
      </div>
    </div>
  )
}

export default Header

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC, useState } from 'react'
import { Button } from '@theme-ui/components'
import { useAddItemToCart } from '@lib/shopify/storefront-data-hooks'
import { getProduct } from '@lib/shopify/storefront-data-hooks/src/api/operations'
import shopifyConfig from '@config/shopify'

interface AddToCartButtonProps {
  productId: string
  variantId?: string
  quantity?: number
  text?: string
}

export const AddToCartButton: FC<AddToCartButtonProps> = ({
  productId,
  variantId,
  quantity = 1,
  text = 'Add to Cart',
}) => {
  const addItem = useAddItemToCart()
  const [loading, setLoading] = useState(false)

  const addToCart = async () => {
    setLoading(true)
    try {
      let variant = variantId
      if (!variant) {
        const product = await getProduct(shopifyConfig, { id: productId })
        variant = product.variants[0].id
      }
      if (typeof variant === 'string' || typeof variant === 'number') {
        await addItem(variant, quantity)
      } else {
        throw new Error('Invalid variant ID')
      }
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  return (
    <Button
      name="add-to-cart"
      disabled={loading}
      sx={{ margin: 2, display: 'block' }}
      onClick={addToCart}
    >
      {loading ? 'Adding...' : text}
    </Button>
  )
}

export default AddToCartButton
// /** @jsxRuntime classic */
// /** @jsx jsx */
// import { jsx } from 'theme-ui'
// import { FC, useState } from 'react'
// import { Button } from '@theme-ui/components'
// import { useAddItemToCart } from '@lib/shopify/storefront-data-hooks'

// interface AddToCartButtonProps {
//   variantId: string | number
//   quantity?: number
//   text?: string
// }

// export const AddToCartButton: FC<AddToCartButtonProps> = ({
//   variantId,
//   quantity = 1,
//   text = 'Add to Cart',
// }) => {
//   const addItem = useAddItemToCart()
//   const [loading, setLoading] = useState(false)

//   const addToCart = async () => {
//     setLoading(true)
//     try {
//       await addItem(variantId, quantity)
//       setLoading(false)
//     } catch (err) {
//       console.error(err)
//       setLoading(false)
//     }
//   }

//   return (
//     <Button
//       name="add-to-cart"
//       disabled={loading}
//       sx={{ margin: 2, display: 'block' }}
//       onClick={addToCart}
//     >
//       {loading ? 'Adding...' : text}
//     </Button>
//   )
// }

// export default AddToCartButton
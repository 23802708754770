import React from 'react';
import styles from './ProductInfo.module.css';
import AddToCartButton from '../../blocks/AddToCart/AddToCartButton';

interface ProductInfoProps {
  productName: string;
  productPrice: string;
  productDescription: string;
  productId: string;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ productName, productPrice, productDescription, productId }) => {
  return (
    <div className={styles.infoContainer}>
      <h1 className={styles.productName}>{productName}</h1>
      <p className={styles.price}>{productPrice}</p>
      <p className={styles.description}>{productDescription}</p>
      <AddToCartButton productId={productId} />
      {/* <button className={styles.quoteButton}>Contact For A Quote</button> */}
    </div>
  );
};

export default ProductInfo;
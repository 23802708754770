import React, { useState } from 'react'
import { ArrowRight, Play, X } from 'lucide-react'
import styles from './InstructionalVideos.module.css'

interface Video {
  title: string
  description: string
  thumbnail: string
  videoUrl: string
}



const videos: Video[] = [
  {
    title: "Setting Up Your Fogger System.",
    description: "Easily set up your NeutraFog system for optimal performance with our step-by-step instructions. From assembly to calibration, ensure your fogger is ready to deliver effective pest management right from the start.",
    thumbnail: "/dev-preview.png",
    videoUrl: "/Neutrafog_tutorial.mp4"
  },
  // {
  //   title: "Headline For Second Video Content",
  //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.",
  //   thumbnail: "/dev-preview.png",
  //   videoUrl: "/Neutrafog_tutorial.mp4"
  // },
  // {
  //   title: "Headline For Third Video Content",
  //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.",
  //   thumbnail: "/dev-preview.png",
  //   videoUrl: "/Neutrafog_tutorial.mp4"
  // }
]

interface InstructionalVideosProps {
  onExploreFAQs: () => void
}

// export default function InstructionalVideos({ onExploreFAQs }: InstructionalVideosProps) {

  const InstructionalVideos: React.FC<InstructionalVideosProps> = ({ onExploreFAQs }) => {

  const [activeVideo, setActiveVideo] = useState<string | null>(null)

  const playVideo = (videoUrl: string) => {
    setActiveVideo(videoUrl)
  }

  const closeVideo = () => {
    setActiveVideo(null)
  }

  return (
    <div className={styles.container}>
      {videos.map((video, index) => (
        <div key={index} className={`${styles.videoSection} ${index % 2 !== 0 ? styles.darkBg : ''}`}>
          <div className={styles.content}>
            <div className={styles.textContent}>
              <span className={styles.partNumber}>Part {index + 1}</span>
              <h2 className={styles.title}>{video.title}</h2>
              <p className={styles.description}>{video.description}</p>
            </div>
            <div className={styles.videoContainer}>
              <img src={video.thumbnail} alt={video.title} className={styles.thumbnail} />
              <button className={styles.playButton} onClick={() => playVideo(video.videoUrl)}>
                <Play className={styles.playIcon} />
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className={styles.exploreSection}>
        <h2 className={styles.exploreHeading}>Explore FAQs</h2>
        <button className={styles.exploreButton} onClick={onExploreFAQs}>
          <ArrowRight className={styles.arrowIcon} />
        </button>
      </div>
      {activeVideo && (
        <div className={styles.videoModal}>
          <div className={styles.videoModalContent}>
            <button className={styles.closeButton} onClick={closeVideo}>
              <X className={styles.closeIcon} />
            </button>
            <video src={activeVideo} controls autoPlay className={styles.videoPlayer} />
          </div>
        </div>
      )}
    </div>
  )
}

export default InstructionalVideos

// import React from 'react'
// import { ArrowRight, Play } from 'lucide-react'
// import styles from './InstructionalVideos.module.css'

// interface Video {
//   title: string
//   description: string
//   thumbnail: string
// }

// const videos: Video[] = [
//   {
//     title: "Setting Up Your Fogger System.",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.",
//     thumbnail: "/placeholder.svg?height=400&width=800"
//   },
//   {
//     title: "Headline For Second Video Content",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.",
//     thumbnail: "/placeholder.svg?height=400&width=800"
//   },
//   {
//     title: "Headline For Third Video Content",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.",
//     thumbnail: "/placeholder.svg?height=400&width=800"
//   }
// ]

// interface InstructionalVideosProps {
//   onExploreFAQs: () => void
// }

// export default function InstructionalVideos({ onExploreFAQs }: InstructionalVideosProps) {
//   return (
//     <div className={styles.container}>
//       {videos.map((video, index) => (
//         <div key={index} className={`${styles.videoSection} ${index % 2 !== 0 ? styles.darkBg : ''}`}>
//           <div className={styles.content}>
//             <div className={styles.textContent}>
//               <span className={styles.partNumber}>Part {index + 1}</span>
//               <h2 className={styles.title}>{video.title}</h2>
//               <p className={styles.description}>{video.description}</p>
//             </div>
//             <div className={styles.videoContainer}>
//               <img src={video.thumbnail} alt={video.title} className={styles.thumbnail} />
//               <button className={styles.playButton}>
//                 <Play className={styles.playIcon} />
//               </button>
//             </div>
//           </div>
//         </div>
//       ))}
//       <div className={styles.exploreSection}>
//         <h2 className={styles.exploreHeading}>Explore FAQs</h2>
//         <button className={styles.exploreButton} onClick={onExploreFAQs}>
//           <ArrowRight className={styles.arrowIcon} />
//         </button>
//       </div>
//     </div>
//   )
// }
import React, { useState } from 'react'
import { FileText, Video, HelpCircle, Newspaper } from 'lucide-react'
import ProductSOPs from './ProductSOPs'
import InstructionalVideos from './InstructionalVideos'
import FAQs from './FAQs'

import styles from './page.module.css'

type Section = 'Product SOPs' | 'Instructional Videos' | 'FAQs' | 'News & Blog'


interface Resource {
  title: string
  icon: React.ReactNode
}



export default function Resources() {
  const [activeSection, setActiveSection] = useState<Section>('Product SOPs')

  const handleExploreNewsBlog = () => {
    setActiveSection('News & Blog')
  }
  const resources: Record<Section, Resource> = {
    'Product SOPs': { title: 'Product SOPs', icon: <FileText className={styles.icon} /> },
    'Instructional Videos': { title: 'Instructional Videos', icon: <Video className={styles.icon} /> },
    'FAQs': { title: 'FAQs', icon: <HelpCircle className={styles.icon} /> },
    'News & Blog': { title: 'News & Blog', icon: <Newspaper className={styles.icon} /> },
  }

  const handleExploreVideos = () => {
    setActiveSection('Instructional Videos')
  }

  const handleExploreFAQs = () => {
    setActiveSection('FAQs')
  }

  

  const renderContent = () => {
    switch (activeSection) {
      case 'Product SOPs':
        return <ProductSOPs onExploreVideos={handleExploreVideos} />
      case 'Instructional Videos':
        return <InstructionalVideos onExploreFAQs={handleExploreFAQs} />
        case 'FAQs':
            return <FAQs onExploreNewsBlog={handleExploreNewsBlog} />
      case 'News & Blog':
        return <div>News & Blog - Coming Soon</div>
      default:
        return null
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.cardGrid}>
          {Object.entries(resources).map(([key, resource]) => (
            <div 
              key={key} 
              className={`${styles.card} ${activeSection === key ? styles.activeCard : ''}`}
              onClick={() => setActiveSection(key as Section)}
            >
              {resource.icon}
              <h3 className={styles.cardTitle}>{resource.title}</h3>
            </div>
          ))}
        </div>
        <div className={styles.contentWrapper}>
          {renderContent()}
        </div>
      </div>
    </div>
  )
}



// import React, { useState } from 'react'
// import { FileText, Video, HelpCircle, Newspaper } from 'lucide-react'
// import ProductSOPs from './ProductSOPs'
// import InstructionalVideos from './InstructionalVideos'
// import styles from './page.module.css'

// type Section = 'Product SOPs' | 'Instructional Videos' | 'FAQs' | 'News & Blog'

// interface Resource {
//   title: string
//   icon: React.ReactNode
// }

// export default function Resources() {
//   const [activeSection, setActiveSection] = useState<Section>('Product SOPs')

//   const resources: Record<Section, Resource> = {
//     'Product SOPs': { title: 'Product SOPs', icon: <FileText className={styles.icon} /> },
//     'Instructional Videos': { title: 'Instructional Videos', icon: <Video className={styles.icon} /> },
//     'FAQs': { title: 'FAQs', icon: <HelpCircle className={styles.icon} /> },
//     'News & Blog': { title: 'News & Blog', icon: <Newspaper className={styles.icon} /> },
//   }

//   const handleExploreVideos = () => {
//     setActiveSection('Instructional Videos')
//   }

//   const handleExploreFAQs = () => {
//     setActiveSection('FAQs')
//   }

//   const renderContent = () => {
//     switch (activeSection) {
//       case 'Product SOPs':
//         return <ProductSOPs onExploreVideos={handleExploreVideos} />
//       case 'Instructional Videos':
//         return <InstructionalVideos onExploreFAQs={handleExploreFAQs} />
//       case 'FAQs':
//         return <div>FAQs Content</div>
//       case 'News & Blog':
//         return <div>News & Blog Content</div>
//       default:
//         return null
//     }
//   }

//   return (
//     <div className={styles.container}>
//       <div className={styles.cardGrid}>
//         {Object.entries(resources).map(([key, resource]) => (
//           <div 
//             key={key} 
//             className={`${styles.card} ${activeSection === key ? styles.activeCard : ''}`}
//             onClick={() => setActiveSection(key as Section)}
//           >
//             {resource.icon}
//             <h3 className={styles.cardTitle}>{resource.title}</h3>
//           </div>
//         ))}
//       </div>
//       <div className={styles.contentWrapper}>
//         {renderContent()}
//       </div>
//     </div>
//   )
// }



// // import React, { useState } from 'react'
// // import { FileText, Video, HelpCircle, Newspaper } from 'lucide-react'
// // import ProductSOPs from './ProductSOPs'
// // import InstructionalVideos from './InstructionalVideos'
// // import styles from './page.module.css'

// // type Section = 'Product SOPs' | 'Instructional Videos' | 'FAQs' | 'News & Blog'

// // interface Resource {
// //   title: string
// //   icon: React.ReactNode
// // }

// // export default function Resources() {
// //   const [activeSection, setActiveSection] = useState<Section>('Product SOPs')

// //   const resources: Record<Section, Resource> = {
// //     'Product SOPs': { title: 'Product SOPs', icon: <FileText className={styles.icon} /> },
// //     'Instructional Videos': { title: 'Instructional Videos', icon: <Video className={styles.icon} /> },
// //     'FAQs': { title: 'FAQs', icon: <HelpCircle className={styles.icon} /> },
// //     'News & Blog': { title: 'News & Blog', icon: <Newspaper className={styles.icon} /> },
// //   }

// //   const handleExploreVideos = () => {
// //     setActiveSection('Instructional Videos')
// //   }

// //   const handleExploreFAQs = () => {
// //     setActiveSection('FAQs')
// //   }

// //   const renderContent = () => {
// //     switch (activeSection) {
// //       case 'Product SOPs':
// //         return <ProductSOPs onExploreVideos={handleExploreVideos} />
// //       case 'Instructional Videos':
// //         return <InstructionalVideos onExploreFAQs={handleExploreFAQs} />
// //       case 'FAQs':
// //         return <div>FAQs Content</div>
// //       case 'News & Blog':
// //         return <div>News & Blog Content</div>
// //       default:
// //         return null
// //     }
// //   }

// //   return (
// //     <div className={styles.container}>
// //       <div className={styles.cardGrid}>
// //         {Object.entries(resources).map(([key, resource]) => (
// //           <div 
// //             key={key} 
// //             className={`${styles.card} ${activeSection === key ? styles.activeCard : ''}`}
// //             onClick={() => setActiveSection(key as Section)}
// //           >
// //             {resource.icon}
// //             <h3 className={styles.cardTitle}>{resource.title}</h3>
// //           </div>
// //         ))}
// //       </div>
// //       {renderContent()}
// //     </div>
// //   )
// // }


// // // import React, { useState } from 'react'
// // // import { FileText, Video, HelpCircle, Newspaper } from 'lucide-react'
// // // import ProductSOPs from './ProductSOPs'
// // // import styles from './page.module.css'

// // // type Section = 'Product SOPs' | 'Instructional Videos' | 'FAQs' | 'News & Blog'

// // // interface Resource {
// // //   title: string
// // //   icon: React.ReactNode
// // // }

// // // export default function ResourcesComponent() {
// // //   const [activeSection, setActiveSection] = useState<Section>('Product SOPs')

// // //   const resources: Record<Section, Resource> = {
// // //     'Product SOPs': { title: 'Product SOPs', icon: <FileText className={styles.icon} /> },
// // //     'Instructional Videos': { title: 'Instructional Videos', icon: <Video className={styles.icon} /> },
// // //     'FAQs': { title: 'FAQs', icon: <HelpCircle className={styles.icon} /> },
// // //     'News & Blog': { title: 'News & Blog', icon: <Newspaper className={styles.icon} /> },
// // //   }

// // //   const handleExploreVideos = () => {
// // //     setActiveSection('Instructional Videos')
// // //   }

// // //   const renderContent = () => {
// // //     switch (activeSection) {
// // //       case 'Product SOPs':
// // //         return <ProductSOPs onExploreVideos={handleExploreVideos} />
// // //       case 'Instructional Videos':
// // //         return <div>Instructional Videos Content</div>
// // //       case 'FAQs':
// // //         return <div>FAQs Content</div>
// // //       case 'News & Blog':
// // //         return <div>News & Blog Content</div>
// // //       default:
// // //         return null
// // //     }
// // //   }

// // //   return (
// // //     <div className={styles.container}>
// // //       <div className={styles.cardGrid}>
// // //         {Object.entries(resources).map(([key, resource]) => (
// // //           <div 
// // //             key={key} 
// // //             className={`${styles.card} ${activeSection === key ? styles.activeCard : ''}`}
// // //             onClick={() => setActiveSection(key as Section)}
// // //           >
// // //             {resource.icon}
// // //             <h3 className={styles.cardTitle}>{resource.title}</h3>
// // //           </div>
// // //         ))}
// // //       </div>
// // //       {renderContent()}
// // //     </div>
// // //   )
// // // }

// // // import React, { useState } from 'react'
// // // import { FileText, Video, HelpCircle, Newspaper } from 'lucide-react'
// // // import ProductSOPs from './ProductSOPs'
// // // import styles from './page.module.css'

// // // type Section = 'Product SOPs' | 'Instructional Videos' | 'FAQs' | 'News & Blog'

// // // interface Resource {
// // //   title: string
// // //   icon: React.ReactNode
// // // }

// // // export default function ResourcesComponent() {
// // //   const [activeSection, setActiveSection] = useState<Section>('Product SOPs')

// // //   const resources: Record<Section, Resource> = {
// // //     'Product SOPs': { title: 'Product SOPs', icon: <FileText className={styles.icon} /> },
// // //     'Instructional Videos': { title: 'Instructional Videos', icon: <Video className={styles.icon} /> },
// // //     'FAQs': { title: 'FAQs', icon: <HelpCircle className={styles.icon} /> },
// // //     'News & Blog': { title: 'News & Blog', icon: <Newspaper className={styles.icon} /> },
// // //   }

// // //   const renderContent = () => {
// // //     switch (activeSection) {
// // //       case 'Product SOPs':
// // //         return <ProductSOPs />
// // //       // ... other cases
// // //       default:
// // //         return null
// // //     }
// // //   }

// // //   return (
// // //     <div className={styles.container}>
// // //       <div className={styles.cardGrid}>
// // //         {Object.entries(resources).map(([key, resource]) => (
// // //           <div 
// // //             key={key} 
// // //             className={`${styles.card} ${activeSection === key ? styles.activeCard : ''}`}
// // //             onClick={() => setActiveSection(key as Section)}
// // //           >
// // //             {resource.icon}
// // //             <h3 className={styles.cardTitle}>{resource.title}</h3>
// // //           </div>
// // //         ))}
// // //       </div>
// // //       {renderContent()}
// // //     </div>
// // //   )
// // // }

// // // // import React, { useState } from 'react'
// // // // import { FileText, Video, HelpCircle, Newspaper } from 'lucide-react'
// // // // import styles from './page.module.css'

// // // // type Section = 'Product SOPs' | 'Instructional Videos' | 'FAQs' | 'News & Blog'

// // // // interface Resource {
// // // //   title: string
// // // //   icon: React.ReactNode
// // // // }

// // // // export default function Component() {
// // // //   const [activeSection, setActiveSection] = useState<Section>('FAQs')

// // // //   const resources: Record<Section, Resource> = {
// // // //     'Product SOPs': { title: 'Product SOPs', icon: <FileText className={styles.cardIcon} /> },
// // // //     'Instructional Videos': { title: 'Instructional Videos', icon: <Video className={styles.cardIcon} /> },
// // // //     'FAQs': { title: 'FAQs', icon: <HelpCircle className={styles.cardIcon} /> },
// // // //     'News & Blog': { title: 'News & Blog', icon: <Newspaper className={styles.cardIcon} /> },
// // // //   }

// // // //   const renderContent = () => {
// // // //     switch (activeSection) {
// // // //       case 'Product SOPs':
// // // //         return <ProductSOPs />
// // // //       case 'Instructional Videos':
// // // //         return <InstructionalVideos />
// // // //       case 'FAQs':
// // // //         return <FAQs />
// // // //       case 'News & Blog':
// // // //         return <NewsBlog />
// // // //       default:
// // // //         return null
// // // //     }
// // // //   }

// // // //   return (
// // // //     <div className={styles.container}>
// // // //       <div className={styles.cardGrid}>
// // // //         {Object.entries(resources).map(([key, resource]) => (
// // // //           <div 
// // // //             key={key} 
// // // //             className={`${styles.card} ${activeSection === key ? styles.cardActive : ''}`}
// // // //             onClick={() => setActiveSection(key as Section)}
// // // //           >
// // // //             {resource.icon}
// // // //             <h3 className={styles.cardTitle}>{resource.title}</h3>
// // // //           </div>
// // // //         ))}
// // // //       </div>
// // // //       {renderContent()}
// // // //     </div>
// // // //   )
// // // // }

// // // // function ProductSOPs() {
// // // //   return (
// // // //     <div>
// // // //       <h2 className={styles.sectionTitle}>Standard Operating Procedures</h2>
// // // //       {['Pro - 250', 'Pro - 500', 'Pro - 1500', 'Pro - 4000'].map((product) => (
// // // //         <div key={product} className={styles.listItem}>
// // // //           <span>{product}</span>
// // // //           <button className={styles.button}>Download</button>
// // // //         </div>
// // // //       ))}
// // // //     </div>
// // // //   )
// // // // }

// // // // function InstructionalVideos() {
// // // //   return (
// // // //     <div>
// // // //       <h2 className={styles.sectionTitle}>Setting Up Your Fogger System</h2>
// // // //       <div className={styles.videoContainer}></div>
// // // //       <h2 className={styles.sectionTitle}>Headline For Second Video Content</h2>
// // // //       <div className={styles.videoContainer}></div>
// // // //       <h2 className={styles.sectionTitle}>Headline For Third Video Content</h2>
// // // //       <div className={styles.videoContainer}></div>
// // // //     </div>
// // // //   )
// // // // }

// // // // function FAQs() {
// // // //   const questions = [
// // // //     "What Type Of Connection Do I Need For Remote Operation?",
// // // //     "Can I Use My Own Products With The Neutrafog Unit?",
// // // //     "Can We Use The Product In A Green House?",
// // // //     "Can I Use The Device For Any Crop?",
// // // //     "How Effective Is The Fog?",
// // // //     "Can I Use The My Neutrafog Unit Remotely?",
// // // //     "How Do I Properly Maintain And Clean A Fogger System?",
// // // //     "What Types Of IPM Products Can Be Used With Each System?",
// // // //     "Are There Any Precautions I Should Take Before And After Using A Fogger System?",
// // // //     "Does The Fogger System Work The Same Indoors And Outdoors?",
// // // //   ]

// // // //   return (
// // // //     <div>
// // // //       <h2 className={styles.sectionTitle}>Frequently Asked Questions</h2>
// // // //       {questions.map((question, index) => (
// // // //         <div key={index} className={styles.faqItem}>
// // // //           <h3 className={styles.faqQuestion}>{question}</h3>
// // // //         </div>
// // // //       ))}
// // // //     </div>
// // // //   )
// // // // }

// // // // function NewsBlog() {
// // // //   return (
// // // //     <div>
// // // //       <h2 className={styles.sectionTitle}>Latest News & Blog Posts</h2>
// // // //       <p>Content for News & Blog section goes here.</p>
// // // //     </div>
// // // //   )
// // // // }
const dummyData = {
  tableData: [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Bob Johnson" },
    { id: 4, name: "Alice Brown" },
    { id: 5, name: "Charlie Davis" },
  ],
  dropdownOptions: [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ],
  outputData: {
    option1: {
      title: "Output 1",
      description: "This is the first output option",
      value: 42,
    },
    option2: {
      title: "Output 2",
      description: "This is the second output option",
      value: 84,
    },
  },
};

export const resultData = {
  title: "Pro-250, using Azagaurd",
  sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
  mixture: [
    { name: "Halo", value: "1160ml" },
    { name: "Azagaurd", value: "36ml" },
    { name: "H2O", value: "54ml" },
    { name: "PH", value: "5.8-6.2" },
  ],
  preventativeDays: 7,
  reactiveDays: 5,
};


export default dummyData;

/**
 * This code was generated by Builder.io.
 */
import React from 'react'
import styles from './ComparisonTable.module.css'
import cx from 'classnames'

interface ComparisonItem {
  neutraFoggers: string
  traditional: string
}

interface ComparisonTableProps {
  items: ComparisonItem[]
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({ items }) => {
  return (
    <section className={styles.container}>
      {/* <div className={styles.brandSection}>
        <h1 className={styles.brand}>NeutraFoggers</h1>
        <h2 className={styles.title}>fogging vs traditional method</h2>
        <p className={styles.description}>
          A animation content displaying fogging method vs a traditional method
          needs to be shown......Fogger method is full 3D coverage as this
          application is consistent and covers the entire grow room 100% from
          wall to wall and top to bottom.The traditional spraying concept only
          covers the area being sprayed which is inconsistent and won't provide
          100% results.
        </p>
      </div> */}
      <div className={styles.comparisonTable}>
        <header className={styles.headerRow}>
          <div className={styles.comparisonRow}>
            <div className={styles.comparisonCellHeader}>
              <div className={styles.headerText}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/48cd2b0bfde64813ca35aab6d2098f5ac367cb30d75bc19a5ea1793eaaa9f7cd?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
                  alt=""
                  className={styles.headerIcon}
                />
                <h3 className={styles.headerLabel}>Neutrafog</h3>
              </div>
              {items.map((item, index) => (
                <div key={index} className={styles.comparisonRow}>
                  <div
                    className={cx(
                      styles.comparisonCellGreen,
                      index % 2 === 0 ? styles.bgLightGrey : styles.bgLightGreen
                    )}
                  >
                    {item.neutraFoggers}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.comparisonCellGrayHeader}>
              <div className={styles.headerText}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1d20dd8d1204b234dcf93e63ec8ad3e6e832a17a0be7961b056df4de34df8f32?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
                  alt=""
                  className={styles.headerIcon}
                />
                <h3 className={styles.headerLabel}>Traditional</h3>
              </div>
              {items.map((item, index) => (
                <div key={index} className={styles.comparisonRow}>
                  <div
                    className={cx(
                      index % 2 === 0 ? styles.bgLightBlack : styles.bgLightGrey,
                      styles.comparisonCellGray
                    )}
                  >
                    {item.traditional}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </header>
      </div>
    </section>
  )
}

export default ComparisonTable

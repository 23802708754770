import React, { useState } from 'react'
import styles from './FAQAccordion.module.css'

interface FAQItem {
  question: string
  answer: string
}

interface FAQAccordionProps {
  items: FAQItem[]
  isDarkMode: boolean
  faqTitleColor?: string
  faqContentColor?: string
}

export default function FAQAccordion({
  items,
  isDarkMode,
  faqTitleColor,
  faqContentColor
}: FAQAccordionProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  return (
    <div className={styles.faqAccordion}>
      {items.map((item, index) => (
        <div key={index}>
          <div
            className={`${styles.faqTitle} ${activeIndex === index ? styles.active : ''}`}
            onClick={() => toggleAccordion(index)}
            style={{ color: isDarkMode ? '#ffffff' : faqTitleColor }}
          >
            <img
              className={`${styles.icon} ${activeIndex === index ? styles.rotated : ''}`}
              alt="Toggle accordion"
              src={isDarkMode ? "/img/material-symbols-add-11-light.svg" : "/img/material-symbols-add-11.svg"}
            />
            {item.question}
          </div>
          <div
            className={`${styles.faqContent} ${activeIndex === index ? styles.active : ''}`}
            style={{ color: isDarkMode ? '#ffffff' : faqContentColor }}
          >
            <p>{item.answer}</p>
          </div>
          <div className={styles.faqDivider} style={{ backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)' }} />
        </div>
      ))}
    </div>
  )
}
// import React, { useState } from 'react'
// import styles from './FAQAccordion.module.css'

// interface FAQItem {
//   question: string
//   answer: string
// }

// interface FAQAccordionProps {
//   items: FAQItem[]
//   isDarkMode: boolean
//   faqTitleFontFamily: string
//   faqTitleFontSize: number
//   faqContentFontFamily: string
//   faqContentFontSize: number
// }

// export default function FAQAccordion({
//   items,
//   isDarkMode,
//   faqTitleFontFamily,
//   faqTitleFontSize,
//   faqContentFontFamily,
//   faqContentFontSize
// }: FAQAccordionProps) {
//   const [activeIndex, setActiveIndex] = useState<number | null>(null)

//   const toggleAccordion = (index: number) => {
//     setActiveIndex(activeIndex === index ? null : index)
//   }

//   return (
//     <div className={styles.faqAccordion}>
//       {items.map((item, index) => (
//         <div key={index}>
//           <div
//             className={`${styles.faqTitle} ${activeIndex === index ? styles.active : ''}`}
//             onClick={() => toggleAccordion(index)}
//             style={{
//               color: isDarkMode ? '#ffffff' : '#0b0b0b',
//               fontFamily: faqTitleFontFamily,
//               fontSize: `${faqTitleFontSize}px`
//             }}
//           >
//             <img
//               className={`${styles.icon} ${activeIndex === index ? styles.rotated : ''}`}
//               alt="Toggle accordion"
//               src={isDarkMode ? "/img/material-symbols-add-11-light.svg" : "/img/material-symbols-add-11.svg"}
//             />
//             {item.question}
//           </div>
//           <div
//             className={`${styles.faqContent} ${activeIndex === index ? styles.active : ''}`}
//             style={{
//               color: isDarkMode ? '#ffffff' : '#0b0b0b',
//               fontFamily: faqContentFontFamily,
//               fontSize: `${faqContentFontSize}px`
//             }}
//           >
//             <p>{item.answer}</p>
//           </div>
//           <div className={styles.faqDivider} style={{ backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)' }} />
//         </div>
//       ))}
//     </div>
//   )
// }

// import React, { useState } from 'react'
// import styles from './FAQAccordion.module.css'

// interface FAQItem {
//   question: string
//   answer: string
// }

// interface FAQAccordionProps {
//   items: FAQItem[]
//   isDarkMode: boolean
// }

// export default function FAQAccordion({ items, isDarkMode }: FAQAccordionProps) {
//   const [activeIndex, setActiveIndex] = useState<number | null>(null)

//   const toggleAccordion = (index: number) => {
//     setActiveIndex(activeIndex === index ? null : index)
//   }

//   return (
//     <div className={styles.faqAccordion}>
//       {items.map((item, index) => (
//         <div key={index}>
//           <div
//             className={`${styles.faqTitle} ${activeIndex === index ? styles.active : ''}`}
//             onClick={() => toggleAccordion(index)}
//             style={{ color: isDarkMode ? '#ffffff' : '#0b0b0b' }}
//           >
//             <img
//               className={`${styles.icon} ${activeIndex === index ? styles.rotated : ''}`}
//               alt="Toggle accordion"
//               src={isDarkMode ? "/img/material-symbols-add-11-light.svg" : "/img/material-symbols-add-11.svg"}
//             />
//             {item.question}
//           </div>
//           <div
//             className={`${styles.faqContent} ${activeIndex === index ? styles.active : ''}`}
//             style={{ color: isDarkMode ? '#ffffff' : '#0b0b0b' }}
//           >
//             <p>{item.answer}</p>
//           </div>
//           <div className={styles.faqDivider} style={{ backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)' }} />
//         </div>
//       ))}
//     </div>
//   )
// }

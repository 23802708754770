import React from 'react';
import styles from './HeroSection.module.css';
import Link from 'next/link';
import Image from 'next/image';

interface HeroSectionProps {
  videoSrc: string;
  videoPoster: string;
  headline: string;
  description: string;
  ctaText: string;
  ctaIcon: string;
  ctaLink: string;
  secondaryCtaText1: string;
  secondaryCtaLink1: string;
  secondaryCtaText2: string;
  secondaryCtaLink2: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  videoSrc,
  videoPoster,
  headline,
  description,
  ctaText,
  ctaIcon,
  ctaLink,
  secondaryCtaText1,
  secondaryCtaLink1,
  secondaryCtaText2,
  secondaryCtaLink2
}) => {
  return (
    <section className={styles.heroSection}>
      <video
        autoPlay
        muted
        loop
        playsInline
        poster={videoPoster}
        className={styles.backgroundVideo}
        aria-hidden="true"
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      <div className={styles.contentWrapper}>
        <span className={styles.headline}>{headline}</span>
        <div className={styles.contentRow}>
          <p className={styles.description}>{description}</p>
          <Link href={ctaLink} className={styles.ctaWrapper}>
            <span className={styles.ctaText}>
              {ctaText}
            </span>
            <img src={ctaIcon} alt="" className={styles.ctaIcon} aria-hidden="true" />
          </Link>
          <Link href={secondaryCtaLink1} className={`${styles.ctaWrapper} ${styles.secondaryCta}`}>
            <span className={styles.ctaText}>
              {secondaryCtaText1}
            </span>
            <Image 
              src="/img/arrow-5.svg" 
              alt="" 
              width={15} 
              height={15} 
              className={styles.secondaryCtaIcon} 
              aria-hidden="true"
            />
          </Link>
          <Link href={secondaryCtaLink2} className={`${styles.ctaWrapper} ${styles.secondaryCta}`}>
            <span className={styles.ctaText}>
              {secondaryCtaText2}
            </span>
            <Image 
              src="/img/arrow-5.svg" 
              alt="" 
              width={15} 
              height={15} 
              className={styles.secondaryCtaIcon} 
              aria-hidden="true"
            />
          </Link>
        </div>
        <hr className={styles.divider} />
      </div>
    </section>
  );
};

export default HeroSection;

// import React from 'react';
// import styles from './HeroSection.module.css';
// import Link from 'next/link';

// interface HeroSectionProps {
//   videoSrc: string;
//   videoPoster: string;
//   headline: string;
//   description: string;
//   ctaText: string;
//   ctaIcon: string;
//   ctaLink: string;
//   secondaryCtaText1: string;
//   secondaryCtaLink1: string;
//   secondaryCtaText2: string;
//   secondaryCtaLink2: string;
// }

// const HeroSection: React.FC<HeroSectionProps> = ({
//   videoSrc,
//   videoPoster,
//   headline,
//   description,
//   ctaText,
//   ctaIcon,
//   ctaLink,
//   secondaryCtaText1,
//   secondaryCtaLink1,
//   secondaryCtaText2,
//   secondaryCtaLink2
// }) => {
//   return (
//     <section className={styles.heroSection}>
//       <video
//         autoPlay
//         muted
//         loop
//         playsInline
//         poster={videoPoster}
//         className={styles.backgroundVideo}
//         aria-hidden="true"
//       >
//         <source src={videoSrc} type="video/mp4" />
//       </video>
//       <div className={styles.contentWrapper}>
//         <span className={styles.headline}>{headline}</span>
//         <div className={styles.contentRow}>
//           <p className={styles.description}>{description}</p>
//           <Link href={ctaLink} className={styles.ctaWrapper}>
//             <span className={styles.ctaText}>
//               {ctaText}
//             </span>
//             <img src={ctaIcon} alt="" className={styles.ctaIcon} aria-hidden="true" />
//           </Link>
//           <Link href={secondaryCtaLink1} className={`${styles.ctaWrapper} ${styles.secondaryCta}`}>
//             <span className={styles.ctaText}>
//               {secondaryCtaText1}
//             </span>
//           </Link>
//           <Link href={secondaryCtaLink2} className={`${styles.ctaWrapper} ${styles.secondaryCta}`}>
//             <span className={styles.ctaText}>
//               {secondaryCtaText2}
//             </span>
//           </Link>
//         </div>
//         <hr className={styles.divider} />
//       </div>
//     </section>
//   );
// };

// export default HeroSection;


// import React from 'react';
// import styles from './HeroSection.module.css';
// // import { SharedNav } from '../SharedNav';
// // import { Nav }from '../Nav';
// import Link from 'next/link'; 


// interface HeroSectionProps {
//   videoSrc: string;
//   videoPoster: string;
//   headline: string;
//   description: string;
//   ctaText: string;
//   ctaIcon: string;
//   ctaLink: string; // Add this line

// }

// const HeroSection: React.FC<HeroSectionProps> = ({
//   videoSrc,
//   videoPoster,
//   headline,
//   description,
//   ctaText,
//   ctaIcon,
//   ctaLink // Add this line

// }) => {
//   return (
//     <section className={styles.heroSection}>
//       {/* <SharedNav/> */}
//       <video
//         autoPlay
//         muted
//         loop
//         playsInline
//         poster={videoPoster}
//         className={styles.backgroundVideo}
//         aria-hidden="true"
//       >
//         <source src={videoSrc} type="video/mp4" />
//       </video>
//       <div className={styles.contentWrapper}>
//         <span className={styles.headline}>{headline}</span>
//         <div className={styles.contentRow}>
//           <p className={styles.description}>{description}</p>
//           <Link href={ctaLink} className={styles.ctaWrapper}>
//           {/* <div className={styles.ctaWrapper}> */}
//             <span className={styles.ctaText}>
//               {ctaText}
//             </span>
//             <img src={ctaIcon} alt="" className={styles.ctaIcon} aria-hidden="true" />
//           {/* </div> */}
//           </Link>
//           <Link href={ctaLink} className={styles.ctaWrapper}>
//           {/* <div className={styles.ctaWrapper}> */}
//             <span className={styles.ctaText}>
//               {ctaText}
//             </span>
//             {/* <img src={ctaIcon} alt="" className={styles.ctaIcon} aria-hidden="true" /> */}
//           {/* </div> */}
//           </Link>
//           <Link href={ctaLink} className={styles.ctaWrapper}>
//           {/* <div className={styles.ctaWrapper}> */}
//             <span className={styles.ctaText}>
//               {ctaText}
//             </span>
//             {/* <img src={ctaIcon} alt="" className={styles.ctaIcon} aria-hidden="true" /> */}
//           {/* </div> */}
//           </Link>
//         </div>
//           <hr className={styles.divider} />
//       </div>
//     </section>
//   );
// };

// export default HeroSection;


import React from 'react'
import Link from 'next/link'
import styles from './nav.module.css'
import CallButtonDesktop from './CallModal/CallButtonDesktop'
import cx from 'classnames'

type HeaderProps = {
  toggleMenu: () => void;
  menuOpen: boolean;
  toggleCall: () => void;
  isNavOpen: boolean;
};

const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
  return (
    <header className={styles.fixedHeader}>
      <div
        className={cx(
          styles.flex,
          styles['justify-between'],
          styles['items-center'],
          styles['w-full'],
          styles['text-4xl'],
          styles['font-bold'],
          styles['tracking-tighter'],
          styles['text-center'],
          styles['text-white'],
          styles['whitespace-nowrap'],
          styles['max-md:flex-wrap'],
          styles['max-md:max-w-full']
        )}
      >
        <div className={cx(styles.flex, styles['gap-5'], styles['items-center'])}>
        <Link href="/home" passHref>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
            className={cx(styles['shrink-0'], styles['aspect-0-99'], styles['w-69px'])}
            style={{
              objectFit: 'contain',
            }}
            alt="NeutraFog logo"
          />
          </Link>

          <Link href="/home" passHref>

          <div className={cx(styles['flex-auto'], styles['my-auto'], styles.basic)}>
            NeutraFog
          </div>
          </Link>
        </div>

        <div className={cx(styles.flex, styles['items-center'], styles.navItems)}>
          <div className={styles.menuToggle}>
            {menuOpen ? (
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
                className={cx(styles['w-70px'], styles.pointer)}
                onClick={toggleMenu}
                alt="Close menu"
              />
            ) : (
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
                className={cx(styles['w-89px'], styles.pointer)}
                onClick={toggleMenu}
                alt="Open menu "
              />
            )}
          </div>
          <div className={cx(styles['mr-2'], styles.callButton )}>
            <CallButtonDesktop  />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header



// import React from 'react'
// import styles from './nav.module.css'
// import CallButtonDesktop from './CallModal/CallButtonDesktop'
// import cx from 'classnames'

// type HeaderProps = {
//   toggleMenu: () => void;
//   menuOpen: boolean;
//   toggleCall: () => void;
//   isNavOpen: boolean;
// };

// const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
//   return (
//     <header className={styles.fixedHeader}>
//       <div
//         className={cx(
//           styles.flex,
//           styles['justify-between'],
//           styles['items-center'],
//           styles['w-full'],
//           styles['text-4xl'],
//           styles['font-bold'],
//           styles['tracking-tighter'],
//           styles['text-center'],
//           styles['text-white'],
//           styles['whitespace-nowrap'],
//           styles['max-md:flex-wrap'],
//           styles['max-md:max-w-full']
//         )}
//       >
//         <div className={cx(styles.flex, styles['gap-5'], styles['items-center'])}>
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
//             className={cx(styles['shrink-0'], styles['aspect-0-99'], styles['w-69px'])}
//             style={{
//               objectFit: 'contain',
//             }}
//             alt="NeutraFog logo"
//           />
//           <div className={cx(styles['flex-auto'], styles['my-auto'], styles.basic)}>
//             NeutraFog
//           </div>
//         </div>

//         <div className={cx(styles.flex, styles['items-center'], styles.navItems)}>
//           <div className={styles.menuToggle}>
//             {menuOpen ? (
//               <img
//                 loading="lazy"
//                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
//                 className={cx(styles['w-70px'], styles.pointer)}
//                 onClick={toggleMenu}
//                 alt="Close menu"
//               />
//             ) : (
//               <img
//                 loading="lazy"
//                 src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
//                 className={cx(styles['w-89px'], styles.pointer)}
//                 onClick={toggleMenu}
//                 alt="Open menu"
//               />
//             )}
//           </div>
//           <div className={styles.callButton}>
//             <CallButtonDesktop />
//           </div>
//         </div>
//       </div>
//     </header>
//   )
// }

// export default Header

// //good, fixed but shopping cart is not visible enough

// import React from 'react'
// import styles from './nav.module.css'
// import CallButtonDesktop from './CallModal/CallButtonDesktop'
// import cx from 'classnames'

// type HeaderProps = {
//   toggleMenu: () => void;
//   menuOpen: boolean;
//   toggleCall: () => void;
//   isNavOpen: boolean;
// };

// const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
//   return (
//     <header className={styles.fixedHeader}>
//       <div
//         className={cx(
//           styles.flex,
//           styles['gap-5'],
//           styles['justify-between'],
//           styles['items-center'],
//           styles['w-full'],
//           styles['text-4xl'],
//           styles['font-bold'],
//           styles['tracking-tighter'],
//           styles['text-center'],
//           styles['text-white'],
//           styles['whitespace-nowrap'],
//           styles['max-md:flex-wrap'],
//           styles['max-md:max-w-full']
//         )}
//       >
//         <div className={cx(styles.flex, styles['gap-5'])}>
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
//             className={cx(styles['shrink-0'], styles['aspect-0-99'], styles['w-69px'])}
//             style={{
//               objectFit: 'contain',
//             }}
//             alt="NeutraFog logo"
//           />
//           <div className={cx(styles['flex-auto'], styles['my-auto'], styles.basic)}>
//             NeutraFog
//           </div>
//         </div>

//         <div className={cx(styles.flex, styles['items-center'], styles['gap-6'], styles.navItems)}>
//           <div className={styles.menuToggle}>
//             {menuOpen ? (
//               <img
//                 loading="lazy"
//                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
//                 className={cx(styles['w-70px'], styles.pointer)}
//                 onClick={toggleMenu}
//                 alt="Close menu"
//               />
//             ) : (
//               <img
//                 loading="lazy"
//                 src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
//                 className={cx(styles['w-89px'], styles.pointer)}
//                 onClick={toggleMenu}
//                 alt="Open menu"
//               />
//             )}
//           </div>
//           <div className={styles.callButton}>
//             <CallButtonDesktop />
//           </div>
//         </div>
//       </div>
//     </header>
//   )
// }

// export default Header

// import React from 'react'
// import styles from './nav.module.css'
// import CallButtonDesktop from './CallModal/CallButtonDesktop'
// import cx from 'classnames'

// type HeaderProps = {
//   toggleMenu: () => void;
//   menuOpen: boolean;
//   toggleCall: () => void;
//   isNavOpen: boolean;
// };

// const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
//   return (
//     <header className={styles.fixedHeader}>
//       <div
//         className={cx(
//           styles.flex,
//           styles['gap-5'],
//           styles['justify-between'],
//           styles['items-center'],
//           styles['w-full'],
//           styles['text-4xl'],
//           styles['font-bold'],
//           styles['tracking-tighter'],
//           styles['text-center'],
//           styles['text-white'],
//           styles['whitespace-nowrap'],
//           styles['max-md:flex-wrap'],
//           styles['max-md:max-w-full']
//         )}
//       >
//         <div className={cx(styles.flex, styles['gap-5'])}>
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
//             className={cx(styles['shrink-0'], styles['aspect-0-99'], styles['w-69px'])}
//             style={{
//               objectFit: 'contain',
//             }}
//             alt="NeutraFog logo"
//           />
//           <div className={cx(styles['flex-auto'], styles['my-auto'], styles.basic)}>
//             NeutraFog
//           </div>
//         </div>

//         <div className={cx(styles.flex, styles['items-center'], styles['gap-6'])}>
//           <div className={styles.menuToggle}>
//             {menuOpen ? (
//               <img
//                 loading="lazy"
//                 src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
//                 className={cx(styles['w-70px'], styles.pointer)}
//                 onClick={toggleMenu}
//                 alt="Close menu"
//               />
//             ) : (
//               <img
//                 loading="lazy"
//                 src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
//                 className={cx(styles['w-89px'], styles.pointer)}
//                 onClick={toggleMenu}
//                 alt="Open menu"
//               />
//             )}
//           </div>
//           <div className={styles.callButton}>
//             <CallButtonDesktop />
//           </div>
//         </div>
//       </div>
//     </header>
//   )
// }

// export default Header



//final good, no fixed

// import React from 'react'
// import styles from './nav.module.css'
// import CallButtonDesktop from './CallModal/CallButtonDesktop'
// import cx from 'classnames'

// type HeaderProps = {
//   toggleMenu: () => void;
//   menuOpen: boolean;
//   toggleCall: () => void;
//   isNavOpen: boolean;
// };

// const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
//   return (
//     <div
//       className={cx(
//         styles.flex,
//         styles['gap-5'],
//         styles['justify-between'],
//         styles['items-center'],
//         styles['w-full'],
//         styles['text-4xl'],
//         styles['font-bold'],
//         styles['tracking-tighter'],
//         styles['text-center'],
//         styles['text-white'],
//         styles['whitespace-nowrap'],
//         styles['max-md:flex-wrap'],
//         styles['max-md:max-w-full']
//       )}
//       style={{
//         width: '100%',
//         zIndex: isNavOpen ? 9999999999999999 : 'auto',
//       }}
//     >
//       <div className={cx(styles.flex, styles['gap-5'])}>
//         <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
//           className={cx(styles['shrink-0'], styles['aspect-0-99'], styles['w-69px'])}
//           style={{
//             objectFit: 'contain',
//           }}
//           alt="NeutraFog logo"
//         />
//         <div className={cx(styles['flex-auto'], styles['my-auto'], styles.basic)}>
//           NeutraFog
//         </div>
//       </div>

//       <div className={cx(styles.flex, styles['items-center'], styles['gap-6'])}>
//         <div className={styles.menuToggle}>
//           {menuOpen ? (
//             <img
//               loading="lazy"
//               src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
//               className={cx(styles['w-70px'], styles.pointer)}
//               onClick={toggleMenu}
//               alt="Close menu"
//             />
//           ) : (
//             <img
//               loading="lazy"
//               src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
//               className={cx(styles['w-89px'], styles.pointer)}
//               onClick={toggleMenu}
//               alt="Open menu"
//             />
//           )}
//         </div>
//         <div className={styles.callButton}>
//           <CallButtonDesktop />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Header



// import React from 'react'
// import styles from './nav.module.css'
// import CallButtonDesktop from './CallModal/CallButtonDesktop'
// import cx from 'classnames'

// type HeaderProps = {
//   toggleMenu: () => void;
//   menuOpen: boolean;
//   toggleCall: () => void;
//   isNavOpen: boolean;
// };

// const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
//   return (
//     <div
//       className={cx(
//         styles.flex,
//         styles['gap-5'],
//         styles['justify-between'],
//         styles['items-center'],
//         styles['w-full'],
//         styles['text-4xl'],
//         styles['font-bold'],
//         styles['tracking-tighter'],
//         styles['text-center'],
//         styles['text-white'],
//         styles['whitespace-nowrap'],
//         styles['max-md:flex-wrap'],
//         styles['max-md:max-w-full']
//       )}
//       style={{
//         width: '100%',
//         zIndex: isNavOpen ? 9999999999999999 : 'auto',
//       }}
//     >
//       <div className={cx(styles.flex, styles['gap-5'])}>
//         <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
//           className={cx(styles['shrink-0'], styles['aspect-0-99'], styles['w-69px'])}
//           style={{
//             objectFit: 'contain',
//           }}
//           alt="NeutraFog logo"
//         />
//         <div className={cx(styles['flex-auto'], styles['my-auto'], styles.basic)}>
//           NeutraFog
//         </div>
//       </div>

//       <div className={cx(styles.flex, styles['items-center'], styles['gap-4'])}>
//         <div className={styles.menuToggle}>
//           {menuOpen ? (
//             <img
//               loading="lazy"
//               src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
//               className={cx(styles['w-70px'], styles.pointer)}
//               onClick={toggleMenu}
//               alt="Close menu"
//             />
//           ) : (
//             <img
//               loading="lazy"
//               src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
//               className={cx(styles['w-89px'], styles.pointer)}
//               onClick={toggleMenu}
//               alt="Open menu"
//             />
//           )}
//         </div>
//         <div className={styles.callButton}>
//           <CallButtonDesktop />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Header

// prev to mon sep 9

// import React from 'react'
// import styles from './nav.module.css'
// import CallButtonDesktop from './CallModal/CallButtonDesktop'
// import cx from 'classnames'

// type HeaderProps = {
//   toggleMenu: () => void;
//   menuOpen: boolean;
//   toggleCall: () => void;
//   isNavOpen: boolean;
// };

// const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
//   return (
//     <div
//       className={cx(
//         styles.flex,
//         styles['gap-5'],
//         styles['justify-between'],
//         styles['items-center'],
//         styles['w-full'],
//         styles['text-4xl'],
//         styles['font-bold'],
//         styles['tracking-tighter'],
//         styles['text-center'],
//         styles['text-white'],
//         styles['whitespace-nowrap'],
//         styles['max-md:flex-wrap'],
//         styles['max-md:max-w-full']
//       )}
//       style={{
//         width: '100%',
//         zIndex: isNavOpen ? 9999999999999999 : 'auto',
//       }}
//     >
//       <div className={cx(styles.flex, styles['gap-5'])}>
//         <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
//           className={cx(styles['shrink-0'], styles['aspect-0-99'], styles['w-69px'])}
//           style={{
//             objectFit: 'contain',
//           }}
//           alt="NeutraFog logo"
//         />
//         <div className={cx(styles['flex-auto'], styles['my-auto'], styles.basic)}>
//           NeutraFog
//         </div>
//       </div>

//       <div className={cx(styles.flex, styles['items-center'])}>
//         <div className={styles.menuToggle}>
//           {menuOpen ? (
//             <img
//               loading="lazy"
//               src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
//               className={cx(styles['w-70px'], styles.pointer)}
//               onClick={toggleMenu}
//               alt="Close menu"
//             />
//           ) : (
//             <img
//               loading="lazy"
//               src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
//               className={cx(styles['w-89px'], styles.pointer)}
//               onClick={toggleMenu}
//               alt="Open menu"
//             />
//           )}
//         </div>
//         <div className={styles.callButton}>
//           <CallButtonDesktop />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Header

// import React from 'react'
// import styles from './nav.module.css'
// import CallButtonDesktop from './CallModal/CallButtonDesktop'

// import cx from 'classnames'

// type HeaderProps = {
//   toggleMenu: () => void;
//   menuOpen: boolean;
//   toggleCall: () => void;
//   isNavOpen: boolean;

// };

// const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
//   return (
//     <div
//       className={`${styles.flex} ${styles['gap-5']} ${styles['justify-between']} ${styles['items-center']} ${styles['w-full']} ${styles['text-4xl']} ${styles['font-bold']} ${styles['tracking-tighter']} ${styles['text-center']} ${styles['text-white']} ${styles['whitespace-nowrap']} ${styles['max-md:flex-wrap']} ${styles['max-md:max-w-full']}`}
//       style={{
//         width: '100%',
//         zIndex: isNavOpen ? 9999999999999999 : 'auto',

//       }}
//     >
//       <div className={`${styles.flex} ${styles['gap-5']}`}>
//         <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
//           className={`${styles['shrink-0']} ${styles['aspect-0-99']} ${styles['w-69px']}`}
//           style={{
//             objectFit: 'contain',
//           }}
//           alt=""
//         />
//         <div className={`${styles['flex-auto']} ${styles['my-auto']} ${styles['basic']}` }>
//           NeutraFog
//         </div>
//       </div>

//       <div className={cx(styles.flex)}>
//         {menuOpen ? (
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
//             className={`${styles['w-70px']} ${styles.pointer} ${styles.position}`}
//             onClick={toggleMenu}
//             alt=""
//           />
//         ) : (
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
//             className={`${styles['w-89px']} ${styles.pointer} ${styles.position}`}
//             onClick={toggleMenu}
//             alt=""
//           />
//         )}

// <CallButtonDesktop/>

//         {/* <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F415cd4ab860f4cf9acab124056bf2da5"
//           className={cx(styles['w-89px'], styles.pointer)}
//           onClick={toggleCall}
//           alt=""
//         /> */}
//       </div>
//     </div>
//   )
// }

// export default Header


// import React, { useEffect, useState } from 'react'
// import styles from './nav.module.css'
// import CallButtonDesktop from './CallModal/CallButtonDesktop'
// import cx from 'classnames';


// type HeaderProps = {
//   toggleMenu: () => void;
//   menuOpen: boolean;
//   toggleCall: () => void;
//   isNavOpen: boolean;

// };

// const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen, toggleCall, isNavOpen }) => {
//   return (
//     <div
//       className={`${styles.flex} ${styles['gap-5']} ${styles['justify-between']} ${styles['items-center']} ${styles['w-full']} ${styles['text-4xl']} ${styles['font-bold']} ${styles['tracking-tighter']} ${styles['text-center']} ${styles['text-white']} ${styles['whitespace-nowrap']} ${styles['max-md:flex-wrap']} ${styles['max-md:max-w-full']}`}
//       style={{
//         width: '100%',
//         zIndex: isNavOpen ? 9999999999999999 : 'auto',

//       }}
//     >
//       <div className={`${styles.flex} ${styles['gap-5']}`}>
//         <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&"
//           className={`${styles['shrink-0']} ${styles['aspect-0-99']} ${styles['w-69px']}`}
//           style={{
//             objectFit: 'contain',
//           }}
//           alt=""
//         />
//         <div className={`${styles['flex-auto']} ${styles['my-auto']} ${styles['basic']}` }>
//           NeutraFog
//         </div>
//       </div>

//       <div className={cx(styles.flex)}>
//         {menuOpen ? (
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
//             className={`${styles['w-70px']} ${styles.pointer} ${styles.position}`}
//             onClick={toggleMenu}
//             alt=""
//           />
//         ) : (
//           <img
//             loading="lazy"
//             src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
//             className={`${styles['w-89px']} ${styles.pointer} ${styles.position}`}
//             onClick={toggleMenu}
//             alt=""
//           />
//         )}

// <CallButtonDesktop/>

//         {/* <img
//           loading="lazy"
//           src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F415cd4ab860f4cf9acab124056bf2da5"
//           className={cx(styles['w-89px'], styles.pointer)}
//           onClick={toggleCall}
//           alt=""
//         /> */}
//       </div>
//     </div>
//   )
// }

// export default Header

import React from 'react';
import styles from './styles.module.css';
import cx from 'classnames';

interface DropdownProps {
  label: string;
  name?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ label, name, onChange, value }) => {
  const id = label.replace(/\s+/g, '-').toLowerCase().replace(':', '');

  return (
    <div className={styles.dropdownContainer}>
      <select id={id} className={cx(styles.customSelect)} name={name} onChange={onChange} value={value} >
        <option> {label} </option>
        <option value="na1">N/A</option>
        <option value="na2">N/A</option>
        <option value="na3">N/A</option>
      </select>
    </div>
  );
};

export default Dropdown;

'use client'

import { useState, useEffect } from 'react'
import { X } from 'lucide-react'
// import { Button } from "@/components/ui/button"
import { Button } from "@trycreo/ui/button";

import styles from './CallButton.module.css'
import Image from 'next/image'

export default function CallButton() {
  const [isOpen, setIsOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [activeTab, setActiveTab] = useState('Sales')

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    formData.append('department', activeTab)
    
    // Here you can choose where to send the submission based on the activeTab
    let submitUrl = '/api/submit'
    switch(activeTab) {
      case 'Sales':
        submitUrl = '/api/submit-sales'
        break
      case 'Support':
        submitUrl = '/api/submit-support'
        break
      case 'IPM':
        submitUrl = '/api/submit-ipm'
        break
    }

    // Send the form data to the appropriate endpoint
    fetch(submitUrl, {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error))
  }

  if (!isMobile) return null

  return (
    <>
      <div className={styles.callButtonContainer}>
        <Button
          onClick={() => setIsOpen(true)}
          className={styles.callButton}
          aria-label="Call us"
          variant="ghost"
        >
          <Image 
            src="/call.png" 
            alt="Call Icon" 
            width={65} 
            height={65}
            className={styles.pngIcon}
          />
        </Button>
      </div>

      {isOpen && (
        <div className={styles.modal}>
          <h2 className={styles.modalTitle}>Get In Touch</h2>
          <div className={styles.tabContainer}>
            {['Sales', 'Support', 'IPM'].map((tab) => (
              <button
                key={tab}
                className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          {/* <div className={styles.phoneContainer}>
            <p className={styles.phoneNumber}>Phone: 222-334-4556</p>
          </div> */}
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <input id="firstName" name="firstName" className={styles.input} placeholder="First Name" />
            </div>
            <div className={styles.formGroup}>
              <input id="lastName" name="lastName" className={styles.input} placeholder="Last Name" />
            </div>
            <div className={styles.formGroup}>
              <input id="email" name="email" type="email" className={styles.input} placeholder="Email Address" />
            </div>
            <div className={styles.formGroup}>
              <input id="phone" name="phone" type="tel" className={styles.input} placeholder="Phone Number" />
            </div>
            <div className={styles.formGroup}>
              <select id="location" name="location" className={styles.input}>
                <option value="">Location/State</option>
                {/* Add state options here */}
              </select>
            </div>
            <div className={styles.formGroup}>
              <textarea id="message" name="message" className={styles.input} rows={3} placeholder="Message:" />
            </div>
            <button type="submit" className={styles.submitButton}>
              SUBMIT
              <Image 
                src="/icon_dark.svg" 
                alt="Submit" 
                width={75} 
                height={75}
                className={styles.submitIcon}
              />
            </button>
          </form>
          <Button 
            onClick={() => setIsOpen(false)} 
            className={styles.closeButton}
            variant="ghost"
          >
            <X className={styles.closeIcon} />
          </Button>
        </div>
      )}
    </>
  )
}
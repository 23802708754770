'use client'

import React, { useState } from 'react'
import styles from './ProfileCardsC.module.css'

interface Profile {
  name: string;
  title: string;
  bio: string;
  image: string; // Add this line

}

interface ProfileCardsCProps {
  profiles: Profile[];
}

const profiles = [


  {
    name: 'Mike D.',
    title: 'Chief Executive Officer',
    bio:
      'Previously CEO of Gamma Holdings Manufacturing. Robust background in manufacturing. EstablishedNeutraTech in 2020',
      // Robust background in manufacturing & electronics, established NeutraTech in 2020
    
    image:
      '/img/ourTeam/avery_our_team.png',
  },
  {
    name: 'Adrian D.',
    title: 'Chief Operating Officer',
    bio:
      'Sales executive with a track record of exceeding targets and building strong client relationships. Background in sales.',
    image:
      '/img/ourTeam/adrian_our_team.jpg',
  },
  {
    name: 'Chris M.',
    position: 'Chief Technology Officer',
    description:
      'Chris, a 3x founder and CTO, has led the development and scaling of multiple ventures. With deep expertise in AI, machine learning, and growth hacking, Chris excels at aligning technology strategy with business objectives to drive innovation and scalable growth. He has worked with notable brands including the NBA, NFL, MLB, and Maybach',
    imageSrc:
        '/img/ourTeam/sergio_our_team.png',
  },
  {
    name: 'Sergio C.',
    title: 'Warehouse Clerk',
    bio:
      '4+ years in the shipping and logistic space (Formely @Amazon)',
    image:
      // '/img/ourTeam/michelle_our_team.png',
     
      '/img/ourTeam/sergio_our_team.png',
  },
  {
    name: 'Michelle U.',
    title: 'Cultivator & Influencer',
    bio:
      '(aka. Miss Rad Reefer) professional cannabis cultivator and influencer',
    image:
      '/img/ourTeam/michelle_our_team.png',
    },
  {
    name: 'James A.',
    title: 'Sales Rep',
    bio:
      'Extensive experience in BD and sales for tech companies expert communicator',
    image:
      '/img/ourTeam/russel_our_team.png',
  },
  {
    name: 'Sam A.',
    title: 'Technical Support',
    bio:
      '14+ years of technical expertise across software and hardware solutions',
    image:
      '/img/ourTeam/sam_our_team.png',
  },
  {
    name: 'Emily A.',
    title: 'Service Support',
    bio:
      '',
    image:
      '/img/ourTeam/michelle_our_team.png',
    },
  {
    name: 'Adam S.',
    title: 'IPM Advisor',
    bio:
      'Extensive regulatory & ops knowledge from 5+ years in commercial cultivation',
    image:
      '/img/ourTeam/avery_our_team.png',
  },

  // {
  //   name: 'Mike D.',
  //   title: 'CHIEF EXECUTIVE OFFICER',
  //   bio: 'With an uncanny ability to navigate through complex situations, Mike ensures our projects stay on track even in the haziest conditions.',
  //   image: 'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F47c263f42aeb46598b5e7f1ed20d24f5?format=webp&width=1200'

  // },
  // {
  //   name: 'Adrian D.',
  //   title: 'CHIEF OPERATING OFFICER',
  //   bio: 'Adrian brings over 15 years of operational excellence to our team. His strategic vision drives our companys growth and efficiency.',
  //   image: '/adrian.png'

  // },

  // {
  //   name: 'Sarah',
  //   title: 'HEAD OF MARKETING',
  //   bio: 'Sarahinnovative marketing strategies have put our brand on the map. ',
  //   image: 'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F47c263f42aeb46598b5e7f1ed20d24f5?format=webp&width=1200'

  // },
  // {
  //   name: 'John',
  //   title: 'LEAD DEVELOPER',
  //   bio: 'Sarahinnovative marketing strategies have put our brand on the map. ',
  //   image: 'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F47c263f42aeb46598b5e7f1ed20d24f5?format=webp&width=1200'

  // },
  // {
  //   name: 'Emily',
  //   title: 'CUSTOMER SUCCESS MANAGER',
  //   bio: 'Sarahinnovative marketing strategies have put our brand on the map. ',
  //   image: 'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F47c263f42aeb46598b5e7f1ed20d24f5?format=webp&width=1200'

  // },
  // {
  //   name: 'David',
  //   title: 'FINANCIAL ANALYST',
  //   bio: 'Sarahinnovative marketing strategies have put our brand on the map. ',
  //       image: 'https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F47c263f42aeb46598b5e7f1ed20d24f5?format=webp&width=1200'

  // },
]

export default function ProfileCardsC({ profiles }: ProfileCardsCProps) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredIndex(null)
  }

  const handleClick = (index: number) => {
    setHoveredIndex(hoveredIndex === index ? null : index)
  }

  return (
    <div className={styles.container}>
      {profiles.map((profile, index) => (
        <div
          key={index}
          className={`${styles.card} ${hoveredIndex === index ? styles.hovered : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(index)}
        >
          <div className={styles.imageWrapper}>
            <img
              src={profile.image || `/placeholder.svg?height=400&width=300&text=${profile.name}`}

              // src={`/placeholder.svg?height=400&width=300&text=${profile.name}`}
              alt={`Profile of ${profile.name}`}
              className={styles.image}
            />
          </div>
          <div className={styles.info}>
            <h2 className={styles.name}>{profile.name}</h2>
            <p className={styles.title}>{profile.title}</p>
            <p className={styles.bio}>{profile.bio}</p>
          </div>
          <div className={styles.arrow}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 17L17 7M17 7H7M17 7V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
      ))}
    </div>
  )
}
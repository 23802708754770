'use client'

import React, { useState } from 'react'
import styles from './CustFaq.module.css'
import cx from 'classnames'
import FAQAccordion from './FAQAccordion'

interface FAQItem {
  question: string
  answer: string
}

interface FAQProps {
  title?: string
  description?: string
  faqItems?: FAQItem[]
  backgroundColor?: string
  textColor?: string
}

const Faqq: React.FC<FAQProps> = ({
  title = 'Frequently Asked Questions',
  description = '',
  faqItems = [],
  backgroundColor = '#ffffff',
  textColor = '#000000'
}) => {
  const [isDarkMode, setIsDarkMode] = useState(false)

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode)
  }

  const themeStyles = {
    backgroundColor: isDarkMode ? '#000000' : backgroundColor,
    color: isDarkMode ? '#ffffff' : textColor
  }

  return (
    <div className={cx(styles.instRoot)} style={themeStyles}>
      <div className={styles.container}>
        <h1 className={cx(styles.resourceTitle)}>{title}</h1>
        {description && <p className={styles.resourceDescription}>{description}</p>}
        {/* <button onClick={toggleTheme} className={styles.themeToggle}>
          {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
        </button> */}
        <div className={styles.faqDivider}>
          <svg width="100%" height="1" viewBox="0 0 100 1" preserveAspectRatio="none">
            <line x1="0" y1="0" x2="100" y2="0" stroke={isDarkMode ? '#ffffff' : '#000000'} strokeWidth="0.5" />
          </svg>
        </div>
        <FAQAccordion items={faqItems} isDarkMode={isDarkMode} />
      </div>
    </div>
  )
}

export default Faqq
"use client";
import React from 'react';
import { Input } from "@trycreo/ui/input";
import style from './sop.module.css'

interface SearchInputProps {
  onSearch: (query: string) => void;
}

export function SearchInput({ onSearch }: SearchInputProps) {
  return (
    <Input
      type="text"
      placeholder="Search"
      onChange={(e) => onSearch(e.target.value)}
      className={style.shadInput}
    />
  );
}

import React from 'react'
import styles from './nav.module.css'
import Link from 'next/link';

type HeaderProps = {
  toggleMenu: () => void;
  menuOpen: boolean;
  toggleCall: () => void;
};

const Header: React.FC<HeaderProps> = ({ toggleMenu, menuOpen }) => {
  return (
    <header className={styles.container}>
      <div className={styles.logoWrapper}>
        <Link href="/home" passHref>
        <img
          loading="lazy"
          src={
            'https://cdn.builder.io/api/v1/image/assets/TEMP/03b529fd8f1563ec8c5630d4ee62b758b4f401a3b4ef42fa7d4e3500fcf4fab6?apiKey=7d740121674b4225a077dc91176b5085&'
          }
          alt="NeutraFog Logo"
          className={styles.logoIcon}
        />
        </Link>
        <Link href="/home" passHref>
        <h1 className={styles.brandName}>NeutraFog</h1>
        </Link>
      </div>
      {menuOpen ? (
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/70bd71e9838c9472fbec170f2fac80a2a9e15bbe1e09d39ac447d7056a1765ae?apiKey=7d740121674b4225a077dc91176b5085&"
          onClick={toggleMenu}
          className={styles.leftIcon}
          alt=""
        />
      ) : (
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F1b5896a8db7d469fa5eda9cb86f9c6e7"
          className={styles.leftIcon}
          onClick={toggleMenu}
          alt=""
        />
      )}
    </header>
  )
}

export default Header

"use client";
import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@trycreo/ui/select";
import style from './sop.module.css'


interface SelectDropdownProps {
  options: { value: string; label: string }[];
  onSelect: (value: string) => void;
  selectedOption: string | null;
}

export function SelectDropdown({ options, onSelect, selectedOption }: SelectDropdownProps) {
  return (
    <Select onValueChange={onSelect} value={selectedOption || undefined}>
      <SelectTrigger  className={style.shadInput} >
        <SelectValue placeholder="Select System" />
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

import React, { useState } from 'react'
import styles from './FAQAccordion.module.css'

interface FAQItem {
  question: string
  answer: string
}

interface FAQAccordionProps {
  items: FAQItem[]
  isLight: boolean
}

export default function FAQAccordion({ items, isLight }: FAQAccordionProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  return (
    <div className={styles.faqAccordion}>
      {items.map((item, index) => (
        <div key={index}>
          <div
            className={`${styles.faqTitle} ${activeIndex === index ? styles.active : ''}`}
            onClick={() => toggleAccordion(index)}
          >
            <img
              className={`${styles.icon} ${activeIndex === index ? styles.rotated : ''}`}
              alt="Toggle accordion"
              src="/img/material-symbols-add-11-light.svg"
            />
            {item.question}
          </div>
          <div className={`${styles.faqContent} ${activeIndex === index ? styles.active : ''}`}>
            <p>{item.answer}</p>
          </div>
          <div className={styles.faqDivider} />
        </div>
      ))}
    </div>
  )
}

// import React, { useState } from 'react'
// import styles from './FAQAccordion.module.css'

// interface FAQItem {
//   question: string
//   answer: string
// }

// interface FAQAccordionProps {
//   items: FAQItem[]
//   isLight: boolean
// }

// const FAQAccordion: React.FC<FAQAccordionProps> = ({ items, isLight }) => {
//   const [activeIndex, setActiveIndex] = useState<number | null>(null)

//   const toggleAccordion = (index: number) => {
//     setActiveIndex(activeIndex === index ? null : index)
//   }

//   return (
//     <div className={styles.faqAccordion}>
//       {items.map((item, index) => (
//         <div key={index}>
//           <div
//             className={styles.faqTitle}
//             onClick={() => toggleAccordion(index)}
//           >
//             <img
//               className="material-symbols-add"
//               alt="Material symbols add"
//               src="/img/material-symbols-add-11.svg"
//             />
//             {item.question}
//           </div>
//           <div className={`${styles.faqContent} ${activeIndex === index ? styles.active : ''}`}>
//             <p>{item.answer}</p>
//           </div>
//         </div>
//       ))}
//     </div>
//   )
// }

// export default FAQAccordion

// // import React, { useState } from "react";
// // import styles from "./FAQAccordion.module.css";
// // import cx from "classnames";

// // interface FAQItem {
// //   question: string
// //   answer: string
// // }

// // interface FAQAccordionProps {
// //   items: string[];
// //   isLight?: boolean;
// //   backgroundColor?: string;
// //   textColor?: string;
// // }

// // const FAQAccordion: React.FC<FAQAccordionProps> = ({
// //   items,
// //   isLight,
// // }) => {
// //   const [activeIndex, setActiveIndex] = useState<number[] | null>(null);

// //   const backgroundColor = isLight ? "#ffffff" : "#121212";
// //   const textColor = isLight ? "#1e1e1e" : "#ffffff";

// //   const toggleAccordion = (index: number) => {
// //     if(activeIndex?.includes(index)) {
// //       setActiveIndex(activeIndex.filter((i) => i !== index));
// //     } else {
// //       setActiveIndex(activeIndex ? [...activeIndex, index] : [index]);
// //     }
// //   };

// //   return (
// //     <div className={styles.faqAccordion} style={{ backgroundColor }}>
// //       {items?.map((item, index) => (
// //         <div key={index}>
// //           <div
// //             className={styles.faqTitle}
// //             style={{ color: textColor }}
// //             onClick={() => toggleAccordion(index)}
// //           >
// //             <img
// //               className={styles.materialSymbolsAdd}
// //               alt="Material symbols add"
// //               src={ isLight ? "/img/material-symbols-add-11.svg" : "/img/material-symbols-add-11-light.svg"}
// //             />
// //             {item}
// //           </div>
// //           <div className={ cx(styles.faqContent, activeIndex?.includes(index) && styles.active)}>
// //             <p style={{ color: textColor }}>
// //             {item.answer}
// //             </p>
// //           </div>
// //           {index < items.length - 1 && (
// //             <div className="faq-divider">
// //               <svg width="100%" height="1" viewBox="0 0 100 1" preserveAspectRatio="none">
// //                 <line x1="0" y1="0" x2="100" y2="0" style={{ stroke: textColor, strokeWidth: 0.5 }} />
// //               </svg>
// //             </div>
// //           )}
// //         </div>
// //       ))}
// //     </div>
// //   );
// // };

// // export default FAQAccordion;



// // import React, { useState } from "react";
// // import "./FAQAccordian.css";

// // interface FAQAccordionProps {
// //   items: string[];
// //   backgroundColor?: string;
// //   textColor?: string;
// // }

// // const FAQAccordion: React.FC<FAQAccordionProps> = ({
// //   items,
// //   backgroundColor = "#ffffff",
// //   textColor = "#1e1e1e"
// // }) => {
// //   const [activeIndex, setActiveIndex] = useState<number | null>(null);

// //   const toggleAccordion = (index: number) => {
// //     setActiveIndex(activeIndex === index ? null : index);
// //   };

// //   return (
// //     <div className="faq-accordion" style={{ backgroundColor }}>
// //       {items.map((item, index) => (
// //         <div key={index}>
// //           <div
// //             className="faq-title"
// //             style={{ color: textColor }}
// //             onClick={() => toggleAccordion(index)}
// //           >
// //             <img
// //               className="material-symbols-add"
// //               alt="Material symbols add"
// //               src="/img/material-symbols-add-11.svg"
// //             />
// //             {item}
// //           </div>
// //           <div className={`faq-content ${activeIndex === index ? "active" : ""}`}>
// //             <p style={{ color: textColor }}>
// //               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.
// //               Aliquam in hendrerit urna.
// //             </p>
// //           </div>
// //         </div>
// //       ))}
// //     </div>
// //   );
// // };

// // export default FAQAccordion;

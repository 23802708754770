/**
 * This code was generated by Builder.io.
 */
import React from 'react'
import styles from './ComparisonTable.module.css'
import cx from 'classnames'

interface ComparisonItem {
  neutraFoggers: string
  traditional: string
}

interface ComparisonTableProps {
  items: ComparisonItem[]
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({ items }) => {
  return (
    <section id="proscons" className={styles.container}>
      <div className={styles.brandSection}>
        <h1 className={styles.brand}>NeutraFog Systems</h1>
        <h2 className={styles.title}>NeutraFog vs traditional method</h2>
        <p className={styles.description}>
        Elevate your pest control approach with the power of fogging technology. Unlike traditional methods, fogging ensures even coverage, reduces chemical usage, and enhances the overall health of your crops. Experience a smarter, more sustainable way to protect your greenhouse with NeutraFog.
        </p>
      </div>
      <div className={styles.comparisonTable}>
        <header className={styles.headerRow}>
          <div className={styles.comparisonRow}>
            <div className={styles.comparisonCellHeader}>
              <div className={styles.headerText}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/48cd2b0bfde64813ca35aab6d2098f5ac367cb30d75bc19a5ea1793eaaa9f7cd?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
                  alt=""
                  className={styles.headerIcon}
                />
                <h3 className={styles.headerLabel}>Neutrafog</h3>
              </div>
            </div>
            <div className={styles.comparisonCellGrayHeader}>
              <div className={styles.headerText}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1d20dd8d1204b234dcf93e63ec8ad3e6e832a17a0be7961b056df4de34df8f32?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085"
                  alt=""
                  className={styles.headerIcon}
                />
                <h3 className={styles.headerLabel}>Traditional</h3>
              </div>
            </div>
          </div>
        </header>
        {items.map((item, index) => (
          <div key={index} className={styles.comparisonRow}>
            <div
              className={cx(
                styles.comparisonCellGreen,
                index % 2 === 0 ? styles.bgLightBlack : styles.bgLightGreen
              )}
            >
              {item.neutraFoggers}
            </div>
            <div
              className={cx(
                styles.comparisonCellGray,
                index % 2 === 0 ? styles.bgLightBlack : styles.bgLightGrey,
              )}
            >
              {item.traditional}
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default ComparisonTable

import React from 'react'
import styles from './nav.module.css'
import cx from 'classnames'

const navigationItems = [
  { name: 'about us', url: '/about-us' },
  { name: 'our systems', url: '/our-systems' },
  { name: 'IPM Products', url: '/ipm-products' },
  { name: 'subscriptions', url: '/subscriptions' },
  { name: 'resources', url: '/resources' },
]

const Navigation: React.FC = () => {
  return (
    <nav
      className={`${styles.flex} ${styles['flex-col']} ${styles['self-start']} ${styles['text-9xl']} ${styles['tracking-tighter']} ${styles.capitalize} ${styles['leading-117']} ${styles['text-white']} ${styles['text-opacity-50']} ${styles['max-md:max-w-full']} ${styles['max-md:text-4xl']}`}
    >
      {navigationItems.map((item, index) => (
        <a
          href={item.url}
          className={cx(
            styles['text-white'],
            styles['text-decoration-none'],
            styles['link-hover']
          )}
        >
          <div key={index}>{item.name}</div>
        </a>
      ))}
    </nav>
  )
}

export default Navigation

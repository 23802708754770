import React, { useEffect, useState } from 'react'
import Header from './Header'
import Navigation from './Navigation'
import SocialLinks from './SocialLinks'
import styles from './nav.module.css'
import navStyles from './Navigation.module.css'
import cx from 'classnames'
import CallForm from './CallModal'

const menuItems = [
  { name: 'about us', url: '/about' },
  { name: 'our systems', url: '/our-systems' },
  { name: 'IPM Products', url: '/ipm-solutions' },
  { name: 'subscriptions', url: '/subscriptions' },
  { name: 'resources', url: '/resources' },
]

const subMenuItems = [
  { name: 'Careers', url: '/careers/' },
  { name: 'SOP', url: '/sop' },
  { name: 'Contact Us', url: '/contact' },
]

const socialLinks = [
  {
    url: 'https://www.facebook.com/neutrafg/',
    imgUrl:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/20084bf84dadbe51ba1e6f3ee497207e5b9a890fec8e5f08e0f7f8dd3020f3cc?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085',
  },
  {
    url: 'https://www.instagram.com/neutrafg/',
    imgUrl:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/f0e8917b9b40962629248add1322882812c1fdb4b7bfe85dbd8d450958401bc1?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085',
  },
  {
    url: 'https://twitter.com/neutrafg',
    imgUrl:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/f973ceb4f7aaf3bb891ed46b3b3a646e1388df6e70c4cf1132ab244d2f74fffc?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085',
  },
  {
    url: 'https://www.youtube.com/channel/UCe3lWb_H0aKJZKXxhV2n7TA',
    imgUrl:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/17a9f19210c0effb89f44edac3636cab7921a0186ce186b0f579fe0401aec7d1?apiKey=7d740121674b4225a077dc91176b5085&&apiKey=7d740121674b4225a077dc91176b5085',
  },
]

export const NavMobile = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [callOpen, setCallOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const toggleCall = () => {
    setCallOpen(!callOpen)
  }

  useEffect(() => {
    const triggerElement = document.querySelector('.nav-trigger')
    if (triggerElement) {
      triggerElement.addEventListener('click', toggleMenu)
    }
    return () => {
      if (triggerElement) {
        triggerElement.removeEventListener('click', toggleMenu)
      }
    }
  }, [])

  return (
    <div className={cx(styles.root, menuOpen && styles.menuOpenClass)}>
      <Header
        toggleMenu={toggleMenu}
        menuOpen={menuOpen}
        toggleCall={toggleCall}
      />
      {menuOpen && (
        <div>
          {' '}
          <div className={navStyles.menuContainer}>
            {menuItems.map((item, index) => (
              <a href={item.url} key={index} className={navStyles.menuItem}>
                {item.name}
              </a>
            ))}
            <div className={navStyles.subMenu}>
              {subMenuItems.map((item, index) => (
                <a href={item.url} key={index}>
                  {item.name}
                </a>
              ))}
            </div>
            <div className={navStyles.divider} />
            <div className={navStyles.socialIcons}>
              {socialLinks.map((item, index) => (
                <a href={item.url} key={index}>
                  <img
                    loading="lazy"
                    src={item.imgUrl}
                    className={styles.socialIcon}
                    alt="Social media icon"
                  />
                </a>
              ))}
            </div>
          </div>{' '}
        </div>
      )}
      {/* <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets%2F7d740121674b4225a077dc91176b5085%2F415cd4ab860f4cf9acab124056bf2da5"
        className={cx(styles.callIconMobile)}
        onClick={toggleCall}
        alt=""
      /> */}
      {/* <CallForm callOpen={callOpen} toggleCall={toggleCall} /> */}
    </div>
  )
}

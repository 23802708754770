const dummyData = {
  tableData: [
      { id: 1, name: "Azagaurd (every 7 days until pressure subsides or stop by day 18 flower)" },
      { id: 2, name: "Azagaurd (every 5 days until pressure subsides or stop by day 18 flower)" },
      { id: 3, name: "Azamax (every 7 days until pressure subsides or stop by day 18)" },
      { id: 4, name: "Azamax (every 5 days until pressure subsides or stop by day 18)" },
      { id: 5, name: "Azatin (every 7 days until pressure subsides or stop by day 18)" },
      { id: 6, name: "Azatin (every 5 days until pressure subsides or stop by day 18)" },
      { id: 7, name: "Suffoil (every 5 days till pressure subsides or stop use by day 18 of flower) Pest dependent" },
      { id: 8, name: "Suffoil (every 3 days till pressure subsides or stop use by day 18 of flower) Pest dependent" },
      { id: 9, name: "Tritek (every 5 days till pressure subsides or stop use by day 18 of flower) Pest dependent" },
      { id: 10, name: "Tritek (every 3 days till pressure subsides or stop use by day 18 of flower) Pest dependent" },
      { id: 11, name: "Big Time Exterminator (every 5 days till pressure subsides)" },
      { id: 12, name: "Big Time Exterminator (every 3 days till pressure subsides)" },
      { id: 13, name: "Smite (every 7 days until pressure subsides, can be used in late flower)" },
      { id: 14, name: "Smite (every 5 days until pressure subsides, can be used in late flower)" },
      { id: 15, name: "SNS 203 (every 3 days, stop by day 18 of flower, no more than 5 applications per crop cycle)" },
      { id: 16, name: "SNS 203 (every 2 days, stop by day 18 of flower, no more than 5 applications per crop cycle)" },
      { id: 17, name: "Mammoth Biocontrol (every 5 days till pressure subsides, can be used in late flower)" },
      { id: 18, name: "Mammoth Biocontrol (every 3 days till pressure subsides, can be used in late flower)" },
      { id: 19, name: "Mammoth CannControl (every 5 days till pressure subsides, can be used in late flower)" },
      { id: 20, name: "Mammoth CannControl (every 3 days till pressure subsides, can be used in late flower)" },
      { id: 21, name: "Athena IPM (apply daily with a day break every 3 days) any soft body insects)" },
      { id: 22, name: "Athena IPM (apply daily with a day break every 5 days) any soft body insects)" },
      { id: 23, name: "Growsafe (every 5 days then stop by day 18 of flower)" },
      { id: 24, name: "Growsafe (every 3 days then stop by day 18 of flower)" },
      { id: 25, name: "Wizards Brew Concentrate (every 5 days then stop by day 18 of flower)" },
      { id: 26, name: "Wizards Brew Concentrate (every 3 days then stop by day 18 of flower)" },
      { id: 27, name: "TetraCurb (every 5 days and can be used in late flower)" },
      { id: 28, name: "TetraCurb (every 3 days and can be used in late flower)" },
      { id: 29, name: "Organishield (every 10 days no more than 6 applications per crop cycle)" },
      { id: 30, name: "Organishield (every 5 days no more than 6 applications per crop cycle)" },
      { id: 31, name: "Plant Therapy (every 5 days can be used in late flower, use as many times in a cycle until you see signs of damaged plants)" },
      { id: 32, name: "Plant Therapy (every 3 days can be used in late flower, use as many times in a cycle until you see signs of damaged plants)" },
      { id: 33, name: "Green Cleaner (every 5 days can be used in late flower, use as many times in a cycle until you see signs of damaged plants)" },
      { id: 34, name: "Green Cleaner (every 3 days can be used in late flower, use as many times in a cycle until you see signs of damaged plants)" },
      { id: 35, name: "Dr. Zymes (every 5 days can be used in late flower)" },
      { id: 36, name: "Dr. Zymes (every 3 days can be used in late flower)" },
      { id: 37, name: "Flying Skull Nuke Em (every 3 days can be used in late flower)" },
      { id: 38, name: "Flying Skull Nuke Em (every 2 days can be used in late flower)" },
      { id: 39, name: "Dr. Bronners Peppermint (every 5 days can be used in late flower)" },
      { id: 40, name: "Dr. Bronners Peppermint (every 3 days can be used in late flower)" },
      { id: 41, name: "Mpede (every 5 days can be used in late flower)" },
      { id: 42, name: "Mpede (every 3 days can be used in late flower)" },
      { id: 43, name: "Pure Crop1 (every 3 days can be used up to day of harvest)" },
    { id: 44, name: "Pure Crop1 (every 5 days can be used up to day of harvest)" },
    { id: 45, name: "Pyganic5.0" },
    { id: 46, name: "Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)" },
    { id: 47, name: "Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)" },
    { id: 48, name: "Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)" },
    { id: 49, name: "Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)" },
    { id: 50, name: "Circadian Sunrise (every 3 days can be used in late flower)" },
    { id: 51, name: "Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)" },
    { id: 52, name: "Circadian Sunrise + Allphase (every 3 days can be used in late flower)" },
    { id: 53, name: "Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)" },
    { id: 54, name: "Azagaurd Oxiphos Zerotol (every 7 days under pressure, stop by day 21 of flower)" },
    { id: 55, name: "Azagaurd Oxiphos Zerotol Pro (every 7 days under pressure, stop by day 21 of flower)" },
    { id: 56, name: "Netra Fog HOCL (can be used daily, stop using at first sign of damage to plants)" },
    { id: 57, name: "Regalia Maxx (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)" },
    { id: 58, name: "Regalia Maxx (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)" },
    { id: 59, name: "Procidic2 (every 5 days. can be used up to day of harvest)" },
    { id: 60, name: "Procidic2 (every 3 days. can be used up to day of harvest)" },
    { id: 61, name: "Zerotol 2.0 (every 3 days under pressure can be used up to the day of harvest)" },
    { id: 62, name: "Zerotol 2.0 (every 2 days under pressure can be used up to the day of harvest)" },
    { id: 63, name: "Oxiphos (every 7 days under pressure stop using by day 14 of flower)" },
    { id: 64, name: "Dr Zymes (every 3 days under pressure, can be used in late flower)" },
    { id: 65, name: "Dr Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later. after 3 days repeat pattern until pressure subsides, can be used in late flower)" },
    { id: 66, name: "Flying Skull Nuke em (every 3 days, can be used up to day of harvest)" },
    { id: 67, name: "Flying Skull Nuke em (every 2 days, can be used up to day of harvest)" },
    { id: 68, name: "Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)" },
    { id: 69, name: "Pure Crop1 (every 5 days can be used up to day of harvest)" },
    { id: 70, name: "Pure Crop1 (every 3 days can be used up to day of harvest)" },
    { id: 71, name: "Circadian Sunrise + Allphase (every 5 days can be used in late flower)" },
    { id: 72, name: "Circadian Sunrise + Allphase (every 3 days can be used in late flower)" },
    { id: 73, name: "Allphase (every 2 days or as needed can be used in late flower)" },
    { id: 74, name: "Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo" },
    { id: 75, name: "Grandevo WDG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo" },
    { id: 76, name: "Venerate CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo" },
    { id: 77, name: "Botanigaurd 22wp (every 7 days can be used in late flower) Aura to be used as wetting agent/carrier not Halo" },
    { id: 78, name: "Botanigaurd Maxx (should only be used in vegetative phase no more than 6 times per crop cycle with interval of 7 days) Aura to be used as wetting agent/carrier not Halo" },
    { id: 79, name: "Power si Original (1 to 2 times per week depending on the health of the plant)" },
    { id: 80, name: "Power si Bloom (1 to 2 times per week depending on the health of the plant)" },
    { id: 81, name: "Athena Stack (1 to 2 times per week depending on the health of the plant)" },
    { id: 82, name: "Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)" },
    { id: 83, name: "CES Foliar (1 to 2 times per week depending on the health of the plant)" },
    { id: 84, name: "B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)" },
    { id: 85, name: "Humboldt Humic (1 to 2 times per week depending on the health of the plant)" },
    { id: 86, name: "House n Garden Magic Green (1 to 2 times per week depending on the health of the plant)" },
    { id: 87, name: "Optic Foliar Transport + Rev + Watts (1 to 2 times per week depending on the health of the plant)" },
    { id: 88, name: "Neutra Fog HOCL 2 times per week" },
    { id: 89, name: "Sanidate5.0 2 times per week" },
    { id: 90, name: "Athena Cleanse 2 times per week" },
  ],
  dropdownOptions: [
    { value: "t250", label: "T-250" },
    { value: "t500", label: "T-500" },
    { value: "pro1500", label: "Pro-1500" },
    { value: "pro4000", label: "Pro-4000" },

  ],
};

interface ResultData {
  title: string;
  sizeArea: string[];
  mixture: { name: string; value: string }[];
  preventativeDays: number;
  reactiveDays: number;
}

const resultDataMap: Record<string, Record<string, Record<string, ResultData>>> = {
  "t250": {
    "1": {
      "25000": {
        title: "T-250, using Azagaurd (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1215ml" },
          { name: "Azagaurd", value: "15ml" },
          { name: "H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Azagaurd (minimum) (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azagaurd", value: "30ml" },
          { name: "H2O", value: "30ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "2": {
      "25000": {
        title: "T-250, using Azagaurd (maximum) (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1160ml" },
          { name: "Azagaurd", value: "36ml" },
          { name: "H2O", value: "54ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-250, using Azagaurd (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1110ml" },
          { name: "Azagaurd", value: "75ml" },
          { name: "H2O", value: "65ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "3": {
      "25000": {
        title: "T-250, using Azamax (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azamax", value: "45ml" },
          { name: "H2O", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      // not recommended
      "50000": {
        title: "T-250, using Azamax (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "4": {
      "25000": {
        title: "T-250, using Azamax (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1090ml" },
          { name: "Azamax", value: "120ml" },
          { name: "H2O", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "5": {
      "25000": {
        title: "T-250, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1215ml" },
          { name: "Azatin", value: "15ml" },
          { name: "H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1200ml" },
          { name: "Azatin", value: "30ml" },
          { name: "H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "6": {
      "25000": {
        title: "T-250, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azatin", value: "36ml" },
          { name: "H2O", value: "24ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-250, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Azatin", value: "75ml" },
          { name: "H2O", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },
    "7": {
      "25000": {
        title: "T-250, using Suffoil (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Suffoil-x", value: "75ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      // not recommended
      "50000": {
        title: "T-250, using Suffoil (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "8": {
      "25000": {
        title: "T-250, using Suffoil (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Suffoil-x", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      //not recommended
      "50000": {
        title: "T-250, using Suffoil (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Suffoil-x", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "9": {
      "25000": {
        title: "T-250, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tri-Tek", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tri-Tek", value: "50ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "10": {
      "25000": {
        title: "T-250, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tri-Tek", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tri-Tek", value: "300ml" },
          { name: "ISO", value: "175ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "11": {
      "25000": {
        title: "T-250, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Big Time", value: "90ml" },
          { name: "ISO", value: "10ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Big Time", value: "90ml" },
          { name: "ISO", value: "10ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "12": {
      "25000": {
        title: "T-250, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Big Time", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Big Time", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "13": {
      "25000": {
        title: "T-250, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Smite", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Smite", value: "60ml" },
          { name: "ISO", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "14": {
      "25000": {
        title: "T-250, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Smite", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-250, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Smite", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },

    "15": {
      "25000": {
        title: "T-250, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "SNS 203", value: "120ml" },
          { name: "ISO", value: "80ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
   
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },
    "16": {
      "25000": {
        title: "T-250, using SNS 203 (every 2 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "950ml" },
          { name: "SNS 203", value: "100ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "T-250, using SNS 203 (every 2 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [

        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
    },


    "17": {
      "25000": {
        title: "T-250, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "BioControl", value: "60ml" },
          { name: "R/O ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },

      "50000": {
        title: "T-250, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "BioControl", value: "60ml" },
          { name: "R/O ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
  
    },
    "18": {
      "25000": {
        title: "T-250, using Mammoth Biocontrol (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "BioControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Mammoth Biocontrol (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "BioControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },


    "19": {
      "25000": {
        title: "T-250, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "CannControl", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "CannControl", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    
    },
    "20": {
      "25000": {
        title: "T-250, using Mammoth CannControl (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "CannControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Mammoth CannControl (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "CannControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
// fix this 
    "21": {
      "25000": {
        title: "T-250, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Athena IPM", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 2,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 2,
        reactiveDays: 0,
      },
    },
    "22": {
      "25000": {
        title: "T-250, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Athena IPM", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
      "50000": {
        title: "T-250, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Athena IPM", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
    },
    "23": {
      "25000": {
        title: "T-250, using Growsafe (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Growsafe", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Growsafe (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "24": {
      "25000": {
        title: "T-250, using Growsafe (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Growsafe", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Growsafe (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "25": {
      "25000": {
        title: "T-250, using Wizards Brew Concentrate (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Wizard Brew", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Wizards Brew Concentrate (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "26": {
      "25000": {
        title: "T-250, using Wizards Brew Concentrate (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Wizard Brew", value: "300ml" },
          { name: "ISO", value: "50ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Wizards Brew Concentrate (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Wizard Brew", value: "950ml" },
          { name: "ISO", value: "100ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "27": {
      "25000": {
        title: "T-250, using TetraCurb (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tetra Curb", value: "50ml" },
          { name: "ISO", value: "25ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using TetraCurb (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "28": {
      "25000": {
        title: "T-250, using TetraCurb (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tetra Curb", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using TetraCurb (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tetra Curb", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "29": {
      "25000": {
        title: "T-250, using Organishield (every 10 days, no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Organishield", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Organishield (every 10 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
    },
    "30": {
      "25000": {
        title: "T-250, using Organishield (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Organishield", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-250, using Organishield (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Organishield", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },

    "31": {
      "25000": {
        title: "T-250, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Plant Therapy", value: "60ml" },
          { name: "H2O", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Plant Therapy (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "32": {
      "25000": {
        title: "T-250, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Plant Therapy", value: "300ml" },
          { name: "H2O", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Plant Therapy (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Plant Therapy", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "33": {
      "25000": {
        title: "T-250, using Green Cleaner (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Green Cleaner", value: "60ml" },
          { name: "H2O", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Green Cleaner (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "34": {
      "25000": {
        title: "T-250, using Green Cleaner (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Green Cleaner", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Green Cleaner (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Green Cleaner", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "35": {
      "25000": {
        title: "T-250, using Dr. Zymes (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Dr Zymes", value: "50ml" },
          { name: "H2O", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Dr. Zymes (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "36": {
      "25000": {
        title: "T-250, using Dr. Zymes (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Zymes", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Dr. Zymes (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Zymes", value: "300ml" },
          { name: "H2O", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "37": {
      "25000": {
        title: "T-250, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Nuke em", value: "120ml" },
          { name: "ISO", value: "80ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },
    "38": {
      "25000": {
        title: "T-250, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Nuke em", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Flying Skull Nuke Em (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Nuke em", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 2,
      },
    },
    "39": {
      "25000": {
        title: "T-250, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Dr Bronners", value: "75ml" },
          { name: "H2O", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Dr. Bronners Peppermint (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "40": {
      "25000": {
        title: "T-250, using Dr. Bronners Peppermint (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Bronners", value: "300ml" },
          { name: "H2O", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Dr. Bronners Peppermint (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Bronners", value: "300ml" },
          { name: "H2O", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "41": {
      "25000": {
        title: "T-250, using Mpede (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Mpede", value: "75ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Mpede (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "42": {
      "25000": {
        title: "T-250, using Mpede (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Mpede", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Mpede (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Mpede", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },


//new min starts here
    "46": {
      "25000": {
        title: "T-250, using Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Cpt. Jacks", value: "50ml" },
          { name: "H20", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using T-250, using Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "47": {
      "25000": {
        title: "T-250, using Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Cpt. Jacks", value: "300ml" },
          { name: "H20", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Cpt. Jacks", value: "300ml" },
          { name: "H20", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "48": {
      "25000": {
        title: "T-250, using Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Monterey", value: "50ml" },
          { name: "H20", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "49": {
      "25000": {
        title: "T-250, using Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Monterey", value: "300ml" },
          { name: "H20", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-250, using Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Monterey", value: "300ml" },
          { name: "H20", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "50": {
      "25000": {
        title: "T-250, using Circadian Sunrise (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1100ml" },
          { name: "Circ. Sun", value: "90ml" },
          { name: "ISO", value: "60ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Circadian Sunrise (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },

    "51": {
      "25000": {
        title: "T-250, using Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Circ. Sun", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
      "50000": {
        title: "T-250, using Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Circ. Sun", value: "300ml" },
          { name: "ISO", value: "75l" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
    },
    "52": {
      "25000": {
        title: "T-250, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Circ. Sun", value: "90ml" },
          { name: "Alphase", value: "7gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-250, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Circ. Sun", value: "90ml" },
          { name: "Alphase", value: "7gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },
    "53": {
      "25000": {
        title: "T-250, using Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "300ml" },
          { name: "Circ. Sun", value: "300ml" },
          { name: "Alphase", value: "30gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
      "50000": {
        title: "T-250, using Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "300ml" },
          { name: "Circ. Sun", value: "300ml" },
          { name: "Alphase", value: "30gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
    },

    //needs reviewing
    // min
    "54": {
    "25000": {
      title: "T-250, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "1015ml" },
        { name: "Azaguard", value: "36ml" },
        { name: "Zerotol", value: "50ml" },
        { name: "Oxiphos", value: "50ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 0,
    },
  },

  //input needed here for max
  "55": {
    "25000": {
      title: "T-250, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "1015ml" },
        { name: "Azaguard", value: "36ml" },
        { name: "Zerotol", value: "50ml" },
        { name: "Oxiphos", value: "50ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 7,
    },
    "50000": {
      title: "T-250, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 0,
      reactiveDays: 7,
    },
  },


  "56": {
    "25000": {
      title: "T-250, using Neutra-HOCL tablet (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Neutra-HOCL", value: "2 tablet (HOCL mix should be @ 300ppm-600ppm)" },
        { name: "Halo", value: "1100ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Neutra-HOCL tablet (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 0,
    },
  },


  "57": {
    "25000": {
      title: "T-250, using Regalia (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 7,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Regalia (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 7,
      reactiveDays: 0,
    },
  },

  //input here
  "58": {
    "25000": {
      title: "T-250, using Regalia (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 0,
      reactiveDays: 5,
    },
    "50000": {
      title: "T-250, using Regalia (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
         { name: "Halo", value: "875ml" },
        { name: "Zerotol", value: "200ml" },
        { name: "H20", value: "100ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 5,
    },
  },

  //this one needs review
  "59": {
    "25000": {
      title: "T-250, using Procidic2 (every 5 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
           { name: "Halo", value: "1175ml" },
        { name: "Procidic2", value: "50ml" },
        { name: "H20", value: "25ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Procidic2 (every 5 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 0,
    },
  },

  //needs review, should be right! Even number

  //min

  "60": {
    "25000": {
      title: "T-250, using Procidic2 (every 3 days. can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
           { name: "Halo", value: "875ml" },
        { name: "Procidic2", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Procidic2 (every 3 days. can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },


  //min again
  //input here
  "61": {
    "25000": {
      title: "T-250, using Zerotol (every 3 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "950ml" },
        { name: "Zerotol", value: "60ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Zerotol (every 3 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },


  //should be good!
  "62": {
    "25000": {
      title: "T-250, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 0,
      reactiveDays: 2,
    },
    "50000": {
      title: "T-250, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "950ml" },
        { name: "Zerotol", value: "200ml" },
        { name: "H20", value: "100ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 2,
    },
  },


  //should be good
  "63": {
    "25000": {
      title: "T-250, using Oxiphos (every 7 days under pressure stop using by day 14 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 0,
      reactiveDays: 7,
    },
    "50000": {
      title: "T-250, using Oxiphos (every 7 days under pressure stop using by day 14 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "950ml" },
        { name: "Oxiphos", value: "200ml" },
        { name: "H20", value: "100ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 7,
    },
  },

//min dr zymes
  //input needed
  "64": {
    "25000": {
      title: "T-250, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1175ml" },
        { name: "Dr. Zymes", value: "50ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },

  //should be good
  "65": {
    "25000": {
      title: "T-250, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Dr. Zymes", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-250, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
            { name: "Halo", value: "875ml" },
        { name: "Dr. Zymes", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },


//min
  "66": {
    "25000": {
      title: "T-250, using Flying Skull Nuke em (every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1050ml" },
        { name: "Nuke em", value: "120ml" },
        { name: "ISO", value: "80ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Flying Skull Nuke em (every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },

//should be good
  "67": {
    "25000": {
      title: "T-250, using Flying Skull Nuke em (every 2 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Nuke em", value: "325ml" },
        { name: "ISO", value: "50ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 2,
    },
    "50000": {
      title: "T-250, using Flying Skull Nuke em (every 2 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
            { name: "Halo", value: "875ml" },
        { name: "Nuke em", value: "325ml" },
        { name: "ISO", value: "50ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 2,
    },
  },

  //should be nice!
  "68": {
    "25000": {
      title: "T-250, using Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Nuke em", value: "300ml" },
        { name: "ISO", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 5,
    },
    "50000": {
      title: "T-250, using Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 0,
      reactiveDays: 5,
    },
  },

  
//min purecrop 1
//input needed
  "69": {
    "25000": {
      title: "T-250, using Pure Crop1 (every 5 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1100ml" },
        { name: "Pure Crop 1", value: "90ml" },
        { name: "ISO", value: "60ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Pure Crop1 (every 5 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 0,
    },
  },

  
  //should be good
  "70": {
    "25000": {
      title: "T-250, using Pure Crop1 (every 3 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Pure Crop 1", value: "300ml" },
        { name: "ISO", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-250, using Pure Crop1 (every 3 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
            { name: "Halo", value: "875ml" },
        { name: "Pure Crop 1", value: "300ml" },
        { name: "ISO", value: "75ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },

  //will need input
  //min
  "71": {
    "25000": {
      title: "T-250, using Circadian Sunrise + Allphase (every 5 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1100ml" },
        { name: "Circ. Sun", value: "90ml" },
        { name: "H20", value: "60ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Circadian Sunrise + Allphase (every 5 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 0,
    },
  },

  //should be good
  "72": {
    "25000": {
      title: "T-250, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Circ. Sun", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-250, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
            { name: "Halo", value: "875ml" },
        { name: "Circ. Sun", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },

  //no min
  //needs input, none!
  "73": {
    "25000": {
      title: "T-250, using Allphase (every 2 days or as needed can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Circ. Sun", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-250, using Allphase (every 2 days or as needed can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
            { name: "Halo", value: "875ml" },
        { name: "Circ. Sun", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },


  //min
  //should be good
  "74": {
    "25000": {
      title: "T-250, using Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Aura", value: "1130ml" },
        { name: "Grandevo CG", value: "18 Grams" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },

      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
                 { name: "Aura", value: "1075ml" },
        { name: "Grandevo CG", value: "5 TBSP" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },

  //should be good
  "75": {
    "25000": {
      title: "T-250, using Grandevo WDG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Aura", value: "1090ml" },
        { name: "Grandevo", value: "55 Gram" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
      preventativeDays: 4,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Grandevo WDG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 4,
      reactiveDays: 0,
    },
  },


  //min
  //should be good
  "76": {
    "25000": {
      title: "T-250, using Venerate CG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 4,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Venerate CG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
{ name: "Aura", value: "1075ml" },
        { name: "Venerate CG", value: "5 TBSP" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 4,
      reactiveDays: 0,
    },
  },
"79": {
  "25000": {
    title: "T-250, using Power si Original (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "1200ml" },
      { name: "Power si", value: "12ml" },
      { name: "H2O", value: "38ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-250, using Power si Original (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 3,
    reactiveDays: 0,
  },
},
"80": {
  "25000": {
    title: "T-250, using Power si Bloom (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
     { name: "Halo", value: "1200ml" },
      { name: "Power si", value: "24ml" },
      { name: "H2O", value: "26ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-250, using Power si Bloom (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 3,
    reactiveDays: 0,
  },
},

//min
//athena stack, input required
"81": {
  "25000": {
    title: "T-250, using Athena Stack (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
    mixture: [
        { name: "Halo", value: "1150ml" },
      { name: "Athena Stack", value: "50ml" },
      { name: "H20", value: "50ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-250, using Athena Stack (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
    mixture: [
    ],
      preventativeDays: 3,
    reactiveDays: 0,
  },

},

   // input required

"82": {
  "25000": {
    title: "T-250, using Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "1150ml" },
      { name: "Nitrozyme", value: "50ml" },
      { name: "H2O", value: "50ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-250, using Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 3,
    reactiveDays: 0,
  },
},

//min
 // input required
"83": {
"25000": {
  title: "T-250, using CES Foliar (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1000ml" },
    { name: "Bproof", value: "30ml" },
    { name: "Mag Ampd", value: "30ml" },
    { name: "UJB", value: "60ml" },
    { name: "Plant Ampd", value: "70ml" },
    { name: "H2O", value: "70ml" },
  ],
  preventativeDays: 3,
  reactiveDays: 0,
},
"50000": {
  title: "T-250, using CES Foliar (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 3,
  reactiveDays: 0,
},

},

 // input required
"84": {
"25000": {
  title: "T-250, using B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1200ml" },
    { name: "Humboldt Humic", value: "12.5ml" },
    { name: "H2O", value: "37.5ml" },
    { name: "PH", value: "5.8-6.2" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "T-250, using B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 7,
  reactiveDays: 0,
},
},


// need to review
"85": {
    "25000": {
      title: "T-250, using Humboldt Humic (1 to 2 times per week depending on the health of the plant)",
      sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
      mixture: [
        { name: "Halo", value: "1200ml" },
        { name: "Humboldt Humic", value: "12.5ml" },
        { name: "H2O", value: "37.5ml" },
        { name: "PH", value: "5.8-6.2" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Humboldt Humic (1 to 2 times per week depending on the health of the plant)",
      sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
      mixture: [],
      preventativeDays: 3,
      reactiveDays: 0,
    },
  },


// will need to review
"86": {
"25000": {
  title: "T-250, using House n Garden Magic Green (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1150ml" },
    { name: "Magic Green", value: "60ml" },
    { name: "H2O", value: "40ml" },
    { name: "PH", value: "5.8-6.2" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "T-250, using House n Garden Magic Green (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 7,
  reactiveDays: 0,
},
},

//min
// will need to review
"87": {
  "25000": {
    title: "T-250, using Optic Foliar Transport + Rev + Watts (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "950ml" },
      { name: "Transport", value: "30ml" },
      { name: "Rev", value: "30ml" },
      { name: "Watts", value: "30ml" },
      { name: "H2O", value: "60ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 7,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-250, using Optic Foliar Transport + Rev + Watts (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 7,
    reactiveDays: 0,
  },
},





  "88": {
    "25000": {
      title: "T-250, using Neutra Fog HOCL (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1150ml" },
        { name: "Neutra-HOCL", value: "1 Tablet (HOCL mix should be @ 300ppm-600ppm)" },
        { name: "R/O H20", value: "100ml" },
      ],
      preventativeDays: 4,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-250, using Neutra Fog HOCL  (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "1150ml" },
        { name: "Neutra-HOCL", value: "1 Tablet (HOCL mix should be @ 300ppm-600ppm)" },
        { name: "R/O H20", value: "100ml" },
      ],
        preventativeDays: 4,
      reactiveDays: 0,
    },
  },



     "89": {
    "25000": {
      title: "T-250, using Sanidate5.0 (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "950ml" },
        { name: "Sanidate", value: "200ml (Sanidate mix should be @ 100ppm-600ppm)" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-250, using Sanidate5.0 (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },



      "90": {
    "25000": {
      title: "T-250, using Athena Cleanse (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "900ml" },
        { name: "Athena Cleanse", value: "300ml (Athena Cleanse mix should be @ 300ppm-600ppm)" },
        { name: "H20", value: "50ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-250, using Athena Cleanse (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },
  },
  "t500": {


    "1": {
      "25000": {
        title: "T-500, using Azagaurd (every 7 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1215ml" },
          { name: "Azagaurd", value: "15ml" },
          { name: "R/O H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Azagaurd (every 7 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azagaurd", value: "30ml" },
          { name: "R/O H2O", value: "30ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "2": {
      "25000": {
        title: "T-500, using Azagaurd (every 5 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1160ml" },
          { name: "Azagaurd", value: "36ml" },
          { name: "H2O", value: "54ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-500, using Azagaurd (every 5 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1110ml" },
          { name: "Azagaurd", value: "75ml" },
          { name: "H2O", value: "65ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "3": {
      "25000": {
        title: "T-500, using Azamax (every 7 days until pressure subsides or stop by day 18)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azamax", value: "45ml" },
          { name: "R/O H2O", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Azamax (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "4": {
      "25000": {
        title: "T-500, using Azamax (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1090ml" },
          { name: "Azamax", value: "120ml" },
          { name: "H2O", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "5": {
      "25000": {
        title: "T-500, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1215ml" },
          { name: "Azatin", value: "15ml" },
          { name: "R/O H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1200ml" },
          { name: "Azatin", value: "30ml" },
          { name: "R/O H2O", value: "20ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "6": {
      "25000": {
        title: "T-500, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azatin", value: "36ml" },
          { name: "H2O", value: "24ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-500, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
   
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "7": {
      "25000": {
        title: "T-500, using Suffoil (every 5 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Suffoil-x", value: "75ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      // not recommended
      "50000": {
        title: "T-500, using Suffoil (every 5 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "8": {
      "25000": {
        title: "T-500, using Suffoil (every 3 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Suffoil-x", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Suffoil Suffoil (every 3 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "9": {
      "25000": {
        title: "T-500, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tri-Tek", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "10": {
      "25000": {
        title: "T-500, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tri-Tek", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "11": {
      "25000": {
        title: "T-500, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Big Time", value: "90ml" },
          { name: "ISO", value: "10ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
         
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "12": {
      "25000": {
        title: "T-500, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Big Time", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "13": {
      "25000": {
        title: "T-500, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Smite", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
  
    },
    "14": {
      "25000": {
        title: "T-500, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Smite", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-500, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },

    "15": {
      "25000": {
        title: "T-500, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "SNS 203", value: "120ml" },
          { name: "ISO", value: "80ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "SNS 203", value: "120ml" },
          { name: "ISO", value: "80ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },
    "16": {
      "25000": {
        title: "T-500, using SNS 203 (every 2 days. stop by day 18 of flower.  No more than 5 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "950ml" },
          { name: "SNS 203", value: "100ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "T-500, using SNS 203 (every 2 days. stop by day 18 of flower.  No more than 5 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
    },
    "17": {
      "25000": {
        title: "T-500, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "BioControl", value: "60ml" },
          { name: "R/O ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },

      "50000": {
        title: "T-500, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "18": {
      "25000": {
        title: "T-500, using Mammoth Biocontrol (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "BioControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Mammoth Biocontrol (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },


    "19": {
      "25000": {
        title: "T-500, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "CannControl", value: "60ml" },
          { name: "R/O ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
    
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "20": {
      "25000": {
        title: "T-500, using Mammoth CannControl (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "CannControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Mammoth CannControl (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "21": {
      "25000": {
        title: "T-500, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Athena IPM", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 2,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 2,
        reactiveDays: 0,
      },
    },
    "22": {
      "25000": {
        title: "T-500, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Athena IPM", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
      "50000": {
        title: "T-500, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
    },
    "23": {
      "25000": {
        title: "T-500, using Growsafe (every 5 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Growsafe", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Growsafe (every 5 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "24": {
      "25000": {
        title: "T-500, using Growsafe T(every 3 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Growsafe", value: "300ml" },
          { name: "ISO", value: "75ml" },
          // { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Growsafe T(every 3 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "25": {
      "25000": {
        title: "T-500, using Wizards Brew Concentrate (every 5 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Wizard Brew", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Wizards Brew Concentrate (every 5 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "26": {
      "25000": {
        title: "T-500, using Wizards Brew Concentrate (every 3 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Wizard Brew", value: "300ml" },
          { name: "ISO", value: "50ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Wizards Brew Concentrate (every 3 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "27": {
      "25000": {
        title: "T-500, using TetraCurb (every 5 days and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tetra Curb", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using TetraCurb (every 5 days and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "28": {
      "25000": {
        title: "T-500, using TetraCurb (every 3 days and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tetra Curb", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using TetraCurb (every 3 days and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "29": {
      "25000": {
        title: "T-500, using Organishield (every 10 days no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Organishield", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Organishield (every 10 days no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
    },
    "30": {
      "25000": {
        title: "T-500, using Organishield (every 5 days no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Organishield", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "T-500, using Organishield (every 5 days no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },

    "31": {
      "25000": {
        title: "T-500, using Plant Therapy (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Plant Therapy", value: "60ml" },
          { name: "H2O", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Plant Therapy (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "32": {
      "25000": {
        title: "T-500, using Plant Therapy (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Plant Therapy", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Plant Therapy (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "33": {
      "25000": {
        title: "T-500, using Green Cleaner (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Green Cleaner", value: "60ml" },
          { name: "H2O", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Green Cleaner (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [

        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "34": {
      "25000": {
        title: "T-500, using Green Cleaner Green Cleaner (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Green Cleaner", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Green Cleaner Green Cleaner (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "35": {
      "25000": {
        title: "T-500, using Dr. Zymes (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Dr Zymes", value: "50ml" },
          { name: "H2O", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Dr. Zymes (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "36": {
      "25000": {
        title: "T-500, using Dr. Zymes (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Zymes", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Dr. Zymes (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [

        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "37": {
      "25000": {
        title: "T-500, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Nuke em", value: "120ml" },
          { name: "R/O ISO", value: "80ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },
    "38": {
      "25000": {
        title: "T-500, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Nuke em", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "39": {
      "25000": {
        title: "T-500, using Dr. Bronners Peppermint Dr. Bronners Peppermint (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Dr Bronners", value: "75ml" },
          { name: "H2O", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Dr. Bronners Peppermint Dr. Bronners Peppermint (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "40": {
      "25000": {
        title: "T-500, using Dr. Bronners Peppermint (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Bronners", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Dr. Bronners Peppermint (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "41": {
      "25000": {
        title: "T-500, using Mpede (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Mpede", value: "75ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Mpede (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "42": {
      "25000": {
        title: "T-500, using Mpede (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Mpede", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Mpede (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },



    "46": {
      "25000": {
        title: "T-500, using Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Cpt. Jacks", value: "50ml" },
          { name: "H20", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using T-500, using Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "47": {
      "25000": {
        title: "T-500, using Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Cpt. Jacks", value: "300ml" },
          { name: "H20", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "48": {
      "25000": {
        title: "T-500, using Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Monterey", value: "50ml" },
          { name: "H20", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "49": {
      "25000": {
        title: "T-500, using Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Monterey", value: "300ml" },
          { name: "H20", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "T-500, using Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },
    "50": {
      "25000": {
        title: "T-500, using Circadian Sunrise (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1100ml" },
          { name: "Circ. Sun", value: "90ml" },
          { name: "ISO", value: "60ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Circadian Sunrise (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },

    "51": {
      "25000": {
        title: "T-500, using Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Circ. Sun", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
      "50000": {
        title: "T-500, using Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
    },
    "52": {
      "25000": {
        title: "T-500, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Circ. Sun", value: "90ml" },
          { name: "Alphase", value: "7gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "T-500, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },
    "53": {
      "25000": {
        title: "T-500, using Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "300ml" },
          { name: "Circ. Sun", value: "300ml" },
          { name: "Alphase", value: "30gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
      "50000": {
        title: "T-500, using Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 1,
      },
    },

    "54": {
    "25000": {
      title: "T-500, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "1035ml" },
        { name: "Azaguard", value: "15ml" },
        { name: "Zerotol", value: "50ml" },
        { name: "Oxiphos", value: "50ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 0,
    },
  },

  "55": {
    "25000": {
      title: "T-500, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "1015ml" },
        { name: "Azaguard", value: "36ml" },
        { name: "Zerotol", value: "50ml" },
        { name: "Oxiphos", value: "50ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 7,
    },
    "50000": {
      title: "T-500, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 0,
      reactiveDays: 7,
    },
  },



  "56": {
    "25000": {
      title: "T-500, using Neutra-HOCL tablet (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
      preventativeDays: 7,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Neutra-HOCL tablet (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 0,
    },
  },


  "57": {
    "25000": {
      title: "T-500, using Regalia Maxx (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 7,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Regalia Maxx (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
         { name: "Halo", value: "1070ml" },
        { name: "Regalia CG", value: "130ml" },
        { name: "H20", value: "50ml" },
        { name: "PH", value: "6.0" },

      ],
        preventativeDays: 7,
      reactiveDays: 0,
    },
  },

  "58": {
    "25000": {
      title: "T-500, using Regalia Maxx (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 0,
      reactiveDays: 5,
    },
    "50000": {
      title: "T-500, using Regalia Maxx (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
         { name: "Halo", value: "875ml" },
        { name: "Regalia CG", value: "75ml" },
        { name: "H20", value: "75ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 0,
      reactiveDays: 5,
    },
  },

  //min put here
  "59": {
    "25000": {
      title: "T-500, using Procidic2 (every 5 days. can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
           { name: "Halo", value: "1175ml" },
        { name: "Procidic2", value: "50ml" },
        { name: "H20", value: "25ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Procidic2 (every 3 days. can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 0,
    },
  },



  "60": {
    "25000": {
      title: "T-500, using Procidic2 (every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
           { name: "Halo", value: "875ml" },
        { name: "Procidic2", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Procidic2 (every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },


  //min, T-500
  "61": {
    "25000": {
      title: "T-500, using Zerotol 2.0 (every 3 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "950ml" },
        { name: "Zerotol", value: "60ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },


  //should be good!, not good
  "62": {
    "25000": {
      title: "T-500, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 0,
      reactiveDays: 2,
    },
    "50000": {
      title: "T-500, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "950ml" },
        { name: "Zerotol", value: "200ml" },
        { name: "H20", value: "100ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 2,
    },
  },


  "63": {
    "25000": {
      title: "T-500, using Oxiphos (every 7 days under pressure stop using by day 14 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 0,
      reactiveDays: 7,
    },
    "50000": {
      title: "T-500, using Oxiphos (every 7 days under pressure stop using by day 14 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "950ml" },
        { name: "Oxiphos", value: "200ml" },
        { name: "H20", value: "100ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 7,
    },
  },


  //min
  "64": {
    "25000": {
      title: "T-500, using Dr Zymes (every 3 days under pressure,  can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1175ml" },
        { name: "Dr. Zymes", value: "50ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Dr Zymes (every 3 days under pressure,  can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },

  //should be good
  "65": {
    "25000": {
      title: "T-500, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Dr. Zymes", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-500, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },


  "66": {
    "25000": {
      title: "T-500 using Flying Skull Nuke em ( every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1050ml" },
        { name: "Nuke em", value: "120ml" },
        { name: "ISO", value: "80ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Flying Skull Nuke em ( every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },

  "67": {
    "25000": {
      title: "T-500, using Flying Skull Nuke em (every 2 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Nuke em", value: "325ml" },
        { name: "ISO", value: "50ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 2,
    },
    "50000": {
      title: "T-500, using Flying Skull Nuke em (every 2 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
            { name: "Halo", value: "875ml" },
        { name: "Nuke em", value: "325ml" },
        { name: "ISO", value: "50ml" },
      ],
        preventativeDays: 0,
      reactiveDays: 2,
    },
  },

  "68": {
    "25000": {
      title: "T-500, using Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "875ml" },
        { name: "Wizard Brew", value: "300ml" },
        { name: "ISO", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 5,
    },
    "50000": {
      title: "T-500, using Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
         
      ],
        preventativeDays: 0,
      reactiveDays: 5,
    },
  },

  
  "69": {
    "25000": {
      title: "T-500, using Pure Crop1 (every 5 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1100ml" },
        { name: "Pure Crop 1", value: "90ml" },
        { name: "ISO", value: "60ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Pure Crop1 (every 5 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 0,
    },
  },

  
  "70": {
    "25000": {
      title: "T-500, using Pure Crop1 (every 3 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Pure Crop 1", value: "300ml" },
        { name: "ISO", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-500, using Pure Crop1 (every 3 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },

  "71": {
    "25000": {
      title: "T-500, using Circadian Sunrise + Allphase (every 5 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1100ml" },
        { name: "Circ. Sun", value: "90ml" },
        { name: "H20", value: "60ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Circadian Sunrise + Allphase (every 5 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 0,
    },
  },

  "72": {
    "25000": {
      title: "T-500, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Circ. Sun", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-500, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },

  "73": {
    "25000": {
      title: "T-500, using Allphase (every 2 days or as needed can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-500, using Allphase (every 2 days or as needed can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },

  "74": {
    "25000": {
      title: "T-500, using Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        
      ],
      preventativeDays: 3,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
                 { name: "Aura", value: "1075lml" },
        { name: "Grandevo CG", value: "5 TBSP" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 3,
      reactiveDays: 0,
    },
  },

  "75": {
    "25000": {
      title: "T-500, using Grandevo WDG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Aura", value: "1130ml" },
        { name: "Grandevo", value: "18 Gram" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
      preventativeDays: 4,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Grandevo WDG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 4,
      reactiveDays: 0,
    },
  },

  "76": {
    "25000": {
      title: "T-500, using Venerate CG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 4,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Venerate CG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
{ name: "Aura", value: "1075ml" },
        { name: "Venerate CG", value: "5 TBSP" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 4,
      reactiveDays: 0,
    },
  },
"79": {
  "25000": {
    title: "T-500, using Power si Original (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "1200ml" },
      { name: "Power si", value: "12ml" },
      { name: "H2O", value: "38ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-500, using Power si Original (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 3,
    reactiveDays: 0,
  },
},
"80": {
  "25000": {
    title: "T-500, using Power si Bloom (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
     { name: "Halo", value: "1200ml" },
      { name: "Power si", value: "24ml" },
      { name: "H2O", value: "26ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-500, using Power si Bloom (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 3,
    reactiveDays: 0,
  },
},

//athena stack, input required
"81": {
  "25000": {
    title: "T-500, using Athena Stack (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
    mixture: [
        { name: "Halo", value: "1150ml" },
      { name: "Athena Stack", value: "50ml" },
      { name: "H20", value: "50ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-500, using Athena Stack (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
    mixture: [
    ],
      preventativeDays: 3,
    reactiveDays: 0,
  },

},

   // input required

"82": {
  "25000": {
    title: "T-500, using Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "1150ml" },
      { name: "Nitrozyme", value: "50ml" },
      { name: "H2O", value: "50ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 4,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-500, using Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 4,
    reactiveDays: 0,
  },
},

 // input required
"83": {
"25000": {
  title: "T-500, using CES Foliar (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1000ml" },
    { name: "Bproof si", value: "30ml" },
    { name: "Mag Ampd", value: "60ml" },
    { name: "UJB", value: "60ml" },
    { name: "Plant Ampd", value: "60ml" },
    { name: "H2O", value: "70ml" },
  ],
  preventativeDays: 3,
  reactiveDays: 0,
},
"50000": {
  title: "T-500, using CES Foliar (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1000ml" },
    { name: "Bproof si", value: "30ml" },
    { name: "Mag Ampd", value: "60ml" },
    { name: "UJB", value: "60ml" },
    { name: "Plant Ampd", value: "60ml" },
    { name: "H2O", value: "70ml" },
  ],
  preventativeDays: 3,
  reactiveDays: 0,
},

},

 // input required
"84": {
"25000": {
  title: "T-500, using B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "T-500, using B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 7,
  reactiveDays: 0,
},
},
//up to europonic nitrozine, need to alter, if there is no max. 

// need to review
"85": {
    "25000": {
      title: "T-500, using Humboldt Humic (1 to 2 times per week depending on the health of the plant)",
      sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
      mixture: [
        { name: "Halo", value: "1200ml" },
        { name: "Humboldt Humic", value: "12.5ml" },
        { name: "H2O", value: "37.5ml" },
        { name: "PH", value: "5.8-6.2" },
      ],
      preventativeDays: 4,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Humboldt Humic (1 to 2 times per week depending on the health of the plant)",
      sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
      mixture: [],
      preventativeDays: 4,
      reactiveDays: 0,
    },
  },


// will need to review
"86": {
"25000": {
  title: "T-500, using Magic Green (every 7 days)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1150ml" },
    { name: "Magic Green", value: "60ml" },
    { name: "H2O", value: "40ml" },
    { name: "PH", value: "5.8-6.2" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "T-500, using Magic Green (every 7 days)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 7,
  reactiveDays: 0,
},
},

"87": {
  "25000": {
    title: "T-500, using Transport + Watts (every 7 days)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "950ml" },
      { name: "Transport", value: "30ml" },
      { name: "Rev", value: "30ml" },
      { name: "Watts", value: "30ml" },
      { name: "H2O", value: "60ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 7,
    reactiveDays: 0,
  },
  "50000": {
    title: "T-500, using Transport + Watts (every 7 days)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 7,
    reactiveDays: 0,
  },
},





  "88": {
    "25000": {
      title: "T-500, using Neutra Fog HOCL (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1150ml" },
        { name: "Neutra-HOCL", value: "1 Tablet (HOCL mix should be @ 300ppm-600ppm)" },
        { name: "R/O H20", value: "100ml" },
      ],
      preventativeDays: 4,
      reactiveDays: 0,
    },
    "50000": {
      title: "T-500, using Neutra Fog HOCL  (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 4,
      reactiveDays: 0,
    },
  },



     "89": {
    "25000": {
      title: "T-500, using Sanidate5.0 (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1085ml" },
        { name: "Sanidate", value: "65ml (Sanidate mix should be @ 100ppm-600ppm)" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-500, using Sanidate5.0 (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
  },



      "90": {
    "25000": {
      title: "T-500, using Athena Cleanse (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1050ml" },
        { name: "Athena Cleanse", value: "100ml (Athena Cleanse mix should be @ 300ppm-600ppm)" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 0,
      reactiveDays: 3,
    },
    "50000": {
      title: "T-500, using Athena Cleanse (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 0,
      reactiveDays: 3,
    },
},
  },
  "pro1500": {
    // ... Similar structure for Pro-1500
   
    "1": {
      "25000": {
        title: "Pro-1500, using Azagaurd (every 7 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1170ml" },
          { name: "Azagaurd", value: "40ml" },
          { name: "R/O H2O", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Azagaurd (every 7 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1190ml" },
          { name: "Azagaurd", value: "30ml" },
          { name: "R/O H2O", value: "30ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "2": {
      "25000": {
        title: "Pro-1500, using Azagaurd (every 5 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1100ml" },
          { name: "Azagaurd", value: "100ml" },
          { name: "H2O", value: "50ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-1500, using Azagaurd (every 5 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "3": {
      "25000": {
        title: "Pro-1500, using Azamax (every 7 days until pressure subsides or stop by day 18)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1100ml" },
          { name: "Azamax", value: "120ml" },
          { name: "R/O H2O", value: "30ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Azamax (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "4": {
      "25000": {
        title: "Pro-1500, using Azamax (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Azamax", value: "325ml" },
          { name: "H2O", value: "50ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "5": {
      "25000": {
        title: "Pro-1500, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1170ml" },
          { name: "Azatin", value: "40ml" },
          { name: "R/O H2O", value: "40ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Azatin (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "6": {
      "25000": {
        title: "Pro-1500, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1100ml" },
          { name: "Azatin", value: "100ml" },
          { name: "H2O", value: "50ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-1500, using Azatin (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
   
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },
    "7": {
      "25000": {
        title: "Pro-1500, using Suffoil (every 5 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Suffoil-x", value: "75ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      // not recommended
      "50000": {
        title: "Pro-1500, using Suffoil (every 5 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "8": {
      "25000": {
        title: "Pro-1500, using Suffoil (every 3 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Suffoil-x", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Suffoil Suffoil (every 3 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "9": {
      "25000": {
        title: "Pro-1500, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tri-Tek", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Tritek (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "10": {
      "25000": {
        title: "Pro-1500, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tri-Tek", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Tritek (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "11": {
      "25000": {
        title: "Pro-1500, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Big Time", value: "90ml" },
          { name: "ISO", value: "10ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Big Time Exterminator (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
         
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "12": {
      "25000": {
        title: "Pro-1500, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Big Time", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Big Time Exterminator (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "13": {
      "25000": {
        title: "Pro-1500, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Smite", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Smite (every 7 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
  
    },
    "14": {
      "25000": {
        title: "Pro-1500, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Smite", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-1500, using Smite (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },

    "15": {
      "25000": {
        title: "Pro-1500, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "SNS 203", value: "120ml" },
          { name: "ISO", value: "80ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using SNS 203 (every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },
    "16": {
      "25000": {
        title: "Pro-1500, using SNS 203 (every 2 days. stop by day 18 of flower.  No more than 5 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "950ml" },
          { name: "SNS 203", value: "100ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "Pro-1500, using SNS 203 (every 2 days. stop by day 18 of flower.  No more than 5 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
    },
    "17": {
      "25000": {
        title: "Pro-1500, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "BioControl", value: "60ml" },
          { name: "R/O ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },

      "50000": {
        title: "Pro-1500, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "18": {
      "25000": {
        title: "Pro-1500, using Mammoth Biocontrol (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "BioControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Mammoth Biocontrol (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },


    "19": {
      "25000": {
        title: "Pro-1500, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "CannControl", value: "60ml" },
          { name: "R/O ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Mammoth CannControl (every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
    
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "20": {
      "25000": {
        title: "Pro-1500, using Mammoth CannControl (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "CannControl", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Mammoth CannControl (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "21": {
      "25000": {
        title: "Pro-1500, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Athena IPM", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
      "50000": {
        title: "Pro-1500, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
    },
    "22": {
      "25000": {
        title: "Pro-1500, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Athena IPM", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
      "50000": {
        title: "Pro-1500, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
    },
    "23": {
      "25000": {
        title: "Pro-1500, using Growsafe (every 5 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Growsafe", value: "60ml" },
          { name: "ISO", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Growsafe (every 5 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "24": {
      "25000": {
        title: "Pro-1500, using Growsafe T(every 3 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Growsafe", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Growsafe T(every 3 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "25": {
      "25000": {
        title: "Pro-1500, using Wizards Brew Concentrate (every 5 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Wizard Brew", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Wizards Brew Concentrate (every 5 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "26": {
      "25000": {
        title: "Pro-1500, using Wizards Brew Concentrate (every 3 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Wizard Brew", value: "300ml" },
          { name: "ISO", value: "50ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Wizards Brew Concentrate (every 3 days then stop by day 18 of flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "27": {
      "25000": {
        title: "Pro-1500, using TetraCurb (every 5 days and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Tetra Curb", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using TetraCurb (every 5 days and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "28": {
      "25000": {
        title: "Pro-1500, using TetraCurb (every 3 days and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tetra Curb", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using TetraCurb (every 3 days and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "29": {
      "25000": {
        title: "Pro-1500, using Organishield (every 10 days no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Organishield", value: "50ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Organishield (every 10 days no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
    },
    "30": {
      "25000": {
        title: "Pro-1500, using Organishield (every 5 days no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Organishield", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-1500, using Organishield (every 5 days no more than 6 applications per crop cycle)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },

    "31": {
      "25000": {
        title: "Pro-1500, using Plant Therapy (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Plant Therapy", value: "60ml" },
          { name: "H2O", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Plant Therapy (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "32": {
      "25000": {
        title: "Pro-1500, using Plant Therapy (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Plant Therapy", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Plant Therapy (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "33": {
      "25000": {
        title: "Pro-1500, using Green Cleaner (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Green Cleaner", value: "60ml" },
          { name: "H2O", value: "40ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Green Cleaner (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [

        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "34": {
      "25000": {
        title: "Pro-1500, using Green Cleaner Green Cleaner (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Green Cleaner", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Green Cleaner Green Cleaner (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "35": {
      "25000": {
        title: "Pro-1500, using Dr. Zymes (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Dr Zymes", value: "50ml" },
          { name: "H2O", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Dr. Zymes (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "36": {
      "25000": {
        title: "Pro-1500, using Dr. Zymes (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Zymes", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Dr. Zymes (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [

        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "37": {
      "25000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Nuke em", value: "120ml" },
          { name: "R/O ISO", value: "80ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "38": {
      "25000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Nuke em", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "Pro-1500, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
    },
    "39": {
      "25000": {
        title: "Pro-1500, using Dr. Bronners Peppermint Dr. Bronners Peppermint (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Dr Bronners", value: "75ml" },
          { name: "H2O", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Dr. Bronners Peppermint Dr. Bronners Peppermint (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "40": {
      "25000": {
        title: "Pro-1500, using Dr. Bronners Peppermint (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Dr Bronners", value: "300ml" },
          { name: "H2O", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Dr. Bronners Peppermint (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "41": {
      "25000": {
        title: "Pro-1500, using Mpede (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1150ml" },
          { name: "Mpede", value: "75ml" },
          { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-1500, using Mpede (every 5 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "42": {
      "25000": {
        title: "Pro-1500, using Mpede (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Mpede", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Mpede (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },



    "46": {
      "25000": {
        title: "Pro-1500, using Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Cpt. Jacks", value: "50ml" },
          { name: "H20", value: "25ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Pro-1500, using Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "47": {
      "25000": {
        title: "Pro-1500, using Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Cpt. Jacks", value: "300ml" },
          { name: "H20", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "48": {
      "25000": {
        title: "Pro-1500, using Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1175ml" },
          { name: "Monterey", value: "50ml" },
          { name: "H20", value: "25ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "49": {
      "25000": {
        title: "Pro-1500, using Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Monterey", value: "300ml" },
          { name: "H20", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "50": {
      "25000": {
        title: "Pro-1500, using Circadian Sunrise (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1100ml" },
          { name: "Circ. Sun", value: "90ml" },
          { name: "ISO", value: "60ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Circadian Sunrise (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "51": {
      "25000": {
        title: "Pro-1500, using Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Circ. Sun", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "52": {
      "25000": {
        title: "Pro-1500, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Circ. Sun", value: "90ml" },
          { name: "Alphase", value: "7gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "53": {
      "25000": {
        title: "Pro-1500, using Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
          { name: "Halo", value: "300ml" },
          { name: "Circ. Sun", value: "300ml" },
          { name: "Alphase", value: "30gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-1500, using Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "54": {
    "25000": {
      title: "Pro-1500, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "960ml" },
        { name: "Azaguard", value: "40ml" },
        { name: "Zerotol", value: "75ml" },
        { name: "Oxiphos", value: "75ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 7,
    },
  },

  "55": {
    "25000": {
      title: "Pro-1500, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "900ml" },
        { name: "Azaguard", value: "100ml" },
        { name: "Zerotol", value: "75ml" },
        { name: "Oxiphos", value: "75ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 7,
    },
  },



  "56": {
    "25000": {
      title: "Pro-1500, using Neutra-HOCL tablet (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Neutra-HOCL tablet (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 7,
    },
  },


  "57": {
    "25000": {
      title: "Pro-1500, using Regalia Maxx (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Regalia Maxx (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
         { name: "Halo", value: "1070ml" },
        { name: "Regalia CG", value: "130ml" },
        { name: "H20", value: "50ml" },
        { name: "PH", value: "6.0" },

      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  "58": {
    "25000": {
      title: "Pro-1500, using Regalia Maxx (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Regalia Maxx (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
         { name: "Halo", value: "875ml" },
        { name: "Regalia CG", value: "75ml" },
        { name: "H20", value: "75ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  //min put here
  "59": {
    "25000": {
      title: "Pro-1500, using Procidic2 (every 5 days. can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
           { name: "Halo", value: "1175ml" },
        { name: "Procidic2", value: "50ml" },
        { name: "H20", value: "25ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Procidic2 (every 3 days. can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },



  "60": {
    "25000": {
      title: "Pro-1500, using Procidic2 (every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
           { name: "Halo", value: "875ml" },
        { name: "Procidic2", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Procidic2 (every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },


  //min, Pro-1500
  "61": {
    "25000": {
      title: "Pro-1500, using Zerotol 2.0 (every 3 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "950ml" },
        { name: "Zerotol", value: "60ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },


  //should be good!, not good
  "62": {
    "25000": {
      title: "Pro-1500, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "950ml" },
        { name: "Zerotol", value: "200ml" },
        { name: "H20", value: "100ml" },
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },


  "63": {
    "25000": {
      title: "Pro-1500, using Oxiphos (every 7 days under pressure stop using by day 14 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-1500, using Oxiphos (every 7 days under pressure stop using by day 14 of flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "950ml" },
        { name: "Oxiphos", value: "200ml" },
        { name: "H20", value: "100ml" },
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },


  //min
  "64": {
    "25000": {
      title: "Pro-1500, using Dr Zymes (every 3 days under pressure,  can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1175ml" },
        { name: "Dr. Zymes", value: "50ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Dr Zymes (every 3 days under pressure,  can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  //should be good
  "65": {
    "25000": {
      title: "Pro-1500, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Dr. Zymes", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },


  "66": {
    "25000": {
      title: "Pro-1500 using Flying Skull Nuke em ( every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1050ml" },
        { name: "Nuke em", value: "120ml" },
        { name: "ISO", value: "80ml" },
      ],
      preventativeDays: 2,
      reactiveDays: 2,
    },
    "50000": {
      title: "Pro-1500, using Flying Skull Nuke em ( every 3 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },

  "67": {
    "25000": {
      title: "Pro-1500, using Flying Skull Nuke em (every 2 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Nuke em", value: "325ml" },
        { name: "ISO", value: "50ml" },
      ],
      preventativeDays: 2,
      reactiveDays: 2,
    },
    "50000": {
      title: "Pro-1500, using Flying Skull Nuke em (every 2 days, can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },

  "68": {
    "25000": {
      title: "Pro-1500, using Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Halo", value: "875ml" },
        { name: "Wizard Brew", value: "300ml" },
        { name: "ISO", value: "75ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 5,
    },
    "50000": {
      title: "Pro-1500, using Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
         
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  
  "69": {
    "25000": {
      title: "Pro-1500, using Pure Crop1 (every 5 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1100ml" },
        { name: "Pure Crop 1", value: "90ml" },
        { name: "ISO", value: "60ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 5,
    },
    "50000": {
      title: "Pro-1500, using Pure Crop1 (every 5 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  
  "70": {
    "25000": {
      title: "Pro-1500, using Pure Crop1 (every 3 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Pure Crop 1", value: "300ml" },
        { name: "ISO", value: "75ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 5,
    },
    "50000": {
      title: "Pro-1500, using Pure Crop1 (every 3 days can be used up to day of harvest)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  "71": {
    "25000": {
      title: "Pro-1500, using Circadian Sunrise + Allphase (every 5 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1100ml" },
        { name: "Circ. Sun", value: "90ml" },
        { name: "H20", value: "60ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Circadian Sunrise + Allphase (every 5 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  "72": {
    "25000": {
      title: "Pro-1500, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "875ml" },
        { name: "Circ. Sun", value: "300ml" },
        { name: "H20", value: "75ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  "73": {
    "25000": {
      title: "Pro-1500, using Allphase (every 2 days or as needed can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Allphase (every 2 days or as needed can be used in late flower)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  "74": {
    "25000": {
      title: "Pro-1500, using Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        
      ],
      preventativeDays: 2,
      reactiveDays: 2,
    },
    "50000": {
      title: "Pro-1500, using Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
                 { name: "Aura", value: "1075lml" },
        { name: "Grandevo CG", value: "5 TBSP" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },

  "75": {
    "25000": {
      title: "Pro-1500, using Grandevo WDG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
        { name: "Aura", value: "1130ml" },
        { name: "Grandevo", value: "18 Gram" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Grandevo WDG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [

      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  "76": {
    "25000": {
      title: "Pro-1500, using Venerate CG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Venerate CG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
{ name: "Aura", value: "1075ml" },
        { name: "Venerate CG", value: "5 TBSP" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },
"77": {
  "25000": {
    title: "Pro-1500, using Power si Original (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "1200ml" },
      { name: "Power si", value: "12ml" },
      { name: "H2O", value: "38ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 7,
    reactiveDays: 0,
  },
  "50000": {
    title: "Pro-1500, using Power si Original (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 7,
    reactiveDays: 0,
  },
},
"78": {
  "25000": {
    title: "Pro-1500, using Power si Bloom (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
     { name: "Halo", value: "1200ml" },
      { name: "Power si", value: "24ml" },
      { name: "H2O", value: "26ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 5,
    reactiveDays: 0,
  },
  "50000": {
    title: "Pro-1500, using Power si Bloom (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 5,
    reactiveDays: 0,
  },
},

//athena stack, input required
"81": {
  "25000": {
    title: "Pro-1500, using Athena Stack (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
    mixture: [
        { name: "Halo", value: "1150ml" },
      { name: "Athena Stack", value: "50ml" },
      { name: "H20", value: "50ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 3,
  },
  "50000": {
    title: "Pro-1500, using Athena Stack (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
    mixture: [
    ],
      preventativeDays: 3,
    reactiveDays: 3,
  },

},

   // input required

"82": {
  "25000": {
    title: "Pro-1500, using Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "1150ml" },
      { name: "Nitrozyme", value: "50ml" },
      { name: "H2O", value: "50ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 7,
    reactiveDays: 0,
  },
  "50000": {
    title: "Pro-1500, using Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 7,
    reactiveDays: 0,
  },
},

 // input required
"83": {
"25000": {
  title: "Pro-1500, using CES Foliar (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1000ml" },
    { name: "Bproof si", value: "30ml" },
    { name: "Mag Ampd", value: "30ml" },
    { name: "UJB", value: "60ml" },
    { name: "Plant Ampd", value: "60ml" },
    { name: "H2O", value: "70ml" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "Pro-1500, using CES Foliar (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},

},

 // input required
"84": {
"25000": {
  title: "Pro-1500, using B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1150ml" },
    { name: "B1 Thrive", value: "75ml" },
    { name: "H2O", value: "25ml" },
    { name: "PH", value: "5.8-6.2" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "Pro-1500, using B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 7,
  reactiveDays: 0,
},
},
//up to europonic nitrozine, need to alter, if there is no max. 

// need to review
"85": {
    "25000": {
      title: "Pro-1500, using Humboldt Humic (1 to 2 times per week depending on the health of the plant)",
      sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
      mixture: [
        { name: "Halo", value: "1200ml" },
        { name: "Humboldt Humic", value: "12.5ml" },
        { name: "H2O", value: "37.5ml" },
        { name: "PH", value: "5.8-6.2" },
      ],
      preventativeDays: 7,
      reactiveDays: 0,
    },
    "50000": {
      title: "Pro-1500, using Humboldt Humic (1 to 2 times per week depending on the health of the plant)",
      sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
      mixture: [],
      preventativeDays: 7,
      reactiveDays: 0,
    },
  },


// will need to review
"86": {
"25000": {
  title: "Pro-1500, using Magic Green (every 7 days)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1150ml" },
    { name: "Magic Green", value: "60ml" },
    { name: "H2O", value: "40ml" },
    { name: "PH", value: "5.8-6.2" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "Pro-1500, using Magic Green (every 7 days)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 7,
  reactiveDays: 0,
},
},

"87": {
  "25000": {
    title: "Pro-1500, using Transport + Watts (every 7 days)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "950ml" },
      { name: "Transport", value: "30ml" },
      { name: "Rev", value: "30ml" },
      { name: "Watts", value: "30ml" },
      { name: "H2O", value: "60ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 7,
    reactiveDays: 0,
  },
  "50000": {
    title: "Pro-1500, using Transport + Watts (every 7 days)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 7,
    reactiveDays: 0,
  },
},





  "88": {
    "25000": {
      title: "Pro-1500, using Neutra Fog HOCL (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1150ml" },
        { name: "Neutra-HOCL", value: "1 Tablet (HOCL mix should be @ 300ppm-600ppm)" },
        { name: "R/O H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Neutra Fog HOCL  (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },



     "89": {
    "25000": {
      title: "Pro-1500, using Sanidate5.0 (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1085ml" },
        { name: "Sanidate", value: "65ml (Sanidate mix should be @ 100ppm-600ppm)" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Sanidate5.0 (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },



      "90": {
    "25000": {
      title: "Pro-1500, using Athena Cleanse (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
          { name: "Halo", value: "1050ml" },
        { name: "Athena Cleanse", value: "100ml (Athena Cleanse mix should be @ 300ppm-600ppm)" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-1500, using Athena Cleanse (2 times per week)",
      sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
},
},
  "pro4000": {

    "1": {
      "25000": {
        title: "Pro-4000, using Azagaurd (every 7 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3225ml" },
          { name: "Azagaurd", value: "200ml" },
          { name: "R/O H2O", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azagaurd (every 7 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "2": {
      "25000": {
        title: "Pro-4000, using Azagaurd (every 5 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-4000, using Azagaurd (every 5 days until pressure subsides or stop by day 18 flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3000ml" },
          { name: "Azagaurd", value: "500ml" },
          { name: "H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
    },
    "3": {
      "25000": {
        title: "Pro-4000, using Azamax (every 7 days until pressure subsides or stop by day 18)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3480ml" },
          { name: "Azamax", value: "360ml" },
          { name: "R/O H2O", value: "60ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azamax (every 7 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "4": {
      "25000": {
        title: "Pro-4000, using Azamax (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-4000, using Azamax (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Azamax", value: "950ml" },
          { name: "R/O H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 0,
        reactiveDays: 5,
      },
      
    },
    "5": {
      "25000": {
        title: "Pro-4000, using Azatin (every 7 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3225ml" },
          { name: "Azatin", value: "200ml" },
          { name: "R/O H2O", value: "75ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Azatin (every 7 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
    },
    "6": {
      "25000": {
        title: "Pro-4000, using Azatin (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3000ml" },
          { name: "Azamax", value: "500ml" },
          { name: "R/O H2O", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-4000, using Azatin (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
   
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },
    "7": {
      "25000": {
        title: "Pro-4000, using Suffoil (every 5 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3125ml" },
          { name: "Suffoil-x", value: "375ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      // not recommended
      "50000": {
        title: "Pro-4000, using Suffoil (every 5 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "8": {
      "25000": {
        title: "Pro-4000, using Suffoil (every 3 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Suffoil-x", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },

        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Suffoil Suffoil (every 3 days till pressure subsides or stop use by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "9": {
      "25000": {
        title: "Pro-4000, using Tritek (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Tri-Tek", value: "250ml" },
          { name: "ISO", value: "25ml" },
          { name: "PH", value: "5.8-6.2" },

        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Tritek (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "10": {
      "25000": {
        title: "Pro-4000, using Tritek (every 3 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2250ml" },
          { name: "Tri-Tek", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },

        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Tritek (every 3 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "11": {
      "25000": {
        title: "Pro-4000, using Big Time Exterminator (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3100ml" },
          { name: "Big Time", value: "400ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },

        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Big Time Exterminator (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
         
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "12": {
      "25000": {
        title: "Pro-4000, using Big Time Exterminator (every 3 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Big Time", value: "950ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },

        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Big Time Exterminator (every 3 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "13": {
      "25000": {
        title: "Pro-4000, using Smite (every 7 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3200ml" },
          { name: "Smite", value: "300ml" },
          { name: "ISO", value: "100ml" },
          { name: "PH", value: "5.8-6.2" },

        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Smite (every 7 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 7,
        reactiveDays: 0,
      },
  
    },
    "14": {
      "25000": {
        title: "Pro-4000, using Smite (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Smite", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-4000, using Smite (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },

    "15": {
      "25000": {
        title: "Pro-4000, using SNS 203 (every 3 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3100ml" },
          { name: "SNS 203", value: "400ml" },
          { name: "ISO", value: "400ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using SNS 203 (every 3 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 0,
      },
    },
    "16": {
      "25000": {
        title: "Pro-4000, using SNS 203 (every 2 days. stop by day 18 of flower.  No more than 5 applications per crop cycle)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "SNS 203", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "Pro-4000, using SNS 203 (every 2 days. stop by day 18 of flower.  No more than 5 applications per crop cycle)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
    },
    "17": {
      "25000": {
        title: "Pro-4000, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3200ml" },
          { name: "BioControl", value: "300ml" },
          { name: "R/O ISO", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },

      "50000": {
        title: "Pro-4000, using Mammoth Biocontrol (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "18": {
      "25000": {
        title: "Pro-4000, using Mammoth Biocontrol (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "BioControl", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Mammoth Biocontrol (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 0,
        reactiveDays: 3,
      },
    },


    "19": {
      "25000": {
        title: "Pro-4000, using Mammoth CannControl (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "CannControl", value: "300ml" },
          { name: "R/O ISO", value: "50ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Mammoth CannControl (every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
    
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "20": {
      "25000": {
        title: "Pro-4000, using Mammoth CannControl (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "CannControl", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Mammoth CannControl (every 3 days till pressure subsides ,can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "21": {
      "25000": {
        title: "Pro-4000, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Athena IPM", value: "300ml" },
          { name: "ISO", value: "50ml" },
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
      "50000": {
        title: "Pro-4000, using Athena IPM (apply daily with a day break every 3 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
    },
    "22": {
      "25000": {
        title: "Pro-4000, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Athena IPM", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
      "50000": {
        title: "Pro-4000, using Athena IPM (apply daily with a day break every 5 days)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 1,
        reactiveDays: 1,
      },
    },
    "23": {
      "25000": {
        title: "Pro-4000, using Growsafe (every 5 days then stop by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3200ml" },
          { name: "Growsafe", value: "300ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Growsafe (every 5 days then stop by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "24": {
      "25000": {
        title: "Pro-4000, using Growsafe T(every 3 days then stop by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
            { name: "Halo", value: "2550ml" },
            { name: "Growsafe", value: "950ml" },
            { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Growsafe T(every 3 days then stop by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "25": {
      "25000": {
        title: "Pro-4000, using Wizards Brew Concentrate (every 5 days then stop by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Wizard Brew", value: "250ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Wizards Brew Concentrate (every 5 days then stop by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "26": {
      "25000": {
        title: "Pro-4000, using Wizards Brew Concentrate (every 3 days then stop by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
            { name: "Halo", value: "2550ml" },
            { name: "Wizard Brew", value: "950ml" },
            { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Wizards Brew Concentrate (every 3 days then stop by day 18 of flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "27": {
      "25000": {
        title: "Pro-4000, using TetraCurb (every 5 days and can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          // { name: "Halo", value: "1175ml" },
          // { name: "Tetra Curb", value: "50ml" },
          // { name: "ISO", value: "25ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using TetraCurb (every 5 days and can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "28": {
      "25000": {
        title: "Pro-4000, using TetraCurb (every 3 days and can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "875ml" },
          { name: "Tetra Curb", value: "300ml" },
          { name: "ISO", value: "75ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using TetraCurb (every 3 days and can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "29": {
      "25000": {
        title: "Pro-4000, using Organishield (every 10 days no more than 6 applications per crop cycle)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Organishield", value: "250ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Organishield (every 10 days no more than 6 applications per crop cycle)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 10,
        reactiveDays: 0,
      },
    },
    "30": {
      "25000": {
        title: "Pro-4000, using Organishield (every 5 days no more than 6 applications per crop cycle)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Organishield", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
      "50000": {
        title: "Pro-4000, using Organishield (every 5 days no more than 6 applications per crop cycle)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 5,
      },
    },

    "31": {
      "25000": {
        title: "Pro-4000, using Plant Therapy (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Plant Therapy", value: "250ml" },
          { name: "H2O", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Plant Therapy (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "32": {
      "25000": {
        title: "Pro-4000, using Plant Therapy (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
      
          { name: "Halo", value: "2550ml" },
          { name: "Plant Therapy", value: "950ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Plant Therapy (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "33": {
      "25000": {
        title: "Pro-4000, using Green Cleaner (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Green Cleaner", value: "250ml" },
          { name: "H2O", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Green Cleaner (every 5 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [

        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "34": {
      "25000": {
        title: "Pro-4000, using Green Cleaner Green Cleaner (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
         
          { name: "Halo", value: "2550ml" },
          { name: "Green Cleaner", value: "950ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Green Cleaner Green Cleaner (every 3 days can be used in late flower ,use as many times in a cycle until you see signs of damaged plants)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "35": {
      "25000": {
        title: "Pro-4000, using Dr. Zymes (every 5 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Dr Zymes", value: "250ml" },
          { name: "H2O", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Dr. Zymes (every 5 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "36": {
      "25000": {
        title: "Pro-4000, using Dr. Zymes (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
         
          { name: "Halo", value: "2550ml" },
          { name: "Dr. Zymes", value: "950ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Dr. Zymes (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [

        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "37": {
      "25000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3100ml" },
          { name: "Nuke em", value: "400ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "38": {
      "25000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Nuke em", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
      "50000": {
        title: "Pro-4000, using Flying Skull Nuke Em (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 2,
        reactiveDays: 2,
      },
    },
    "39": {
      "25000": {
        title: "Pro-4000, using Dr. Bronners Peppermint Dr. Bronners Peppermint (every 5 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3125ml" },
          { name: "Dr Bronners", value: "375ml" },
          { name: "H2O", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Dr. Bronners Peppermint Dr. Bronners Peppermint (every 5 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "40": {
      "25000": {
        title: "Pro-4000, using Dr. Bronners Peppermint (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Dr. Bronners", value: "950ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Dr. Bronners Peppermint (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "41": {
      "25000": {
        title: "Pro-4000, using Mpede (every 5 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3125ml" },
          { name: "Mpede", value: "375ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
      "50000": {
        title: "Pro-4000, using Mpede (every 5 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 5,
        reactiveDays: 0,
      },
    },
    "42": {
      "25000": {
        title: "Pro-4000, using Mpede (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Mpede", value: "950ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Mpede (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },



    "46": {
      "25000": {
        title: "Pro-4000, using Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Cpt. Jacks", value: "250ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Pro-4000, using Captain Jacks w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "47": {
      "25000": {
        title: "Pro-4000, using Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Cpt. Jacks", value: "950ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Captain Jacks w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "48": {
      "25000": {
        title: "Pro-4000, using Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3250ml" },
          { name: "Monterey", value: "250ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Monterey w/Spinosad (can be used every 5 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "49": {
      "25000": {
        title: "Pro-4000, using Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Monterey", value: "950ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Monterey w/Spinosad (can be used every 3 days no more than 6 applications per crop cycle and should be used in vegetative phase)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },
    "50": {
      "25000": {
        title: "Pro-4000, using Circadian Sunrise (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "3100ml" },
          { name: "Circ. Sun", value: "400ml" },
          { name: "ISO", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Circadian Sunrise (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "51": {
      "25000": {
        title: "Pro-4000, using Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Circ. Sun", value: "950ml" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Circadian Sunrise (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    // dsafljkjsfd
    "52": {
      "25000": {
        title: "Pro-4000, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "1050ml" },
          { name: "Circ. Sun", value: "90ml" },
          { name: "Allphase", value: "7gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    //sdfjalfjkl
    "53": {
      "25000": {
        title: "Pro-4000, using Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
          { name: "Halo", value: "2550ml" },
          { name: "Circ. Sun", value: "850ml" },
          { name: "Allphase", value: "100gr" },
          { name: "H20", value: "100ml" },
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
      "50000": {
        title: "Pro-4000, using Circadian Sunrise + Allphase (can be used daily however need to stop using when signs of damage to the plants and can be used in late flower)",
        sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
        mixture: [
        ],
        preventativeDays: 3,
        reactiveDays: 3,
      },
    },

    "54": {
    "25000": {
      title: "Pro-4000, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "3100ml" },
        { name: "Azaguard", value: "200ml" },
        { name: "Zerotol", value: "100ml" },
        { name: "Oxiphos", value: "100ml" },
        { name: "R/O H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 7,
    },
  },

  "55": {
    "25000": {
      title: "Pro-4000, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "2250ml" },
        { name: "Azaguard", value: "500ml" },
        { name: "Zerotol", value: "225ml" },
        { name: "Oxiphos", value: "225ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Azaguard + Zerotol + Oxiphos (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 7,
    },
  },



  "56": {
    "25000": {
      title: "Pro-4000, using Neutra-HOCL tablet (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [

      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Neutra-HOCL tablet (every 7 days under pressure ,stop by day 21 of flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [

      ],
        preventativeDays: 7,
      reactiveDays: 7,
    },
  },


  "57": {
    "25000": {
      title: "Pro-4000, using Regalia Maxx (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
            { name: "Halo", value: "3000ml" },
        { name: "Regalia CG", value: "500ml" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Regalia Maxx (every 7 days under pressure can be used in late flower but recommended to stop using in day 21)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
     

      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  "58": {
    "25000": {
      title: "Pro-4000, using Regalia Maxx (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
          { name: "Halo", value: "2550ml" },
        { name: "Regalia CG", value: "950ml" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Regalia Maxx (every 5 days under pressure can be used in late flower but recommended to stop using in day 20)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
       
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  //min put here
  "59": {
    "25000": {
      title: "Pro-4000, using Procidic2 (every 5 days. can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
           { name: "Halo", value: "3250ml" },
        { name: "Procidic2", value: "250ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Procidic2 (every 3 days. can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },



  "60": {
    "25000": {
      title: "Pro-4000, using Procidic2 (every 3 days, can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "2550ml" },
        { name: "Procidic2", value: "950ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Procidic2 (every 3 days, can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },


  //min, Pro-4000
  "61": {
    "25000": {
      title: "Pro-4000, using Zerotol 2.0 (every 3 days under pressure can be used up to the day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "3200ml" },
        { name: "Zerotol", value: "300ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },


  //should be good!, not good
  "62": {
    "25000": {
      title: "Pro-4000, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "2900ml" },
        { name: "Zerotol", value: "600ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Zerotol (every 2 days under pressure can be used up to the day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },


  "63": {
    "25000": {
      title: "Pro-4000, using Oxiphos (every 7 days under pressure stop using by day 14 of flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "2550ml" },
        { name: "Oxiphos", value: "600ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 7,
      reactiveDays: 7,
    },
    "50000": {
      title: "Pro-4000, using Oxiphos (every 7 days under pressure stop using by day 14 of flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },


  //min
  "64": {
    "25000": {
      title: "Pro-4000, using Dr Zymes (every 3 days under pressure,  can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
          { name: "Halo", value: "3250ml" },
        { name: "Dr. Zymes", value: "250ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Dr Zymes (every 3 days under pressure,  can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  //should be good
  "65": {
    "25000": {
      title: "Pro-4000, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "2550ml" },
        { name: "Dr. Zymes", value: "950ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Dr. Zymes (high pressure apply 3 days consecutively then stop and apply 7 days later.  after 3 days repeat pattern until pressure subsides, can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

//min
  "66": {
    "25000": {
      title: "Pro-4000 using Flying Skull Nuke em ( every 3 days, can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
          { name: "Halo", value: "3100ml" },
        { name: "Nuke em", value: "400ml" },
        { name: "ISO", value: "100ml" },
      ],
      preventativeDays: 2,
      reactiveDays: 2,
    },
    "50000": {
      title: "Pro-4000, using Flying Skull Nuke em ( every 3 days, can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },

  "67": {
    "25000": {
      title: "Pro-4000, using Flying Skull Nuke em (every 2 days, can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
  
      ],
      preventativeDays: 2,
      reactiveDays: 2,
    },
    "50000": {
      title: "Pro-4000, using Flying Skull Nuke em (every 2 days, can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },

  //min, if it is remove
  "68": {
    "25000": {
      title: "Pro-4000, using Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "875ml" },
        { name: "Wizard Brew", value: "300ml" },
        { name: "ISO", value: "75ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 5,
    },
    "50000": {
      title: "Pro-4000, using Wizards Brew Concentrate (every 5 days under pressure can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
         
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  //min
  "69": {
    "25000": {
      title: "Pro-4000, using Pure Crop1 (every 5 days can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
          { name: "Halo", value: "3100ml" },
        { name: "Pure Crop 1", value: "400ml" },
        { name: "ISO", value: "100ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 5,
    },
    "50000": {
      title: "Pro-4000, using Pure Crop1 (every 5 days can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },

  
  "70": {
    "25000": {
      title: "Pro-4000, using Pure Crop1 (every 3 days can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "2550ml" },
        { name: "Pure Crop 1", value: "950ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 5,
      reactiveDays: 5,
    },
    "50000": {
      title: "Pro-4000, using Pure Crop1 (every 3 days can be used up to day of harvest)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 5,
      reactiveDays: 5,
    },
  },


  //min
  "71": {
    "25000": {
      title: "Pro-4000, using Circadian Sunrise + Allphase (every 5 days can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
          { name: "Halo", value: "3100ml" },
        { name: "Circ. Sun", value: "400ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Circadian Sunrise + Allphase (every 5 days can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  "72": {
    "25000": {
      title: "Pro-4000, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Halo", value: "2550ml" },
        { name: "Circ. Sun", value: "950ml" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Circadian Sunrise + Allphase (every 3 days can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },


  //min
  "73": {
    "25000": {
      title: "Pro-4000, using Allphase (every 2 days or as needed can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [

      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Allphase (every 2 days or as needed can be used in late flower)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [

      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  "74": {
    "25000": {
      title: "Pro-4000, using Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        
      ],
      preventativeDays: 2,
      reactiveDays: 2,
    },
    "50000": {
      title: "Pro-4000, using Grandevo CG (2 times per week can be used in late flower) Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
                 { name: "Aura", value: "1075lml" },
        { name: "Grandevo CG", value: "5 TBSP" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
        preventativeDays: 2,
      reactiveDays: 2,
    },
  },


  //min
  "75": {
    "25000": {
      title: "Pro-4000, using Grandevo WDG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Grandevo WDG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [

      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },

  "76": {
    "25000": {
      title: "Pro-4000, using Venerate CG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
        { name: "Aura", value: "1010ml" },
        { name: "Venerate CG", value: "10 TBSP" },
        { name: "H20", value: "100ml" },
        { name: "PH", value: "6.0" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Venerate CG (2 times per week can be used in late flower)Aura to be used as wetting agent/carrier not Halo",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },
"77": {
  "25000": {
    title: "Pro-4000, using Power si Original (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "1200ml" },
      { name: "Power si", value: "12ml" },
      { name: "H2O", value: "38ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 7,
    reactiveDays: 0,
  },
  "50000": {
    title: "Pro-4000, using Power si Original (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 7,
    reactiveDays: 0,
  },
},
"78": {
  "25000": {
    title: "Pro-4000, using Power si Bloom (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
     { name: "Halo", value: "1200ml" },
      { name: "Power si", value: "24ml" },
      { name: "H2O", value: "26ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 5,
    reactiveDays: 0,
  },
  "50000": {
    title: "Pro-4000, using Power si Bloom (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 5,
    reactiveDays: 0,
  },
},

//athena stack, input required
"81": {
  "25000": {
    title: "Pro-4000, using Athena Stack (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
    mixture: [
        { name: "Halo", value: "1150ml" },
      { name: "Athena Stack", value: "50ml" },
      { name: "H20", value: "50ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 3,
    reactiveDays: 3,
  },
  "50000": {
    title: "Pro-4000, using Athena Stack (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
    mixture: [
    ],
      preventativeDays: 3,
    reactiveDays: 3,
  },

},

   // input required

"82": {
  "25000": {
    title: "Pro-4000, using Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "1150ml" },
      { name: "Nitrozyme", value: "50ml" },
      { name: "H2O", value: "50ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 7,
    reactiveDays: 0,
  },
  "50000": {
    title: "Pro-4000, using Europonic Nitrozime (1 to 2 times per week depending on the health of the plant)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 7,
    reactiveDays: 0,
  },
},

 // input required
"83": {
"25000": {
  title: "Pro-4000, using CES Foliar (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1000ml" },
    { name: "Bproof si", value: "30ml" },
    { name: "Mag Ampd", value: "30ml" },
    { name: "UJB", value: "60ml" },
    { name: "Plant Ampd", value: "60ml" },
    { name: "H2O", value: "70ml" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "Pro-4000, using CES Foliar (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},

},

 // input required
"84": {
"25000": {
  title: "Pro-4000, using B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1150ml" },
    { name: "B1 Thrive", value: "75ml" },
    { name: "H2O", value: "25ml" },
    { name: "PH", value: "5.8-6.2" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "Pro-4000, using B1 Thrive Alive Green & Red (1 to 2 times per week depending on the health of the plant)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 7,
  reactiveDays: 0,
},
},
//up to europonic nitrozine, need to alter, if there is no max. 

// need to review
"85": {
    "25000": {
      title: "Pro-4000, using Humboldt Humic (1 to 2 times per week depending on the health of the plant)",
      sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
      mixture: [
        { name: "Halo", value: "1200ml" },
        { name: "Humboldt Humic", value: "12.5ml" },
        { name: "H2O", value: "37.5ml" },
        { name: "PH", value: "5.8-6.2" },
      ],
      preventativeDays: 7,
      reactiveDays: 0,
    },
    "50000": {
      title: "Pro-4000, using Humboldt Humic (1 to 2 times per week depending on the health of the plant)",
      sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
      mixture: [],
      preventativeDays: 7,
      reactiveDays: 0,
    },
  },


// will need to review
"86": {
"25000": {
  title: "Pro-4000, using Magic Green (every 7 days)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [
    { name: "Halo", value: "1150ml" },
    { name: "Magic Green", value: "60ml" },
    { name: "H2O", value: "40ml" },
    { name: "PH", value: "5.8-6.2" },
  ],
  preventativeDays: 7,
  reactiveDays: 0,
},
"50000": {
  title: "Pro-4000, using Magic Green (every 7 days)",
  sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
  mixture: [],
  preventativeDays: 7,
  reactiveDays: 0,
},
},

"87": {
  "25000": {
    title: "Pro-4000, using Transport + Watts (every 7 days)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [
      { name: "Halo", value: "950ml" },
      { name: "Transport", value: "30ml" },
      { name: "Rev", value: "30ml" },
      { name: "Watts", value: "30ml" },
      { name: "H2O", value: "60ml" },
      { name: "PH", value: "5.8-6.2" },
    ],
    preventativeDays: 7,
    reactiveDays: 0,
  },
  "50000": {
    title: "Pro-4000, using Transport + Watts (every 7 days)",
    sizeArea: ["25,000 Sq. Ft.", "50,000 Sq. Ft."],
    mixture: [],
    preventativeDays: 7,
    reactiveDays: 0,
  },
},





  "88": {
    "25000": {
      title: "Pro-4000, using Neutra Fog HOCL (2 times per week)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
          { name: "Halo", value: "3475ml" },
        { name: "Neutra-HOCL", value: "3 Tablets (HOCL mix should be @ 300ppm-600ppm)" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Neutra Fog HOCL  (2 times per week)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },



     "89": {
    "25000": {
      title: "Pro-4000, using Sanidate5.0 (2 times per week)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
          { name: "Halo", value: "3240ml" },
        { name: "Sanidate", value: "260ml (Sanidate mix should be @ 100ppm-600ppm)" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Sanidate5.0 (2 times per week)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
  },



      "90": {
    "25000": {
      title: "Pro-4000, using Athena Cleanse (2 times per week)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
          { name: "Halo", value: "3000ml" },
        { name: "Athena Cleanse", value: "500ml (Athena Cleanse mix should be @ 300ppm-600ppm)" },
        { name: "H20", value: "100ml" },
      ],
      preventativeDays: 3,
      reactiveDays: 3,
    },
    "50000": {
      title: "Pro-4000, using Athena Cleanse (2 times per week)",
      sizeArea: ["Max 200,000 Sq. Ft. ", "Max 800,000 Sq. Ft. "],
      mixture: [
      ],
        preventativeDays: 3,
      reactiveDays: 3,
    },
},

  }
}




// export const getResultData = (mixture: string, dropdownOption: string, areaSize: string): ResultData => {
//   const mixtureId = mixture.includes("7 days") ? "1" : "2";
//   return resultDataMap[dropdownOption][mixtureId][areaSize];
// };





export const getResultData = (mixture: string, dropdownOption: string, areaSize: string): ResultData => {
  // Find the matching mixture ID from the tableData
  const mixtureItem = dummyData.tableData.find(item => item.name === mixture);
  
  if (mixtureItem && resultDataMap[dropdownOption]) {
    // Find the correct key in resultDataMap[dropdownOption] that matches the mixture ID
    const matchingKey = Object.keys(resultDataMap[dropdownOption]).find(key => {
      const keyNumber = parseInt(key);
      return keyNumber === mixtureItem.id;
    });

    if (matchingKey) {
      const data = resultDataMap[dropdownOption][matchingKey][areaSize];
      
      // If data for the specific area size exists, return it
      if (data) {
        return data;
      }
    }
  }
  
  // Fallback message when no data is available
  return getFallbackData();
};

const getFallbackData = (): ResultData => ({
  title: "No data available, please contact Neutrafog for further recommendation",
  sizeArea: ["Unavailable", "Unavailable"], // Default size areas
  mixture: [{ name: "Recommendation", value: "Please contact Neutrafog at support@neutrafog.com" }],
  preventativeDays: 0,
  reactiveDays: 0,
});




// export const getResultData = (mixture: string, dropdownOption: string, areaSize: string): ResultData => {
//   const mixtureId = mixture.includes("7 days") ? "1" : "2";
  
//   if (resultDataMap[dropdownOption] && 
//       resultDataMap[dropdownOption][mixtureId]) {
    
//     const data25k = resultDataMap[dropdownOption][mixtureId]["25000"];
//     const data50k = resultDataMap[dropdownOption][mixtureId]["50000"];

//     // If both sizes have data, return the requested size
//     if (data25k && data50k) {
//       return resultDataMap[dropdownOption][mixtureId][areaSize];
//     }
    
//     // If either size is missing, return the fallback
//     if (!data25k || !data50k) {
//       return getFallbackData();
//     }
//   }
  
//   // Fallback message when no data is available
//   return getFallbackData();
// };

// const getFallbackData = (): ResultData => ({
//   title: "No data available, please contact Neutrafog for further recommendation",
//   sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."], // Default size areas
//   mixture: [{ name: "Recommendation", value: "Please contact Neutrafog at support@neutrafog.com" }],
//   preventativeDays: 0,
//   reactiveDays: 0,
// });







// this version works but gets weird click both
// export const getResultData = (mixture: string, dropdownOption: string, areaSize: string): ResultData => {
//   const mixtureId = mixture.includes("7 days") ? "1" : "2";
  
//   if (resultDataMap[dropdownOption] && 
//       resultDataMap[dropdownOption][mixtureId] && 
//       resultDataMap[dropdownOption][mixtureId][areaSize]) {
//     return resultDataMap[dropdownOption][mixtureId][areaSize];
//   } else {
//     // Fallback message when no data is available
//     return {
//       title: "No data available",
//       sizeArea: ["25,000 Cubic Ft.", "50,000 Cubic Ft."], // Default size areas
//       mixture: [{ name: "Recommendation", value: "Please contact Neutrafog at support@neutrafog.com for further recommendation." }],
//       preventativeDays: 0,
//       reactiveDays: 0,
//     };
//   }
// };

export default dummyData;

import React from 'react'
import styles from './footer.module.css'

interface LinkItem {
  text: string;
  url: string;
}

interface LinkColumnProps {
  links: LinkItem[]
  extra?: string
}

const LinkColumn: React.FC<LinkColumnProps> = ({ links, extra }) => (
  <div className={styles.linkColumn}>
    <div className={styles.navLink}>{extra}</div>
    <div className={styles.linkList}>
      {links.map((link, index) => (
        <a key={index} href={link.url} className={styles.link}>
          {link.text}
        </a>
      ))}
    </div>
  </div>
)

const FooterMobile: React.FC = () => {
  const exploreLinks: LinkItem[] = [
    { text: 'about us', url: '/about' },
    { text: 'our systems', url: '/our-systems' },
    { text: 'iPM products', url: '/ipm-solutions' },
    { text: 'subscriptions', url: '/subscriptions' },
    { text: 'resources', url: '/resources' },
  ]
  const additionalLinks: LinkItem[] = [
    { text: 'careers', url: '/careers' },
    { text: 'contact us', url: '/contact' },
  ]

  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.contentWrapper}>
          <section className={styles.logoSection}>
            <div className={styles.logoContent}>
              <div className={styles.logoColumn}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e45f9f9ebad6357993d8aec63e6207917f81762afb221741633a6bc745aebaf?apiKey=7d740121674b4225a077dc91176b5085&"
                  alt="NeutraFog Logo"
                  className={styles.logo}
                />
              </div>
              <div className={styles.descriptionColumn}>
                <div className={styles.descriptionContent}>
                  <span className={styles.description}>
                  Empowering sustainable greenhouse management with cutting-edge IPM solutions.
                  </span>
                  <div className={styles.legalLinks}>
                  <a href="/terms-of-service"> Terms of Service </a> | <a href="/privacy-policy"> Privacy Policy </a>
                  </div>
                  <section className={styles.linksSection}>
            <div className={styles.linksContent}>
              <LinkColumn links={exploreLinks} extra={'explore neutrafog'} />
              <LinkColumn links={additionalLinks} extra={'additional links'} />
              <div className={`${styles.contactColumn} ${styles.paddedColumn}`}>
                <div className={styles.navLink}>contact information</div>
                <address className={styles.contactInfo}>
                  <span className={styles.address}>
                    15300 Valley View Ave , Unit E14, La Mirada , CA 90639
                  </span>
                  <p className={styles.hours}>8:30am-4:30pm PST</p>
                  <a
                    href="mailto:support@neutrafog.com"
                    className={styles.email}
                  >
                    support@neutrafog.com
                  </a>
                  <a href="mailto:sales@neutrafog.com" className={styles.email}>
                    sales@neutrafog.com
                  </a>
                  <div className={styles.socialIcons}>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/bac228519eca05b01538bd327e7038fa8750a1f61b1825af4519bde62f56d281?apiKey=7d740121674b4225a077dc91176b5085&"
                      alt="Social Media Icon"
                      className={styles.socialIcon}
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/0bf024e200a4e8914cdee5b8c22e0b336e8f96385596dc8e0bec67cb6db7e805?apiKey=7d740121674b4225a077dc91176b5085&"
                      alt="Social Media Icon"
                      className={styles.socialIcon}
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a9a2779b2b7ab65168c7fc09a67e9f42a7478a023fe7bc550ffa64d76dbe079?apiKey=7d740121674b4225a077dc91176b5085&"
                      alt="Social Media Icon"
                      className={styles.socialIcon}
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b3379036f26ab5436d5f7b9b691d2f96a93c33cbaad88a45cf436d5aa320462?apiKey=7d740121674b4225a077dc91176b5085&"
                      alt="Social Media Icon"
                      className={styles.socialIcon}
                    />
                  </div>
                </address>
              </div>
            </div>
          </section>
                  <div className={styles.copyright}>
                  © NeutraFog 2024. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </footer>
  )
}

export default FooterMobile

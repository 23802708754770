import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import FormField from './FormField'
import Dropdown from './Dropdown'
import cx from 'classnames'
import useCallForm from './useCallForm'

const SalesForm: React.FC = () => {
  const { formData, submitted, handleChange, handleSubmit, resetForm } =
    useCallForm()

  const clickSubmit = () => {
    console.log(formData)
    handleSubmit()
  }

  const [timer, setTimer] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimer(!timer)
    }, 300);
  }, [])


  return (
    <div className={cx(styles.formRoot, timer && styles.formActive)}>
      {/* <div className={styles.phoneNumber}>phone: 222-334-4556</div> */}
      <hr className={styles.divider} />
      <form className={cx(styles.formMain)}>
        <div className={styles.formRow}>
          <FormField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          <FormField
            label="Last Name"
            name={'lastName'}
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>

        <div className={styles.formRow}>
          <FormField
            label="Email Address"
            name={'email'}
            value={formData.email}
            onChange={handleChange}
          />
          <FormField
            label="Phone Number"
            name={'phoneNumber'}
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>

        <Dropdown
          label="Location/State:"
          name={'location'}
          value={formData.location}
          onChange={handleChange}
        />
        <Dropdown
          label="Interested in:"
          name={'interestedIn'}
          value={formData.interestedIn}
          onChange={handleChange}
        />

        <div className={styles.msgInput}>
          <FormField
            label="Message:"
            className={styles.formInput}
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <div className={styles.submitContainer}>
          <div>submit</div>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/dfb4ffd7ff70fa3c12dd8eca1ae72ef327b148fe9588aa3a65701e4daee9af40?apiKey=7d740121674b4225a077dc91176b5085&"
            alt=""
            className={styles.submitIcon}
            onClick={clickSubmit}
          />
        </div>
      </form>
    </div>
  )
}

export default SalesForm

/**
 * This code was generated by Builder.io.
 */
import React from 'react'
import styles from './OurSystems.module.css'
import { SharedNav } from "../SharedNav";
const OurSystems: React.FC = () => {
  return (
    <main className={styles.container}>
      <SharedNav />
      <header className={styles.header}>
        <section className={styles.titleSection}>
          <h1 className={styles.title}>Our Systems.</h1>
          <div className={styles.contentRow} >
            <p className={styles.description}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
              massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
              fringilla, mattis ligula.
            </p>
            <div className={styles.exploreSection}>
              <span className={styles.exploreText}>explore systems</span>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/001868d636fad78718d5389e5f22892ff87486ccf71f11d706a56c90bbc1049a?placeholderIfAbsent=true&apiKey=7d740121674b4225a077dc91176b5085"
                alt=""
                className={styles.exploreIcon}
              />
            </div>
          </div>
        </section>
      </header>
      <hr className={styles.divider} />
      <section className={styles.imageSection}>
        <div className={styles.iframeRoot} >
          <iframe
          className={styles.iframe}
          src="https://www.youtube.com/embed/ib2bHw3exFk?si=xCKYiky2vKLexCrq"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          ></iframe>
        </div>
      </section>
    </main>
  )
}

export default OurSystems

"use client";
import React from 'react';
import style from './sop.module.css'
import { Button } from "@trycreo/ui/button";

interface SubmitButtonProps {
  onClick: () => void;
}

export function SubmitButton({ onClick }: SubmitButtonProps) {
  return (
    <div className={style.restartBtn} >
      <Button onClick={onClick} className="w-full">
        Generate Feed Results
      </Button>
    </div>
  );
}

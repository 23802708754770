// ProductPageMobile.js
import React from 'react';
import Image from 'next/image';
import { Menu, X } from 'lucide-react';
import styles from './ProductPageMobile.module.css';

const ProductPageMobile = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Image src="/neutrafog-logo.png" alt="NeutraFog Logo" width={40} height={40} />
        <button className={styles.menuButton} onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <X /> : <Menu />}
        </button>
      </header>

      {menuOpen && (
        <nav className={styles.mobileMenu}>
          <a href="#" className={styles.menuItem}>Home</a>
          <a href="#" className={styles.menuItem}>Products</a>
          <a href="#" className={styles.menuItem}>About</a>
          <a href="#" className={styles.menuItem}>Contact</a>
        </nav>
      )}

      <main className={styles.main}>
        <section className={styles.productSection}>
          <h1 className={styles.productTitle}>Pro - 1500 WiFi</h1>
          <p className={styles.productPrice}>$1,234.56</p>
          <div className={styles.imageContainer}>
            <Image src="/ipm.png" alt="Product Image" layout="fill" objectFit="cover" />
          </div>
          <p className={styles.productDescription}>
           <b> Product Description:</b>
           <br/> <br/>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.
          </p>
          <button className={styles.quoteButton}>Contact For A Quote</button>
        </section>

        <section className={styles.additionalInfo}>
          <h2 className={styles.sectionTitle}>Additional Product Information</h2>

          <div className={styles.shareButtons}>
  <button className={styles.shareButton}>
    <Image src='/social_icons/share_facebook.png' alt="Share on Facebook" width={50} height={50} />
  </button>
  <button className={styles.shareButton}>
    <Image src="/social_icons/share_x.png" alt="Share on Twitter" width={50} height={50} />
  </button>
  <button className={styles.shareButton}>
    <Image src="/social_icons/share_pinterest.png" alt="Share on Pinterest" width={50} height={50} />
  </button>
</div>
          {/* <div className={styles.shareButtons}>
            <button className={styles.shareButton}>f</button>
            <button className={styles.shareButton}>t</button>
            <button className={styles.shareButton}>p</button>

            
          </div> */}
          <div className={styles.accordion}>
            <details className={styles.accordionItem}>
              <summary className={styles.accordionHeader}>General Specs</summary>
              <div className={styles.accordionContent}>
                General specs content goes here.
              </div>
            </details>
            <details className={styles.accordionItem}>
              <summary className={styles.accordionHeader}>Fog Generator</summary>
              <div className={styles.accordionContent}>
                Fog generator content goes here.
              </div>
            </details>
            <details className={styles.accordionItem}>
              <summary className={styles.accordionHeader}>Electrical Specification</summary>
              <div className={styles.accordionContent}>
                Electrical specification content goes here.
              </div>
            </details>
            <details className={styles.accordionItem}>
              <summary className={styles.accordionHeader}>Standards</summary>
              <div className={styles.accordionContent}>
                Standards content goes here.
              </div>
            </details>
            <details className={styles.accordionItem}>
              <summary className={styles.accordionHeader}>System Requirements</summary>
              <div className={styles.accordionContent}>
                System requirements content goes here.
              </div>
            </details>
          </div>
        </section>

        <section className={styles.systemDetails}>
          <h2 className={styles.sectionTitle}>Take A Closer Look At System Details</h2>
          <div className={styles.featureList}>
            <div className={styles.featureItem}>
              <Image src="/feature-icon.png" alt="Feature Icon" width={24} height={24} />
              <h3 className={styles.featureTitle}>Powerful</h3>
              <p className={styles.featureDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.</p>
            </div>
            <div className={styles.featureItem}>
              <Image src="/feature-icon.png" alt="Feature Icon" width={24} height={24} />
              <h3 className={styles.featureTitle}>Specially Formulated</h3>
              <p className={styles.featureDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.</p>
            </div>
            <div className={styles.featureItem}>
              <Image src="/feature-icon.png" alt="Feature Icon" width={24} height={24} />
              <h3 className={styles.featureTitle}>Our Fluid Bags</h3>
              <p className={styles.featureDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.</p>
            </div>
            <div className={styles.featureItem}>
              <Image src="/feature-icon.png" alt="Feature Icon" width={24} height={24} />
              <h3 className={styles.featureTitle}>Reduced Power Consumption</h3>
              <p className={styles.featureDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.</p>
            </div>
            <div className={styles.featureItem}>
              <Image src="/feature-icon.png" alt="Feature Icon" width={24} height={24} />
              <h3 className={styles.featureTitle}>Remote Access</h3>
              <p className={styles.featureDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.</p>
            </div>
            <div className={styles.featureItem}>
              <Image src="/feature-icon.png" alt="Feature Icon" width={24} height={24} />
              <h3 className={styles.featureTitle}>Feature</h3>
              <p className={styles.featureDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.</p>
            </div>
          </div>
          <div className={styles.greenhouseImage}>
            <Image src="/greenhouse.jpg" alt="Greenhouse" layout="fill" objectFit="cover" />
          </div>
        </section>
      </main>

      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <p>© 2023 NeutraFog Systems, Inc. NeutraFog</p>
          <div className={styles.footerLinks}>
            <a href="#" className={styles.footerLink}>Terms & Conditions</a>
            <a href="#" className={styles.footerLink}>Privacy Policy</a>
          </div>
          <div className={styles.contactInfo}>
            <p>12345 Valley View Ave, Suite 5678</p>
            <p>Anytown, CA 90210</p>
            <p>support@neutrafog.com</p>
          </div>
          <div className={styles.socialLinks}>
            <a href="#" className={styles.socialLink}>f</a>
            <a href="#" className={styles.socialLink}>in</a>
            <a href="#" className={styles.socialLink}>t</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ProductPageMobile;
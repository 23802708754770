import Image from 'next/image'
// import { Button } from '@/components/ui/button'
import { Button } from "@trycreo/ui/button";
import styles from './ProductGrid.module.css'

const products = [
  {
    id: 1,
    name: 'T-250 System',
    price: 3459.00,
    image: '/products/T250.png?height=180',
    url: '/t-250'

    // image: '/placeholder.svg?height=200&width=200'

  },
  {
    id: 2,
    name: 'T-500 System',
    price: 5839.00,
    image: '/products/T500.png',
    url: '/t-500'

  },
  {
    id: 3,
    name: 'Pro-1500 Wifi',
    price: 8239.00,
    image: '/products/Pro1500.png',
    url: '/pro-1500'

  },
  {
    id: 4,
    name: 'Pro-4000 Wifi',
    price: 10189.00,
    image: '/products/Pro4000.png',
    url: '/pro-4000'

  }
]

export default function ProductGrid() {
  return (
    <div className={styles.grid}>
      {products.map((product) => (
        <div key={product.id} className={styles.product}>
          <div className={styles.imageContainer}>
            <Image
              src={product.image}
              alt={product.name}
              layout="fill"
              objectFit="contain"
            />
          </div>
          <h3 className={styles.productName}>{product.name}</h3>
          <p className={styles.productPrice}>${product.price.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
          <Button className={styles.addToCartButton} onClick={() => window.location.href = product.url}>
            View Product
          </Button>
        </div>
      ))}
    </div>
  )
}
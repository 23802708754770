'use client'

import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { X } from 'lucide-react'
import { Button } from "@trycreo/ui/button"
import Image from 'next/image'
import styles from './CallButtonDesktop.module.css'

export default function CallButtonDesktop() {
  const [isOpen, setIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('Sales')
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setModalRoot(document.body)
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const formObject = Object.fromEntries(formData.entries())
    
    try {
      const response = await fetch('/api/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formObject,
          formType: activeTab
        }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      console.log(data.message)
      setIsOpen(false) // Close the modal on successful submission
      // Here you can handle successful submission (e.g., show a success message)
    } catch (error) {
      console.error('Error:', error)
      // Here you can handle errors (e.g., show an error message to the user)
    }
  }

  const modalContent = isOpen && (
    <div className={styles.modalOverlay} onClick={() => setIsOpen(false)}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <Button 
          onClick={() => setIsOpen(false)} 
          className={styles.closeButton}
          variant="ghost"
        >
          <X className={styles.closeIcon} />
        </Button>
        <h2 className={styles.modalTitle}>Get In Touch</h2>
        <div className={styles.tabContainer}>
          {['Sales', 'Support', 'IPM'].map((tab) => (
            <button
              key={tab}
              className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        {/* <div className={styles.phoneContainer}>
          <p className={styles.phoneNumber}>Phone: 222-334-4556</p>
        </div> */}
        <form onSubmit={handleSubmit} className={styles.form}>
          <input
            name="firstName"
            placeholder="First Name"
            className={styles.input}
            required
          />
          <input
            name="lastName"
            placeholder="Last Name"
            className={styles.input}
            required
          />
          <input
            name="email"
            type="email"
            placeholder="Email Address"
            className={styles.input}
            required
          />
          <input
            name="phoneNumber"
            type="tel"
            placeholder="Phone Number"
            className={styles.input}
            required
          />
          <select
            name="interestedIn"
            className={styles.input}
            required
          >
            <option value="">Interested In?</option>
            <option value="Sales">Sales</option>
            <option value="Starting a Subscription">Starting a Subscription</option>
            <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
            <option value="General Questions & Support">General Questions & Support</option>
          </select>
          <select
            name="location"
            className={styles.input}
            required
          >
            <option value="">Location/State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
            <option value="DC">District of Columbia</option>
            <option value="AS">American Samoa</option>
            <option value="GU">Guam</option>
            <option value="MP">Northern Mariana Islands</option>
            <option value="PR">Puerto Rico</option>
            <option value="VI">U.S. Virgin Islands</option>
            <option value="EU">Europe</option>
            <option value="CA">Canada</option>
            <option value="SA">South America</option>
            <option value="AS">Asia</option>
            <option value="MENA">Middle East and North Africa (MENA)</option>
          </select>
          <textarea
            name="message"
            placeholder="Message:"
            rows={3}
            className={styles.input}
            required
          />
          <button
            type="submit"
            className={styles.submitButton}
          >
            <span>SUBMIT</span>
            <Image 
              src="/icon_dark.svg" 
              alt="Submit" 
              width={150} 
              height={150}
              className={styles.submitIcon}
            />
          </button>
        </form>
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => setIsOpen(true)}
          className={styles.openButton}
          aria-label="Open contact form"
        >
          <Image 
            src="/call.png" 
            alt="Contact" 
            width={400} 
            height={400}
            className={styles.buttonIcon}
          />
        </Button>
      </div>

      {modalRoot && createPortal(modalContent, modalRoot)}
    </>
  )
}

// 'use client'

// import { useState, useEffect } from 'react'
// import { createPortal } from 'react-dom'
// import { X } from 'lucide-react'
// import { Button } from "@trycreo/ui/button"
// import Image from 'next/image'
// import styles from './CallButtonDesktop.module.css'

// export default function CallButtonDesktop() {
//   const [isOpen, setIsOpen] = useState(false)
//   const [activeTab, setActiveTab] = useState('Sales')
//   const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null)

//   useEffect(() => {
//     setModalRoot(document.body)
//   }, [])

//   useEffect(() => {
//     if (isOpen) {
//       document.body.style.overflow = 'hidden'
//     } else {
//       document.body.style.overflow = 'unset'
//     }

//     return () => {
//       document.body.style.overflow = 'unset'
//     }
//   }, [isOpen])

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault()
//     const formData = new FormData(event.currentTarget)
//     const formObject = Object.fromEntries(formData.entries())
    
//     try {
//       const response = await fetch('/api/submit-form', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           ...formObject,
//           formType: activeTab
//         }),
//       })

//       if (!response.ok) {
//         throw new Error('Network response was not ok')
//       }

//       const data = await response.json()
//       console.log(data.message)
//       setIsOpen(false) // Close the modal on successful submission
//       // Here you can handle successful submission (e.g., show a success message)
//     } catch (error) {
//       console.error('Error:', error)
//       // Here you can handle errors (e.g., show an error message to the user)
//     }
//   }

//   const modalContent = isOpen && (
//     <div className={styles.modalOverlay} onClick={() => setIsOpen(false)}>
//       <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
//         <Button 
//           onClick={() => setIsOpen(false)} 
//           className={styles.closeButton}
//           variant="ghost"
//         >
//           <X className={styles.closeIcon} />
//         </Button>
//         <h2 className={styles.modalTitle}>Get In Touch</h2>
//         <div className={styles.tabContainer}>
//           {['Sales', 'Support', 'IPM'].map((tab) => (
//             <button
//               key={tab}
//               className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </button>
//           ))}
//         </div>
//         <div className={styles.phoneContainer}>
//           <p className={styles.phoneNumber}>Phone: 222-334-4556</p>
//         </div>
//         <form onSubmit={handleSubmit} className={styles.form}>
//           <input
//             name="firstName"
//             placeholder="First Name"
//             className={styles.input}
//             required
//           />
//           <input
//             name="lastName"
//             placeholder="Last Name"
//             className={styles.input}
//             required
//           />
//           <input
//             name="email"
//             type="email"
//             placeholder="Email Address"
//             className={styles.input}
//             required
//           />
//           <input
//             name="phoneNumber"
//             type="tel"
//             placeholder="Phone Number"
//             className={styles.input}
//             required
//           />
//           <select
//             name="interestedIn"
//             className={styles.input}
//             required
//           >
//             <option value="">Interested In?</option>
//             <option value="Sales">Sales</option>
//             <option value="Starting a Subscription">Starting a Subscription</option>
//             <option value="IPM Systems & Solutions">IPM Systems & Solutions</option>
//             <option value="General Questions & Support">General Questions & Support</option>
//           </select>
//           <select
//             name="location"
//             className={styles.input}
//             required
//           >
//             <option value="">Location/State</option>
//             <option value="AL">Alabama</option>
//             <option value="AK">Alaska</option>
//             // <option value="AZ">Arizona</option>
// // <option value="AR">Arkansas</option>
// // <option value="CA">California</option>
// // <option value="CO">Colorado</option>
// // <option value="CT">Connecticut</option>
// // <option value="DE">Delaware</option>
// // <option value="FL">Florida</option>
// // <option value="GA">Georgia</option>
// // <option value="HI">Hawaii</option>
// // <option value="ID">Idaho</option>
// // <option value="IL">Illinois</option>
// // <option value="IN">Indiana</option>
// // <option value="IA">Iowa</option>
// // <option value="KS">Kansas</option>
// // <option value="KY">Kentucky</option>
// // <option value="LA">Louisiana</option>
// // <option value="ME">Maine</option>
// // <option value="MD">Maryland</option>
// // <option value="MA">Massachusetts</option>
// // <option value="MI">Michigan</option>
// // <option value="MN">Minnesota</option>
// // <option value="MS">Mississippi</option>
// // <option value="MO">Missouri</option>
// // <option value="MT">Montana</option>
// // <option value="NE">Nebraska</option>
// // <option value="NV">Nevada</option>
// // <option value="NH">New Hampshire</option>
// // <option value="NJ">New Jersey</option>
// // <option value="NM">New Mexico</option>
// // <option value="NY">New York</option>
// // <option value="NC">North Carolina</option>
// // <option value="ND">North Dakota</option>
// // <option value="OH">Ohio</option>
// // <option value="OK">Oklahoma</option>
// // <option value="OR">Oregon</option>
// // <option value="PA">Pennsylvania</option>
// // <option value="RI">Rhode Island</option>
// // <option value="SC">South Carolina</option>
// // <option value="SD">South Dakota</option>
// // <option value="TN">Tennessee</option>
// // <option value="TX">Texas</option>
// // <option value="UT">Utah</option>
// // <option value="VT">Vermont</option>
// // <option value="VA">Virginia</option>
// // <option value="WA">Washington</option>
// // <option value="WV">West Virginia</option>
// // <option value="WI">Wisconsin</option>
// // <option value="WY">Wyoming</option>
// // <option value="DC">District of Columbia</option>
// // <option value="AS">American Samoa</option>
// // <option value="GU">Guam</option>
// // <option value="MP">Northern Mariana Islands</option>
// // <option value="PR">Puerto Rico</option>
// // <option value="VI">U.S. Virgin Islands</option>
// // <option value="EU">Europe</option>
// // <option value="CA">Canada</option>
// // <option value="SA">South America</option>
// // <option value="AS">Asia</option>
// // <option value="MENA">Middle East and North Africa (MENA)</option>
//             {/* Add all other states and locations here */}
//           </select>
//           <textarea
//             name="message"
//             placeholder="Message:"
//             rows={3}
//             className={styles.input}
//             required
//           />
//           <button
//             type="submit"
//             className={styles.submitButton}
//           >
//             <span>SUBMIT</span>
//             <Image 
//               src="/icon_dark.svg" 
//               alt="Submit" 
//               width={150} 
//               height={150}
//               className={styles.submitIcon}
//             />
//           </button>
//         </form>
//       </div>
//     </div>
//   )

//   return (
//     <>
//       <div className={styles.buttonContainer}>
//         <Button
//           onClick={() => setIsOpen(true)}
//           className={styles.openButton}
//           aria-label="Open contact form"
//         >
//           <Image 
//             src="/call.png" 
//             alt="Contact" 
//             width={400} 
//             height={400}
//             className={styles.buttonIcon}
//           />
//         </Button>
//       </div>

//       {modalRoot && createPortal(modalContent, modalRoot)}
//     </>
//   )
// }






// 'use client'

// import { useState, useEffect } from 'react'
// import { createPortal } from 'react-dom'
// import { X } from 'lucide-react'
// import { Button } from "@trycreo/ui/button"
// import Image from 'next/image'
// import styles from './CallButtonDesktop.module.css'

// export default function CallButtonDesktop() {
//   const [isOpen, setIsOpen] = useState(false)
//   const [activeTab, setActiveTab] = useState('Sales')
//   const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null)

//   useEffect(() => {
//     setModalRoot(document.body)
//   }, [])

//   useEffect(() => {
//     if (isOpen) {
//       document.body.style.overflow = 'hidden'
//     } else {
//       document.body.style.overflow = 'unset'
//     }

//     return () => {
//       document.body.style.overflow = 'unset'
//     }
//   }, [isOpen])

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault()
//     const formData = new FormData(event.currentTarget)
//     const formObject = Object.fromEntries(formData.entries())
    
//     let submitUrl = '/api/submit-sales' // default to sales
//     switch(activeTab) {
//       case 'Support':
//         submitUrl = '/api/submit-support'
//         break
//       case 'IPM':
//         submitUrl = '/api/submit-ipm'
//         break
//     }

//     try {
//       const response = await fetch(submitUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formObject),
//       })

//       if (!response.ok) {
//         throw new Error('Network response was not ok')
//       }

//       const data = await response.json()
//       console.log(data.message)
//       setIsOpen(false) // Close the modal on successful submission
//       // Here you can handle successful submission (e.g., show a success message)
//     } catch (error) {
//       console.error('Error:', error)
//       // Here you can handle errors (e.g., show an error message to the user)
//     }
//   }

//   const modalContent = isOpen && (
//     <div className={styles.modalOverlay} onClick={() => setIsOpen(false)}>
//       <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
//         <Button 
//           onClick={() => setIsOpen(false)} 
//           className={styles.closeButton}
//           variant="ghost"
//         >
//           <X className={styles.closeIcon} />
//         </Button>
//         <h2 className={styles.modalTitle}>Get In Touch</h2>
//         <div className={styles.tabContainer}>
//           {['Sales', 'Support', 'IPM'].map((tab) => (
//             <button
//               key={tab}
//               className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </button>
//           ))}
//         </div>
//         <div className={styles.phoneContainer}>
//           <p className={styles.phoneNumber}>Phone: 222-334-4556</p>
//         </div>
//         <form onSubmit={handleSubmit} className={styles.form}>
//           <input
//             name="firstName"
//             placeholder="First Name"
//             className={styles.input}
//           />
//           <input
//             name="lastName"
//             placeholder="Last Name"
//             className={styles.input}
//           />
//           <input
//             name="email"
//             type="email"
//             placeholder="Email Address"
//             className={styles.input}
//           />

// <input
//             name="phone"
//             type="tel"
//             placeholder="Phone Number"
//             className={styles.input}
//           />



          
        
//       <select
//             name="location"
//             className={styles.input}
//           >
//             <option value="">Interested In?</option>
//             <option value="option1">Sales</option>
//           <option value="option2">Starting a Subscription</option>
//           <option value="option3">IPM Systems & Solutions</option>
//           <option value="option4">General Questions & Support</option>

            
//             {/* Add more states here */}
//           </select>

//           <select
//             name="location"
//             className={styles.input}
//           >
//             <option value="">Location/State</option>
//             <option value="AL">Alabama</option>
// <option value="AK">Alaska</option>
// <option value="AZ">Arizona</option>
// <option value="AR">Arkansas</option>
// <option value="CA">California</option>
// <option value="CO">Colorado</option>
// <option value="CT">Connecticut</option>
// <option value="DE">Delaware</option>
// <option value="FL">Florida</option>
// <option value="GA">Georgia</option>
// <option value="HI">Hawaii</option>
// <option value="ID">Idaho</option>
// <option value="IL">Illinois</option>
// <option value="IN">Indiana</option>
// <option value="IA">Iowa</option>
// <option value="KS">Kansas</option>
// <option value="KY">Kentucky</option>
// <option value="LA">Louisiana</option>
// <option value="ME">Maine</option>
// <option value="MD">Maryland</option>
// <option value="MA">Massachusetts</option>
// <option value="MI">Michigan</option>
// <option value="MN">Minnesota</option>
// <option value="MS">Mississippi</option>
// <option value="MO">Missouri</option>
// <option value="MT">Montana</option>
// <option value="NE">Nebraska</option>
// <option value="NV">Nevada</option>
// <option value="NH">New Hampshire</option>
// <option value="NJ">New Jersey</option>
// <option value="NM">New Mexico</option>
// <option value="NY">New York</option>
// <option value="NC">North Carolina</option>
// <option value="ND">North Dakota</option>
// <option value="OH">Ohio</option>
// <option value="OK">Oklahoma</option>
// <option value="OR">Oregon</option>
// <option value="PA">Pennsylvania</option>
// <option value="RI">Rhode Island</option>
// <option value="SC">South Carolina</option>
// <option value="SD">South Dakota</option>
// <option value="TN">Tennessee</option>
// <option value="TX">Texas</option>
// <option value="UT">Utah</option>
// <option value="VT">Vermont</option>
// <option value="VA">Virginia</option>
// <option value="WA">Washington</option>
// <option value="WV">West Virginia</option>
// <option value="WI">Wisconsin</option>
// <option value="WY">Wyoming</option>
// <option value="DC">District of Columbia</option>
// <option value="AS">American Samoa</option>
// <option value="GU">Guam</option>
// <option value="MP">Northern Mariana Islands</option>
// <option value="PR">Puerto Rico</option>
// <option value="VI">U.S. Virgin Islands</option>
// <option value="EU">Europe</option>
// <option value="CA">Canada</option>
// <option value="SA">South America</option>
// <option value="AS">Asia</option>
// <option value="MENA">Middle East and North Africa (MENA)</option>

//             {/* Add more states here */}
//           </select>
         
//           <textarea
//             name="message"
//             placeholder="Message:"
//             rows={3}
//             className={styles.input}
//           />
//           <button
//             type="submit"
//             className={styles.submitButton}
//           >
//             <span>SUBMIT</span>
//             <Image 
//               src="/icon_dark.svg" 
//               alt="Submit" 
//               width={150} 
//               height={150}
//               className={styles.submitIcon}
//             />
//           </button>
//         </form>
//       </div>
//     </div>
//   )

//   return (
//     <>
//       <div className={styles.buttonContainer}>
//         <Button
//           onClick={() => setIsOpen(true)}
//           className={styles.openButton}
//           aria-label="Open contact form"
//         >
//           <Image 
//             src="/call.png" 
//             alt="Contact" 
//             width={400} 
//             height={400}
//             className={styles.buttonIcon}
//           />
//         </Button>
//       </div>

//       {modalRoot && createPortal(modalContent, modalRoot)}
//     </>
//   )
// }

// 'use client'

// import { useState, useEffect } from 'react'
// import { X } from 'lucide-react'
// import { Button } from "@trycreo/ui/button";
// // import { Button } from "@/components/ui/button"
// import Image from 'next/image'
// import styles from './CallButtonDesktop.module.css'

// export default function CallButtonDesktop() {
//   const [isOpen, setIsOpen] = useState(false)
//   const [activeTab, setActiveTab] = useState('Sales')

//   useEffect(() => {
//     if (isOpen) {
//       document.body.style.overflow = 'hidden'
//     } else {
//       document.body.style.overflow = 'unset'
//     }

//     return () => {
//       document.body.style.overflow = 'unset'
//     }
//   }, [isOpen])

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault()
//     const formData = new FormData(event.currentTarget)
//     const formObject = Object.fromEntries(formData.entries())
    
//     let submitUrl = '/api/submit-sales' // default to sales
//     switch(activeTab) {
//       case 'Support':
//         submitUrl = '/api/submit-support'
//         break
//       case 'IPM':
//         submitUrl = '/api/submit-ipm'
//         break
//     }

//     try {
//       const response = await fetch(submitUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formObject),
//       })

//       if (!response.ok) {
//         throw new Error('Network response was not ok')
//       }

//       const data = await response.json()
//       console.log(data.message)
//       setIsOpen(false) // Close the modal on successful submission
//       // Here you can handle successful submission (e.g., show a success message)
//     } catch (error) {
//       console.error('Error:', error)
//       // Here you can handle errors (e.g., show an error message to the user)
//     }
//   }

//   return (
//     <>
//       <div className={styles.buttonContainer}>
//         <Button
//           onClick={() => setIsOpen(true)}
//           className={styles.openButton}
//           aria-label="Open contact form"
//         >
//           <Image 
//             src="/call.png" 
//             alt="Contact" 
//             width={400} 
//             height={400}
//             className={styles.buttonIcon}
//           />
//         </Button>
//       </div>

//       {isOpen && (
//         <div className={styles.modalOverlay} onClick={() => setIsOpen(false)}>
//           <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
//             <Button 
//               onClick={() => setIsOpen(false)} 
//               className={styles.closeButton}
//               variant="ghost"
//             >
//               <X className={styles.closeIcon} />
//             </Button>
//             <h2 className={styles.modalTitle}>Get In Touch</h2>
//             <div className={styles.tabContainer}>
//               {['Sales', 'Support', 'IPM'].map((tab) => (
//                 <button
//                   key={tab}
//                   className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
//                   onClick={() => setActiveTab(tab)}
//                 >
//                   {tab}
//                 </button>
//               ))}
//             </div>
//             <div className={styles.phoneContainer}>
//               <p className={styles.phoneNumber}>Phone: 222-334-4556</p>
//             </div>
//             <form onSubmit={handleSubmit} className={styles.form}>
//               <input
//                 name="firstName"
//                 placeholder="First Name"
//                 className={styles.input}
//               />
//               <input
//                 name="lastName"
//                 placeholder="Last Name"
//                 className={styles.input}
//               />
//               <input
//                 name="email"
//                 type="email"
//                 placeholder="Email Address"
//                 className={styles.input}
//               />
//               <input
//                 name="phone"
//                 type="tel"
//                 placeholder="Phone Number"
//                 className={styles.input}
//               />
//               <select
//                 name="location"
//                 className={styles.input}
//               >
//                 <option value="">Location/State</option>
//                 <option value="AL">Alabama</option>
//                 <option value="AK">Alaska</option>
//                 {/* Add more states here */}
//               </select>
//               <textarea
//                 name="message"
//                 placeholder="Message:"
//                 rows={3}
//                 className={styles.input}
//               />
//               <button
//                 type="submit"
//                 className={styles.submitButton}
//               >
//                 <span>SUBMIT</span>
//                 <Image 
//                   src="/icon_dark.svg" 
//                   alt="Submit" 
//                   width={150} 
//                   height={150}
//                   className={styles.submitIcon}
//                 />
//               </button>
//             </form>
//           </div>
//         </div>
//       )}
//     </>
//   )
// }

// // 'use client'

// // import { useState } from 'react'
// // import { X } from 'lucide-react'
// // // import { Button } from "@/components/ui/button"
// // import { Button } from "@trycreo/ui/button";
// // import Image from 'next/image'
// // import styles from './CallButtonDesktop.module.css'

// // export default function CallButtonDesktop() {
// //   const [isOpen, setIsOpen] = useState(false)
// //   const [activeTab, setActiveTab] = useState('Sales')

// //   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
// //     event.preventDefault()
// //     const formData = new FormData(event.currentTarget)
// //     const formObject = Object.fromEntries(formData.entries())
    
// //     let submitUrl = '/api/submit-sales' // default to sales
// //     switch(activeTab) {
// //       case 'Support':
// //         submitUrl = '/api/submit-support'
// //         break
// //       case 'IPM':
// //         submitUrl = '/api/submit-ipm'
// //         break
// //     }

// //     try {
// //       const response = await fetch(submitUrl, {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify(formObject),
// //       })

// //       if (!response.ok) {
// //         throw new Error('Network response was not ok')
// //       }

// //       const data = await response.json()
// //       console.log(data.message)
// //       setIsOpen(false) // Close the modal on successful submission
// //       // Here you can handle successful submission (e.g., show a success message)
// //     } catch (error) {
// //       console.error('Error:', error)
// //       // Here you can handle errors (e.g., show an error message to the user)
// //     }
// //   }

// //   return (
// //     <>
// //       <div className={styles.buttonContainer}>
// //         <Button
// //           onClick={() => setIsOpen(true)}
// //           className={styles.openButton}
// //           aria-label="Open contact form"
// //         >
// //           <Image 
// //             src="/call.png" 
// //             alt="Contact" 
// //             width={65} 
// //             height={65}
// //             className={styles.buttonIcon}
// //           />
// //         </Button>
// //       </div>

// //       {isOpen && (
// //         <div className={styles.modalOverlay}>
// //           <div className={styles.modalContent}>
// //             <Button 
// //               onClick={() => setIsOpen(false)} 
// //               className={styles.closeButton}
// //               variant="ghost"
// //             >
// //               <X className={styles.closeIcon} />
// //             </Button>
// //             <h2 className={styles.modalTitle}>Get In Touch</h2>
// //             <div className={styles.tabContainer}>
// //               {['Sales', 'Support', 'IPM'].map((tab) => (
// //                 <button
// //                   key={tab}
// //                   className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
// //                   onClick={() => setActiveTab(tab)}
// //                 >
// //                   {tab}
// //                 </button>
// //               ))}
// //             </div>
// //             <div className={styles.phoneContainer}>
// //               <p className={styles.phoneNumber}>Phone: 222-334-4556</p>
// //             </div>
// //             <form onSubmit={handleSubmit} className={styles.form}>
// //               <input
// //                 name="firstName"
// //                 placeholder="First Name"
// //                 className={styles.input}
// //               />
// //               <input
// //                 name="lastName"
// //                 placeholder="Last Name"
// //                 className={styles.input}
// //               />
// //               <input
// //                 name="email"
// //                 type="email"
// //                 placeholder="Email Address"
// //                 className={styles.input}
// //               />
// //               <input
// //                 name="phone"
// //                 type="tel"
// //                 placeholder="Phone Number"
// //                 className={styles.input}
// //               />
// //               <select
// //                 name="location"
// //                 className={styles.input}
// //               >
// //                 <option value="">Location/State</option>
// //                 <option value="AL">Alabama</option>
// //                 <option value="AK">Alaska</option>
// //                 {/* Add more states here */}
// //               </select>
// //               <textarea
// //                 name="message"
// //                 placeholder="Message:"
// //                 rows={3}
// //                 className={styles.input}
// //               />
// //               <button
// //                 type="submit"
// //                 className={styles.submitButton}
// //               >
// //                 <span>SUBMIT</span>
// //                 <Image 
// //                   src="/icon.svg" 
// //                   alt="Submit" 
// //                   width={80} 
// //                   height={80}
// //                   className={styles.submitIcon}
// //                 />
// //               </button>
// //             </form>
// //           </div>
// //         </div>
// //       )}
// //     </>
// //   )
// // }

// // 'use client'

// // import { useState } from 'react'
// // import { X } from 'lucide-react'
// // import { Button } from "@trycreo/ui/button";
// // // import { Button } from "@/components/ui/button"
// // import Image from 'next/image'

// // export default function CallButtonDesktop() {
// //   const [isOpen, setIsOpen] = useState(false)
// //   const [activeTab, setActiveTab] = useState('Sales')

// //   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
// //     event.preventDefault()
// //     const formData = new FormData(event.currentTarget)
// //     const formObject = Object.fromEntries(formData.entries())
    
// //     let submitUrl = '/api/submit-sales' // default to sales
// //     switch(activeTab) {
// //       case 'Support':
// //         submitUrl = '/api/submit-support'
// //         break
// //       case 'IPM':
// //         submitUrl = '/api/submit-ipm'
// //         break
// //     }

// //     try {
// //       const response = await fetch(submitUrl, {
// //         method: 'POST',
// //         headers: {
// //           'Content-Type': 'application/json',
// //         },
// //         body: JSON.stringify(formObject),
// //       })

// //       if (!response.ok) {
// //         throw new Error('Network response was not ok')
// //       }

// //       const data = await response.json()
// //       console.log(data.message)
// //       setIsOpen(false) // Close the modal on successful submission
// //       // Here you can handle successful submission (e.g., show a success message)
// //     } catch (error) {
// //       console.error('Error:', error)
// //       // Here you can handle errors (e.g., show an error message to the user)
// //     }
// //   }

// //   return (
// //     <>
// //       <div className="fixed bottom-4 right-4">
// //         <Button
// //           onClick={() => setIsOpen(true)}
// //           className="w-16 h-16 p-0 rounded-full"
// //           aria-label="Open contact form"
// //         >
// //           <Image 
// //             src="/call.png" 
// //             alt="Contact" 
// //             width={65} 
// //             height={65}
// //             className="w-full h-full object-cover"
// //           />
// //         </Button>
// //       </div>

// //       {isOpen && (
// //         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
// //           <div className="bg-[#121212] text-white p-8 rounded-lg max-w-md w-full relative">
// //             <Button 
// //               onClick={() => setIsOpen(false)} 
// //               className="absolute right-2 top-2 text-white"
// //               variant="ghost"
// //             >
// //               <X className="h-6 w-6" />
// //             </Button>
// //             <h2 className="text-2xl font-bold mb-6 text-center">Get In Touch</h2>
// //             <div className="flex justify-between mb-6">
// //               {['Sales', 'Support', 'IPM'].map((tab) => (
// //                 <button
// //                   key={tab}
// //                   className={`flex-grow py-2 px-4 border border-white ${
// //                     activeTab === tab ? 'bg-white text-[#121212]' : 'text-white'
// //                   } ${tab === 'Sales' ? 'rounded-l-md' : ''} ${tab === 'IPM' ? 'rounded-r-md' : ''}`}
// //                   onClick={() => setActiveTab(tab)}
// //                 >
// //                   {tab}
// //                 </button>
// //               ))}
// //             </div>
// //             <div className="bg-white text-[#121212] p-3 rounded-md mb-6">
// //               <p className="text-center font-bold">Phone: 222-334-4556</p>
// //             </div>
// //             <form onSubmit={handleSubmit} className="space-y-4">
// //               <input
// //                 name="firstName"
// //                 placeholder="First Name"
// //                 className="w-full bg-transparent border-b border-white p-2 focus:outline-none"
// //               />
// //               <input
// //                 name="lastName"
// //                 placeholder="Last Name"
// //                 className="w-full bg-transparent border-b border-white p-2 focus:outline-none"
// //               />
// //               <input
// //                 name="email"
// //                 type="email"
// //                 placeholder="Email Address"
// //                 className="w-full bg-transparent border-b border-white p-2 focus:outline-none"
// //               />
// //               <input
// //                 name="phone"
// //                 type="tel"
// //                 placeholder="Phone Number"
// //                 className="w-full bg-transparent border-b border-white p-2 focus:outline-none"
// //               />
// //               <select
// //                 name="location"
// //                 className="w-full bg-transparent border-b border-white p-2 focus:outline-none"
// //               >
// //                 <option value="">Location/State</option>
// //                 <option value="AL">Alabama</option>
// //                 <option value="AK">Alaska</option>
// //                 {/* Add more states here */}
// //               </select>
// //               <textarea
// //                 name="message"
// //                 placeholder="Message:"
// //                 rows={3}
// //                 className="w-full bg-transparent border-b border-white p-2 focus:outline-none"
// //               />
// //               <button
// //                 type="submit"
// //                 className="w-full flex items-center justify-center space-x-2 py-2 mt-4 text-white hover:opacity-80"
// //               >
// //                 <span>SUBMIT</span>
// //                 <Image 
// //                   src="/icon.svg"
// //                   alt="Submit" 
// //                   width={75} 
// //                   height={75}
// //                 />
// //               </button>
// //             </form>
// //           </div>
// //         </div>
// //       )}
// //     </>
// //   )
// // }

// // //   import React, { useState } from 'react'
// // // import styles from './styles.module.css'
// // // import cx from 'classnames'
// // // import useCallForm from './useCallForm'
// // // import SalesForm from './salesForm'

// // // const contactOptions = ['Sales', 'Support', 'IPM']

// // // const CallForm: React.FC<{
// // //   toggleCall: () => void;
// // //   callOpen: boolean;
// // // }> = ({ toggleCall, callOpen }) => {
// // //   const [activeItem, setActiveItem] = useState(contactOptions[0])

// // //   return (
// // //     <dialog className={styles.contactContainer} open={callOpen}>
// // //       <header className={styles.headerContainer}>
// // //         <h1 className={styles.headerText}>get in touch</h1>
// // //         <img
// // //           onClick={toggleCall}
// // //           src="https://cdn.builder.io/api/v1/image/assets/TEMP/16885182ee1751ac7640377175b295ab75f8b74b4fd0922c5fbc60a2d9e24acd?apiKey=7d740121674b4225a077dc91176b5085&"
// // //           alt=""
// // //           className={cx(styles.headerIcon, styles.pointer)}
// // //         />
// // //       </header>

// // //       <div className={styles.contactOptions}>
// // //         {contactOptions.map((option, index) => (
// // //           <div
// // //             key={index}
// // //             onClick={() => setActiveItem(option)}
// // //             className={styles.contactOption}
// // //           >
// // //             {option}
// // //           </div>
// // //         ))}
// // //       </div>
// // //       {activeItem === 'Sales' && <SalesForm />}
// // //       {activeItem === 'Support' && <SalesForm />}
// // //       {activeItem === 'IPM' && <SalesForm />}
// // //     </dialog>
// // //   )
// // // }

// // // export default CallForm
